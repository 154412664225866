import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import Copy from "../../assets/icons/copy.svg";
import { Link } from "react-router-dom";
import { copyToClipboard } from "../../Helper";

const faqs = [
  {
    question: "Contact Support",
    answer: "+234 903 9861 005",
    CopyIcon: Copy,
  },
];

const FAQ: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [selected, setSelected] = useState(0);
  const [copiedText, setCopied] = useState<string>("");

  const toggleQuestion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="dark:bg-clrDarkBg">
      <Link
        to="https://wicrypt.com/faq.html"
        rel="noopener noreferrer"
        target="_blank"
        className={
          "flex items-center justify-between p-2 cursor-pointer hover:bg-[#f4f4f4] dark:hover:bg-clrDarkBg2 hover:rounded transition duration-150 ease-in"
        }
      >
        <h3 className="font-medium text-x10 text-clrText dark:text-clrPlaceholder">
          Help Center (FAQs)
        </h3>
        <ArrowDown className="w-[15px] h-[15px] transform -rotate-90 dark-arrow-gray" />
      </Link>
      {/* {faqs.map((faq, index) => (
        <div
          key={index}
          className={` mb-2 ${
            selected === index
              ? "hover:bg-[#f4f4f4] dark:hover:bg-clrDarkBg2  hover:rounded transition duration-150 ease-in"
              : "bg-white dark:bg-clrDarkBg"
          }  ${expandedIndex === index ? "bg-white dark:bg-clrDarkBg" : ""}`}
          onClick={() => setSelected(index)}
        >
          <div
            className={"flex items-center justify-between p-2 cursor-pointer"}
            onClick={() => toggleQuestion(index)}
          >
            <h3 className="font-medium text-x10 text-clrText dark:text-clrPlaceholder">
              {faq.question}
            </h3>
            {expandedIndex === index ? (
              <ArrowDown className="w-[15px] h-[15px] dark-arrow-gray" />
            ) : (
              <ArrowDown className="w-[15px] h-[15px] transform -rotate-90 dark-arrow-gray" />
            )}
          </div>
          {expandedIndex === index && (
            <div className="flex justify-between p-2 mt-2 bg-[#FFFCEF] dark:bg-clrDarkBg2 rounded-[3px] relative">
              <p className="font-medium text-black text-x10 dark:text-white">
                {faq.answer}
              </p>
              {faq.CopyIcon && (
                <img
                  onClick={() => {
                    copyToClipboard(faq?.answer);
                    setCopied(faq.answer);
                  }}
                  src={Copy}
                  alt="Copy number"
                  width={"15px"}
                  height={"15px"}
                />
              )}
              {faq?.answer === copiedText && (
                <p className="absolute text-white mt-5 bg-clrYellow p-1 rounded text-x10 border-solid right-0">
                  {" "}
                  Copied
                </p>
              )}
            </div>
          )}
        </div>
      ))} */}
    </div>
  );
};

export default FAQ;
