import SuccessIcon from "../../../assets/icons/wallet/success.svg";
import useOutsideClick from "../../../Hooks/useOutsideClick";
import { Button } from "../../reusables";
import { useRef } from "react";

type SuccessProps = {
    handleButton: ()=> void
    buttonTitle: string
    title: string
    description: string
    handleModal: () => void
}

export const SignUpSuccessModal = ({ 
    handleButton,
    handleModal,
    buttonTitle,
    title,
    description,
   }: SuccessProps) => {
    const modalRef = useRef<HTMLElement>(null);
    useOutsideClick(modalRef, handleModal);
    return (
        <section
            className="fixed inset-0 z-50 h-screen overflow-hidden bg-white md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
            ref={modalRef}
        >
            <div className="bg-white dark:bg-clrDarkBg py-14 z-30 rounded-xl w-11/12 md:px-4 md:w-[400px] slide-in-left">
                <div className="flex flex-col items-center justify-center gap-4 w-[90%] max-w-[285px] mx-auto">
                    <img
                        src={SuccessIcon}
                        alt="Success"
                        width={100}
                        height={100}
                        className="w-20 h-20 md:w-24 md:h-24"
                    />
                    <div className="text-center">
                        <h2 className="mb-3 text-sm font-semibold text-black md:text-base dark:text-white">
                            {title}
                        </h2>
                        <p className="font-medium text-x9 md:text-x10 text-clrGray66 dark:text-clrPlaceholder">
                            {description}{" "}
                        </p>
                    </div>

                    <div className="w-full mt-5">
                        <Button
                            type="button"
                            text={buttonTitle || ""}
                            handleClick={handleButton}
                            />
                    </div>
                </div>
            </div>
        </section>
    );
};