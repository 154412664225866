import React, { useState, useEffect } from "react";
import {
  Card,
  ButtonAuth,
  CardTitle,
  ErrorMsg,
  StatusPage,
} from "../reusables";
import AuthFrame from "../reusables/AuthFrame";
import { Link } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import { useResetPasswordMutation } from "../../features/api/authSlice";

const ResetPin = () => {
  const [pin, setPin] = useState("");
  const [confirnPin, setConfirmPin] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [isError, setError] = useState("");

  const [resetPassword, { data, isLoading }] = useResetPasswordMutation();

  const handleChange = (res: string) => {
    if (isError) {
      setError("");
    }
    setPin(res);
  };

  const handleConfrimChange = (res: string) => {
    if (isError) {
      setError("");
    }
    setConfirmPin(res);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (pin.length !== 4) {
      setError("Please enter a valid pin");
    } else if (pin !== confirnPin) {
      setError("Pins do not match!");
    } else {
      setError("");
      let data = {
        email: email,
        token: token,
        newPassword: pin,
      };
      resetPassword(data);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlToken = urlParams.get("token");
    const urlEmail = urlParams.get("email");
    if (urlToken && urlEmail) {
      setToken(urlToken);
      setEmail(urlEmail);
    }
  }, []);

  return (
    <div className="flex">
      <Card>
        {data?.success ? (
          <StatusPage
            title="Pin Reset!"
            user={email}
            msg={
              <p>
                {" "}
                Your Pin has been reset successfully! Go back to{" "}
                <Link className="text-clrYellow" to="/">
                  {" "}
                  Login{" "}
                </Link>{" "}
                to continue{" "}
              </p>
            }
          />
        ) : (
          <div className="flex items-center flex-col min-[426px]:w-[350px] mx-auto mb-12 md:mb-8 h-full">
            <div className="text-center md:text-left">
              <CardTitle title="Reset PIN" />
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-5">
                Set a new PIN for your account
              </p>
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-8">
                Enter a 4 digit PIN and secure your account
              </p>
            </div>
            <div className="mb-4 text-center">
              <label
                htmlFor="pin"
                className="block text-[0.625rem] leading-3 text-clrTextBlue md:text-sm font-bold mb-4"
              >
                Enter new PIN
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-5xl font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 rounded-md bg-[#f5f5f5]"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={true}
                  length={4}
                  allowedCharacters="numeric"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="text-center">
              <label
                htmlFor="confirmPin"
                className="block text-[0.625rem] leading-3 text-clrTextBlue md:text-sm font-bold mb-4"
              >
                Confirm new PIN
              </label>
              <div className="mb-4">
                <AuthCode
                  inputClassName="w-10 h-10 text-center text-5xl font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 rounded-md bg-[#f5f5f5]"
                  containerClassName="flex justify-center gap-3"
                  ariaLabel="verify-otp"
                  isPassword={true}
                  autoFocus={false}
                  length={4}
                  allowedCharacters="numeric"
                  onChange={handleConfrimChange}
                />
                <ErrorMsg errMsg={isError ? isError : data?.message ?? ""} />
              </div>
            </div>
            <div className="mt-6 mb-8 w-[80%]">
              <ButtonAuth
                type="submit"
                loading={isLoading}
                handleClick={handleSubmit}
                text="Set Pin"
              />
            </div>
          </div>
        )}
      </Card>
      <AuthFrame />
    </div>
  );
};

export default ResetPin;
