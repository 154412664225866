import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";

const ProfilePage = () => {
  const {t} = useTranslation();
 
  return (
      <SecondaryNav
        paths={[
          { path: "/home/profile/info", title: t("myProfile" )},
          { path: "/home/profile/settings", title: t("settings") },
        ]}
      />
  );
};

export default ProfilePage;
