import { useRef, useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { dataCluster, useMyClusterColumn } from "./data";
import { ButtonYellow, EmptyCard, TableLoader } from "../reusables";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { useAppSelector } from "../../app/hooks";
import { useGetClustersQuery } from "../../features/api/clusterSlice";
import { AddClusterModal } from "./clusterModals";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { useTranslation } from "react-i18next";
// import CopyIcon from "../../assets/icons/copy.svg";


const Clusters = () => {
    const {t} = useTranslation();
    const myCluster = useMyClusterColumn();
    const { businessId } = useAppSelector((state) => state.auth);
    const [page, setPage] = useState<number>(1);
    const [searchValue, setSearch] = useState<string>("");
    const [isCluster, setAddCluster] = useState<boolean>(false);
    const { data, isLoading, isFetching } = useGetClustersQuery({
        page,
        pageSize: 30,
        businessId: businessId
    });


    const modalRef = useRef<HTMLElement>(null);

    useOutsideClick(modalRef, ()=> setAddCluster(!isCluster));

    return (
        <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
            <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
                <h3 className="text-sm font-semibold text-clrGray dark:text-clrPlaceholder">
                    {t("clusters")}
                </h3>
                <div className="flex flex-wrap items-end gap-4 my-4 md:mt-6 md:mb-8 md:flex-nowrap">
                    <div>
                        <label
                            htmlFor=""
                            className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
                        >
                            {t("search")}
                        </label>
                        <input
                            type="text"
                            name=""
                            id=""
                            value={searchValue}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t("clusterName")}
                            className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
                        />
                    </div>
                    <button  onClick={()=> setAddCluster(!isCluster)}>
                        <ButtonYellow text={t("addCluster")} btnIcon={AddIcon} to={""} />
                    </button>
                </div>

                <div>
                    {isLoading || isFetching ? (
                        <TableLoader />
                    ) : (
                        <TableMain
                            defaultData={data?.data?.records || dataCluster}
                            columns={myCluster}
                            totalPages={data?.data.totalPages || 0}
                            pageSize={data?.data.pageSize || 0}
                            currentPage={data?.data.currentPage || 0}
                            setPage={setPage}
                        />
                    )}
                    {!isLoading && !isFetching && (!data || !data.data || data.data.records.length === 0) && (
                        <EmptyCard
                            description={searchValue ? `${searchValue} ${t("notFound")}` : t("noClusterFound") }
                        />
                    )}
                </div>
            </div>
            {isCluster && <AddClusterModal modalRef={modalRef} handleCloseModal={()=> setAddCluster(!isCluster) } />}
        </section>
    );
};

export default Clusters;
