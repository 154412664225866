import { apiSlice } from "./apiSlice";
import { AppealRequest, AppealResponse, CommentDisputeRequest, CreateDisputeRequest, CreateDisputeResponse } from "./walletSliceTypes";

const p2pAppealSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get all disputes
        getDisputes: build.query<AppealResponse, AppealRequest>({
            query({ baseUrl, disputeReference, p2pReference }) {
                return {
                    url: `${baseUrl}Dispute/ThirdParty/DisputeChatsByRef?disputeReference=${disputeReference}`,
                    headers: { "publicKey": p2pReference }
                }
            },
            providesTags: ["Disputes"]
        }),

        //Create dispute 
        createDispute: build.mutation<CreateDisputeResponse, Partial<CreateDisputeRequest>>({
            query: (payload) => ({
                url: `/v2/P2POrder/raiseDispute`,
                method: "POST", 
                body: payload
            }),
            invalidatesTags: ["Disputes"]
        }),

        // //Comment on Dispute 
        commentonDispute: build.mutation<string, Partial<CommentDisputeRequest>>({
            query: ({ payload, p2preference }) => ({
                url: `/Dispute/ThirdParty/Comment`,
                method: "POST",
                body: payload,
                headers: { "publicKey": p2preference }
            }),
            invalidatesTags: ["Disputes"]
        }),
    }),
    overrideExisting: true,
})

export const { useGetDisputesQuery, useCreateDisputeMutation, useCommentonDisputeMutation } = p2pAppealSlice