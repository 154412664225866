import { useEffect, useState } from "react";
import { useGetBoosterFaqQuery } from "../../features/api/rewardSlice";
import { ReactComponent as ViewMore } from "../../assets/icons/view-more.svg";
import { ReactComponent as ViewLess } from "../../assets/icons/view-less.svg";
import { TableLoader } from "../reusables";
import { Faq } from "./type";

//Component to answer all booster FAQs
const BoosterFaqs = () => {
  const [faqList, setFaqList] = useState<Faq[]>();

  const { data: boosterFaq, isLoading: gettingFaq } = useGetBoosterFaqQuery({
    Page: 1,
    PageSize: 30,
  });

  //Handle toggle multiple faq open
  const handleFaqClick = (id: string) => {
    setFaqList((prevFaqList) =>
      prevFaqList?.map((faq) =>
        faq.id === id
          ? { ...faq, isView: !faq.hasOwnProperty("isView") || !faq?.isView }
          : faq
      )
    );
  };

  useEffect(() => {
    if (boosterFaq) {
      setFaqList(boosterFaq?.data?.records);
    }
  }, [boosterFaq]);

  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-3 mb-6 overflow-y-auto bg-white dark:bg-clrDarkBg">
      <div className="grid grid-cols-1 gap-3 py-8 my-2 rounded-lg md:gap-4">
        {gettingFaq ? (
          <div className="bg-white rounded-lg dark:bg-clrDarkBg ">
            {" "}
            <TableLoader />{" "}
          </div>
        ) : (
          faqList?.map((each) => {
            return (
              <div
                key={each.id}
                className="bg-white rounded-lg dark:bg-clrDarkBg "
              >
                <div className="flex justify-center cursor-pointer align-center">
                  <div className="flex items-center w-10/12 gap-2 px-5 py-3 rounded md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2">
                    <div className="mr-auto">
                      <p className="text-xs font-semibold text-clrTextGray dark:text-white">
                        {each.question}
                      </p>
                    </div>
                    {each.isView ? (
                      <ViewMore
                        onClick={() => handleFaqClick(each.id)}
                        width={20}
                        height={20}
                      />
                    ) : (
                      <ViewLess
                        onClick={() => handleFaqClick(each.id)}
                        width={16}
                        height={16}
                      />
                    )}
                  </div>
                </div>
                {each.isView && (
                  <div className="flex items-center justify-center text-clrTextLightGray dark:text-clrPlaceholder">
                    <div className="w-10/12 px-5 py-3 text-xs">
                      {each.answer}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};

export default BoosterFaqs;
