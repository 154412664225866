import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import Confirm from "./Confirm";
import Success from "./Success";
import P2PAmount from "./P2PAmount";
import {
  useCheckPendingOrderQuery,
  useInitiateOrderMutation,
  useLazySendP2POTPQuery,
} from "../../features/api/walletSlice";
import ArrowRightYellow from "../../assets/icons/arrow-right-yellow.svg";
import ArrowRightGray from "../../assets/icons/wallet/arrow-right-gray.svg";
import { TableLoader } from "../reusables";
import { useNavigate } from "react-router";
import { WithdrawVerifyEmail } from "./WithdrawInfo";
import { useTranslation } from "react-i18next";

const P2P = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useAppSelector((state) => state.auth);

  //inbound currency: This is the currecy the user receives
  //outbound currency: This is the currency the user sends
  const {
    selectedCurrency: outboundCurrency,
    outboundCurrency: inboundCurrency,
  } = useAppSelector((state) => state.wallet);
  //Amount the user sends
  const [inBoundValue, setInboundValue] = useState<string>("");
  //Amount the user receiives
  const [outBoundValue, setOutBoundValue] = useState<string>("");

  //Conditionally render withdrawal OTP
  const [withdrawalOTP, setWithdrawalOTP] = useState<boolean>(false);

  const [otp, setOtp] = useState<string>("");

  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [step, setStep] = useState<number>(1);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");

  const [initateOrder, { data, isLoading: initiatigOrder }] =
    useInitiateOrderMutation();
  const { data: pendingOrder, isLoading: loadingPendingOrder } =
    useCheckPendingOrderQuery(userId);
    const [trigger] = useLazySendP2POTPQuery();

  const handleAmountConfirm = () => {
    setStep(2);
  };

  const handleSubmit = () => {
    if(otp.length !== 6 ){
      setErr(t("pleaseEnterTheOTPThatWasSentToYourEmailAccount"));
    }else{
      setErr("");
      setWithdrawalOTP(false)
      let data = {
        payload: {
          inboundCurrencyId: inboundCurrency.id,
          outboundCurrencyId: outboundCurrency.id,
          inboundAmount: Number(inBoundValue),
          outboundAmount: Number(outBoundValue),
          bankRecordId: selectedBank?.id + "",
          phoneNumber: phoneNumber,
          otp: otp,
          pin: ""
        },
        orderType: "Sell",
        userId: userId,
      };
    initateOrder(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(3);
        } else {
          setErr(res?.message);
        }
      })
      .catch((error) => setErr(error?.data?.message));
    }
  };

  useEffect(() => {
    if (!outboundCurrency?.name) {
      navigate("/home/wallet");
    }
  }, [navigate, outboundCurrency?.name]);
  // }, []);

  useEffect(() => {
    if (pendingOrder?.data.orderStatus === "ProcessingRequest") {
      setStep(4);
    }
  }, [pendingOrder]);

  const handleOtp = (res: string) => {
    setOtp(res);
  };

    //If user confirms, send OTP
    const handleSendOTP = () => {
      setErr("");
      trigger({
        currencyId: outboundCurrency.id,
        channel: "XendBridge",
      });
      setWithdrawalOTP(true)
    };


  //Function to handle rendered page for p2p
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <P2PAmount
            handleAmountConfirm={handleAmountConfirm}
            setOutBoundValue={setOutBoundValue}
            outBoundValue={Number(outBoundValue)}
            inBoundValue={Number(inBoundValue)}
            setInboundValue={setInboundValue}
            setSelectedBank={setSelectedBank}
            selectedBank={selectedBank}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            type={"WITHDRAWAL"}
            inboundCurrency={inboundCurrency}
            outboundCurrency={outboundCurrency}
          />
        );
      case 2:
        return (
          <Confirm
            handleSubmit={handleSendOTP}
            outBoundValue={Number(outBoundValue)}
            inBoundValue={Number(inBoundValue)}
            selectedBank={selectedBank}
            isLoading={initiatigOrder}
            type={"WITHDRAWAL"}
            inboundCurrency={inboundCurrency}
            outboundCurrency={outboundCurrency}
            errMsg={errMsg}
          />
        );
      case 3:
        return (
          <Success data={data?.data as OrderResponse} type={"WITHDRAWAL"} />
        );
      case 4:
        return (
          <Success
            data={pendingOrder?.data?.orderResponse as OrderResponse}
            type={"FUND"}
          />
        );
      default:
        return;
    }
  };

  return (
    <section className="bg-clrBg dark:bg-clrDarkBg2 w-[91%] max-w-[36rem] mx-auto pb-6 overflow-y-auto">
      <div className="flex items-center justify-between px-8 my-5">
        <div
          className="flex items-center justify-between cursor-pointer gap-x-4 min-[325px]:gap-x-8 sm:gap-x-11 md:gap-x-[5.75rem]"
          onClick={() => step > 1 && setStep(1)}
        >
          <h3 className="text-x10 font-semibold text-[#1a1a1a] dark:text-white">
            {t("amount")}
          </h3>
          <img
            src={step >= 1 ? ArrowRightYellow : ArrowRightGray}
            alt={t("moreOptions")}
            width={20}
            height={20}
          />
        </div>
        <div
          className="flex items-center justify-between cursor-pointer gap-x-4 min-[325px]:gap-x-8 sm:gap-x-11 md:gap-x-[5.75rem]"
          onClick={() => step > 2 && setStep(2)}
        >
          <h3 className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
            {t("confirm")}
          </h3>
          <img
            src={step >= 2 ? ArrowRightYellow : ArrowRightGray}
            alt={t("moreOptions")}
            width={20}
            height={20}
          />
        </div>
        <div className="flex items-center justify-between cursor-pointer gap-x-3">
          <h3 className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
            {t("success")}
          </h3>
        </div>
      </div>
      {loadingPendingOrder ? <TableLoader /> : renderStep()}
      {withdrawalOTP && <WithdrawVerifyEmail 
      handleModal={()=>setWithdrawalOTP(false)} 
      handleContinue={handleSubmit} 
      handleChange={handleOtp} 
      errMsg={errMsg} 
      resendOtp={handleSendOTP} 
      loading={initiatigOrder} 
      />}
    </section>
  );
};

export default P2P;
