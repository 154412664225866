import { useState } from "react";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { EmptyCard, TableLoader } from "../reusables";
import { useParams } from "react-router-dom";
import { useGetBlacklistedCategoriesQuery } from "../../features/api/parentalControlSlice";
import { BlacklistedCategoriesData } from "../../features/api/parentalControlSliceType";
import { ParentalControlCategoryCard } from "../reusables/ParentalControlCategoryCard";


const ParentalCategories = () => {
  const { deviceId } = useParams();
  const [searchValue, setSearch] = useState<string>("");
  const { data, isLoading: gettingBlacklistedCategories } = useGetBlacklistedCategoriesQuery({deviceId: deviceId as string, Search: searchValue});
  const blacklistedCategories: any = data?.data || [];

  return (
    <section className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
      <div>
        <h3 className="text-sm md:text-base font-semibold text-black dark:text-clrPlaceholder mb-1.5">
          Categories
        </h3>
        <p className="text-xs text-[#6B6B6B] dark:text-clrPlaceholder mb-8"> These are categories with url addresses assigned to it</p>
      </div>
      <div className="flex flex-wrap items-end gap-4 mb-8 md:flex-nowrap">
        <MobileFilterSearch>
          <div>
            <label
              htmlFor=""
              className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
            >
                Search
            </label>
            <input
              type="text"
              name=""
              id=""
              value={searchValue}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Categories"
              className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
          </div>
        </MobileFilterSearch>
      </div>
        {gettingBlacklistedCategories ? <div className="m-auto"><TableLoader /></div> :
          <div>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 xl:grid-cols-4 md:gap-4">
              {blacklistedCategories.map((category:BlacklistedCategoriesData, index: number)=>{
                return (
                  <ParentalControlCategoryCard key={index} title={category.name} noOfAddresses={category.numberOfAddresses} link={category.id} />
                )
              })}
            </div>
              {!gettingBlacklistedCategories && !blacklistedCategories?.length && (
                <EmptyCard description={'No Category Found'} />
              )}
          </div>
        }
    </section>
  )
}

export default ParentalCategories
