import { useEffect } from 'react';
import { useRefreshTokenMutation } from '../features/api/authSlice';

const TokenRefreshInterval = 1 * 60 * 1000; // 3 minutes

const useTokenRefresh = () => {
  console.log("got called")
  const [refreshAccessToken] = useRefreshTokenMutation();

  useEffect(() => {
    let pathList = ['/', "/signup", "/reset", "/passwords/reset"]
    const refreshToken = async () => {
      if(!pathList.includes(window.location.pathname)){
        try {
          const userString = localStorage.getItem('user') || '{}';
          const user = JSON.parse(userString);
  
          let data = {
            accessToken: user?.accessToken,
            refreshToken: user?.refreshToken
          }
  
          refreshAccessToken(data)
          .unwrap()
          .then((res)=>{
            if(res.success){
              localStorage.setItem(
                'user',
                JSON.stringify(res?.data)
              );
            }else{
              window.location.replace('/');
              localStorage.clear();
            }
          })
          .catch((err)=>{
            if(err){
              window.location.replace('/');
              localStorage.clear();
            }
            
          })
  
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      }
      
    };

    const intervalId = setInterval(refreshToken, TokenRefreshInterval);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [refreshAccessToken]);
};

export default useTokenRefresh;


