import SpeedIcon from "../../assets/icons/speed.svg";
import { useGetHotspothubstatisticsQuery } from "../../features/api/explorerSlice";
import { ScaleLoader } from "react-spinners";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const DeviceDetails = () => {
  const {t} = useTranslation();
  const { tokenId } = useParams();

  //Get the hotspothub statistics
  const { data: hubDetail, isLoading: gettingHubDetail } =
    useGetHotspothubstatisticsQuery({
      tokenId: tokenId as string,
      lookbackPeriodInHours: "",
      chartLookbackPeriodInDays: "",
    });

  return (
    <div className="flex flex-wrap gap-4 mb-5 md:gap-8">
      <div className="flex items-center gap-2">
        <p className="mr-2 text-xs font-medium md:text-sm text-clrGray dark:text-clrPlaceholder">
          {t("hubName")}:{" "}
        </p>
        <p>
          <span className="text-xs font-bold text-black dark:text-white md:text-sm">
            {gettingHubDetail ? (
              <ScaleLoader
                loading={gettingHubDetail}
                height={12}
                width={2}
                color={"#3d5170"}
              />
            ) : (
              hubDetail?.data.deviceName
            )}
          </span>{" "}
          {/* <span className="font-normal text-x10 md:text-sm">#234</span> */}
        </p>
        {hubDetail?.data.isActive ? (
          <div className="w-3 h-3 rounded-full bg-clrGreen"></div>
        ) : (
          <div className="w-3 h-3 rounded-full bg-clrRed2"></div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <p className="mr-2 text-xs font-medium md:text-sm text-clrGray dark:text-clrPlaceholder">
          {t("city")}:
        </p>
        {gettingHubDetail ? (
          <ScaleLoader
            loading={gettingHubDetail}
            height={12}
            width={2}
            color={"#3d5170"}
          />
        ) : (
          <img
            src={hubDetail?.data.flagIconUrl}
            alt={t("increase")}
            width={20}
            height={20}
          />
        )}

        <p className="font-normal text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
          {gettingHubDetail ? (
            <ScaleLoader
              loading={gettingHubDetail}
              height={12}
              width={2}
              color={"#3d5170"}
            />
          ) : (
            hubDetail?.data.city
          )}
        </p>
      </div>
      <div className="flex items-center">
        <span className="mr-3 text-xs font-medium md:text-sm text-clrGray dark:text-clrPlaceholder">
          {t("speed")}:
        </span>
        <img src={SpeedIcon} alt="Increase" width={18} height={18} />
        <p className="font-normal text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
          {gettingHubDetail ? (
            <ScaleLoader
              loading={gettingHubDetail}
              height={12}
              width={2}
              color={"#3d5170"}
            />
          ) : (
            hubDetail?.data.connectionSpeed
          )}
        </p>
      </div>
    </div>
  );
};

export default DeviceDetails;
