import { Button, ErrorMsg } from '../reusables';
// import { SignUpSuccessModal } from '../auth/signUp/SignUpSuccessModal';
import AuthCode from 'react-auth-code-input';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVerify2faMutation } from '../../features/api/authSlice';
import { useTranslation } from 'react-i18next';

const TwoFaCode = () => {
  const {t} = useTranslation();
  const [faCode, setCode] = useState<string>('');
  const [isError, setError] = useState<string>('');
  // const [status, setSuccessStatus] = useState<boolean>(false);

  const navigate = useNavigate();

  const [verify2fa, { isLoading }] = useVerify2faMutation();

  //function to save the otp code sent
  const handleChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setCode(res);
  };

  const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (faCode.length === 6) {
      let data = {
        twoFactorCode: faCode,
        authenticationMedium: 'Authenticator',
      };
      verify2fa(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            // setSuccessStatus(true);
            navigate('/home/profile/security');
          } else {
            setError(res.message);
          }
        })
        .catch((err) => setError(err?.message));
    } else {
      setError('Please enter a valid code');
    }
  };

  return (
    <section className="w-[96%] mx-auto md:pb-6 my-3 overflow-y-auto bg-white dark:bg-clrDarkBg mt-6 rounded-lg">
      <div className="flex items-center flex-col  px-4 mt-5 md:mt-10 w-full mx-auto mb-12 md:mb-8 h-full">
        <div className="text-center">
          <h2 className="text-lg md:text-xl font-semibold text-black dark:text-white md:mb-[10px]">{t("authenticator")}</h2>
          <div className="mb-12">
            <p className="text-clrText dark:text-clrPlaceholder font-medium text-[0.625rem] lg:w-[62%] text-center mx-auto md:text-sm">
              {t("authenticationCodeDetails")}
            </p>
          </div>
        </div>
        <div className="text-center">
          <div className="mb-4">
            <AuthCode
              inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 border-transparent border-solid border rounded-md bg-primary/10"
              containerClassName="flex justify-center gap-3"
              ariaLabel="verify-otp"
              isPassword={true}
              autoFocus={true}
              length={6}
              allowedCharacters="numeric"
              onChange={handleChange}
            />
            <ErrorMsg errMsg={isError} />
          </div>
        </div>
        <div className="mt-6 mb-8 lg:w-[30%]">
          <Button
            type="submit"
            loading={isLoading}
            handleClick={handleVerify}
            text={t("finish")}
          />
        </div>
      </div>

      {/* {status && (
        <SignUpSuccessModal
          modalRef={undefined}
          handleButton={() => {
            navigate('/home/profile/info');
          }}
          title="Use an authentication app"
          description="Starting now, each time you log into your account, you must input your password along with an authentication code generated by Google Authenticator."
          buttonTitle={'Continue'}
          handleModal={() => {}}
        />
      )} */}
    </section>
  );
};

export default TwoFaCode;
