import ExplorerFirst from "../../assets/icons/explorer1.svg";
import Sync from "../../assets/e-sync.svg";
import Hub from "../../assets/e-hub2.svg";
import Chart from "../../assets/e-chart.svg";
import Claim from "../../assets/e-claim.svg";
import Boost from "../../assets/e-boost.svg";
import { useTranslation } from "react-i18next";

export const ExplorerOnboardingComponent = () => {
  const { t } = useTranslation();

  const EXPLORER_ONBOARDING_DATA = [
    {
      title: t("explorerOnboardingWelcomeTitle"),
      details: t("explorerOnboardingWelcomeDetails"),
      image: ExplorerFirst,
    },
    {
      title: t("explorerOnboardingSyncTitle"),
      details: t("explorerOnboardingSyncDetails"),
      image: Sync,
    },
    {
      title: t("explorerOnboardingHubTitle"),
      details: t("explorerOnboardingHubDetails"),
      image: Hub,
    },
    {
      title: t("explorerOnboardingStatisticsTitle"),
      details: t("explorerOnboardingStatisticsDetails"),
      image: Chart,
    },
    {
      title: t("explorerOnboardingRewardsTitle"),
      details: t("explorerOnboardingRewardsDetails"),
      image: Claim,
    },
    {
      title: t("explorerOnboardingBoostTitle"),
      details: t("explorerOnboardingBoostDetails"),
      image: Boost,
    },
  ];

  // not in use
  const TOOLTIP_ONBOARDING_DATA = [
    {
      title: t("tourWNTBalanceTitle"),
      details: t("tourWNTBalanceDetails"),
    },
    {
      title: t("tourBoostRewardTitle"),
      details: t("tourBoostRewardDetails"),
    },
    {
      title: t("tourClaimRewardsTitle"),
      details: t("tourClaimRewardsDetails"),
    },
    {
      title: t("tourEarningsTitle"),
      details: t("tourEarningsDetails"),
    },
    {
      title: t("tourHotspotHubsTitle"),
      details: t("tourHotspotHubsDetails"),
    },
    {
      title: t("tourConnectionsTitle"),
      details: t("tourConnectionsDetails"),
    },
  ];

  return { EXPLORER_ONBOARDING_DATA, TOOLTIP_ONBOARDING_DATA };
};