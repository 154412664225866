import { createColumnHelper } from "@tanstack/react-table";
import { MoreResponse } from "./hubModals";
import MonitorImage from "../../assets/hub/laptop-image.svg";
import TabletImage from "../../assets/hub/tablet-image.svg";
import PhoneImage from "../../assets/hub/mobile-image.svg";


import experienceImg from "../../assets/hub/experience.svg";
import longTextImg from "../../assets/hub/long-text.svg";
import singleChoiceImg from "../../assets/hub/single-choice.svg";
import dataSubImg from "../../assets/hub/data-sub.svg";
import dropdownFilterImg from "../../assets/hub/download-filter.svg";
import starRatingImg from "../../assets/hub/star-rating.svg";
// import uploadDocumentImg from "../../assets/hub/upload-document.svg";
import { QuestionType } from "./FormContent";
import moment from "moment";
import { IColor } from "./types";
import { FormResponse } from "../../features/api/dynamicFormSliceTypes";


const NetworkHubForm = createColumnHelper<FormResponse>();

export const NetworkHubFormColumn  = [
  NetworkHubForm.accessor("submissionTimeStamp", {
    header: "Date",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
    },
  }),

  NetworkHubForm.accessor("user", {
    header: "Email",
    cell: ({ row }) => {
      return (
        <p>
          {row?.original.user.email}
        </p>
      );
    },
  }),
  NetworkHubForm.accessor("deviceSubmittedFrom", {
    header: "Hub Name",
    cell: ({ row }) => {
      return (
        <p>
          {row?.original.deviceSubmittedFrom.name}
        </p>
      );
    },
  }),
  NetworkHubForm.accessor("deviceSubmittedFrom", {
    header: "BSSID",
    cell: ({ row }) => {
      return (
        <p>
          {row?.original.deviceSubmittedFrom.bssid}
        </p>
      );
    },
  }),
  NetworkHubForm.accessor("id", {
    header: "",
    cell: (val) => {
      const id = val.getValue();
      return (
        <MoreResponse id={id as string}  />
      );
    },
  }),
];

export const selectHubData = [
  {id: 1, name: "Hub 1"}, 
  {id: 2, name: "Hub 2"}, 
  {id: 3, name: "Hub 3"}, 
  {id: 4, name: "Hub 4"}, 
];

export const hubLinksData = [
  {id: 1, channel: "hubs", name: "Link via Hubs", description: "Select individual hubs you wish to link"},
  {id: 2, channel: "clusters", name: "Link via Clusters", description: "Select individual hubs you wish to link"}
]

 // Define an array of images corresponding to each device type
 export const PREVIEW_DATA = [MonitorImage, TabletImage, PhoneImage];


export const ContentOptions: Record<string, { label: QuestionType; image: string }> = {
  "text": {label: "Short Text", image: experienceImg},
  "textarea": { label: "Long Text", image: longTextImg },
  "radio": { label: "Single Choice", image: singleChoiceImg },
  "checkbox": { label: "Multiple Choice", image: dataSubImg },
  "dropdown": { label: "Dropdown", image: dropdownFilterImg },
   "rating":  { label: "Ratings", image: starRatingImg },
   "tel": {label: "Short Text", image: experienceImg},
  //  "file" :   { label: "Upload Document", image: uploadDocumentImg },
}


export const ThemeColors: IColor[] = [
  { name: 'Yellow', bgClass: '#E5B910', borderColor: '#6B6B6BCC'},
  { name: 'Blue', bgClass: '#25346A', borderColor: 'clrPlaceholder'},
  { name: 'Black', bgClass: '#1D1D1D', borderColor: 'clrPlaceholder'},
  { name: 'Grey', bgClass: '#6B6B6B', borderColor: 'clrPlaceholder'},
];


// preferredHubData contains a list of methods that users can choose to link hubs.
// Each method is represented as an object with an id, name, and description.
// This data is used to dynamically generate UI elements that allow users to select their preferred linking method.
