import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import { ReactComponent as HotspotHubs } from "../../assets/icons/hotspot-hubs.svg";
import { ReactComponent as Explorer } from "../../assets/icons/explorer.svg";
import { ReactComponent as RewardBooster } from "../../assets/icons/reward-booster.svg";
import { ReactComponent as Analytics } from '../../assets/icons/analytics.svg';
import { ReactComponent as HubForm } from '../../assets/icons/hub-form.svg';
// import { ReactComponent as ParentalControl } from '../../assets/sidenavbar/parental_control.svg';
import { ReactComponent as ParentalControl } from "../../assets/icons/parental-control.svg";
// import { ReactComponent as Voucher } from "../../assets/icons/voucher.svg";

import { ReactComponent as Referrals } from "../../assets/icons/referrals.svg";
import { ReactComponent as Agents } from "../../assets/agents.svg";
// import { ReactComponent as Business } from "../../assets/icons/business.svg";
// import { ReactComponent as Service } from "../../assets/icons/service-chrage.svg";
// import { ReactComponent as RouterLanding } from "../../assets/icons/router-landing.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as Subscription } from "../../assets/icons/subscription.svg";
import { ReactComponent as Token } from "../../assets/icons/Token.svg";
// import { ReactComponent as Advert } from "../../assets/icons/advert.svg";
import { ReactComponent as TvStation } from "../../assets/icons/tv-station.svg";
import { ReactComponent as Cluster } from "../../assets/icons/cluster.svg";
import { ReactComponent as AdvertControl } from "../../assets/icons/advert-control.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";

export const NavBarData = [
  {
    id: 1,
    icon: <Dashboard />,
    title: "home",
    desc: "Track your apps across devices",
    to: "/home/dashboard",
    path: "dashboard",
  },
  {
    id: 2,
    icon: <Wallet />,
    title: "wallet",
    desc: "Manage your transactions",
    to: "/home/wallet",
    path: "wallet",
  },
  {
    id: 3,
    icon: <Analytics />,
    title: 'analytics',
    desc: 'Hub insights at a glance',
    to: '/home/analytics',
    path: 'analytics',
  },
  {
    id: 4,
    icon: <HotspotHubs />,
    title: "hubs",
    desc: "Explore the world of decentralized Internet Sharing",
    to: "/home/hotspot-hubs",
    path: "hotspot-hubs",
  },
  {
    id: 5,
    icon: <Token />,
    title: "token",
    desc: "View your Token statistics ",
    to: "/home/token",
    path: "token",
  },
  {
    id: 6,
    icon: <HubForm />,
    title: 'hubForm',
    desc: 'Track your apps across devices',
    to: '/home/hub-form',
    path: 'hub-form',
  },
  {
    id: 7,
    icon: <ParentalControl />,
    title: 'parentalControl',
    desc: 'Restrict and mange hub access',
    to: '/home/parental-control',
    path: 'parental-control',
  },
  {
    id: 8,
    icon: <Subscription />,
    title: 'subscription',
    desc: 'View and pay for a perfect plan',
    to: '/home/subscription',
    path: 'subscription',
  },
  // {
  //   id: 9,
  //   icon: <TvStation />,
  //   title: 'My TV Station',
  //   desc: 'Your favorite channels at a go',
  //   to: '/home/tv-station',
  //   path: 'station',
  // },
  
];

export const NavBarDataMore = [
  {
    id: 12,
    icon: <Referrals />,
    title: "referrals",
    desc: "View your referral code and invite your friends ",
    to: "/home/referrals",
    path: "referrals",
  },
  {
    id: 4,
    icon: <Explorer />,
    title: "explorer",
    desc: "An intelligent mapping system that allows users see all the Wicrypt hotspot hubs near them",
    to: "/home/explorer",
    path: "explorer",
  },
  {
    id: 5,
    icon: <RewardBooster />,
    title: "booster",
    desc: "Lock up your weekly rewards to maximize interest over time.",
    to: "/home/booster",
    path: "booster",
  },
  // {
  //   id: 6,
  //   icon: <Voucher />,
  //   title: "Voucher",
  //   desc: "View your Voucher Statistics",
  //   to: "/home/vouchers",
  //   path: "vouchers",
  // },
  // {
  //   id: 8,
  //   icon: <Business />,
  //   title: "Sub Buisnesses",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  // {
  //   id: 9,
  //   icon: <Service />,
  //   title: "Service Charge",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  // {
  //   id: 10,
  //   icon: <RouterLanding />,
  //   title: "Router landing page",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },

  {
    id: 8,
    icon: <Cluster />,
    title: 'cluster',
    desc: 'Assign hubs to a group',
    to: '/home/cluster',
    path: 'cluster',
  },
  {
    id: 11,
    icon: <AdvertControl />,
    title: 'advertControl',
    desc: 'View details of running ads',
    to: '/home/advert-control/overview',
    path: 'advert-control',
  },

  {
    id: 14,
    icon: <Agents />,
    title: "agents",
    desc: "Invite new hub agents",
    to: "/home/agents",
    path: "agents",
  },
  {
    id: 11,
    icon: <Profile />,
    title: "profile",
    desc: "View your profile information",
    to: "/home/profile/info",
    path: "profile",
  },
  // {
  //   id: 15,
  //   icon: <Setting />,
  //   title: "Setting",
  //   desc: "Track your apps across hubs",
  //   to: "/home",
  // },
  {
    id: 16,
    icon: <LogOut />,
    title: "logOut",
    desc: "Are you sure you want to log out?",
    to: "/home/logOut",
  },
];

// export const ReportData = [
//   {
//     icon: WalletYellow,
//     Title: "Revenue",
//     value: "NGN60,00000",
//     rate: "40%",
//   },
//   {
//     icon: ChartIcon,
//     Title: "Sessions",
//     value: "1000",
//     rate: "40%",
//   },
//   {
//     icon: SwitchIcon,
//     Title: "Data Used",
//     value: "50GB",
//     rate: "40%",
//   },
// ];
