import { useRef, useState } from 'react';
import { Button, ButtonOutline, ErrorMsg, Success } from "../reusables";
import {
  ConnectedClusterModal,
  ConnectedHubsModal,
  EditFormHeaderModal,
  EditFormTitle,
  HubLoginAccess,
  ThemeColorModal,
} from "./hubModals";
import { useNavigate } from "react-router-dom";
import { IHubFormQuestion } from './FormContent';
import { ITokenDetail } from './types';
import { useCreateFormMutation } from '../../features/api/dynamicFormSlice';
import { processArray } from '../../Helper';
import { FormImage } from '../../features/api/dynamicFormSliceTypes';
import useOutsideClick from '../../Hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';

type IFormSetting = {
  formName: string,
  fieldList: IHubFormQuestion[],
  handleNavigate: (val: number) => void,
  sliderImages: File[],
  imageUrl: string[] | FormImage[],
}


const FormSetting = ({ formName, fieldList, handleNavigate, sliderImages, imageUrl }: IFormSetting) => {

  const navigate = useNavigate();

  const [isLoginAccess, setIsLoginAccess] = useState<boolean>(false);
  const [isSuccess, setSuccsess] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [authMedium, setAuthMedium] = useState<string>("HubLoginCode");
  const [tokenDetail, setTokenDetail] = useState<ITokenDetail>();
  const [clusterList, setClusterList] = useState<string[]>([]);

  const [editFormTitle, setEditFormTitle] = useState<boolean>(false);
  const [editFormHeader, setEditFormHeader] = useState<boolean>(false);
  const [themeColor, setThemeColor] = useState<boolean>(false);
  const [themeColorCode, setThemeColorCode] = useState<string>('#E5B910');

  const { t } = useTranslation();


  const handleProceed = (medium: string, token: ITokenDetail) => {
    setAuthMedium(medium);
    setTokenDetail(token)
  }


  const [createForm, { isLoading: creatingForm }] = useCreateFormMutation()

  const [isSelectCluster, setIsSelectCluster] = useState<boolean>(false);
  const [isSelectHub, setIsSelectHub] = useState<boolean>(false);

  const modalRefHubLogin = useRef<HTMLElement>(null);
  const modalRefHubCluster = useRef<HTMLElement>(null);

  const handleLoginModal = () => {
    setIsLoginAccess(false);
    setEditFormHeader(false);
    setThemeColor(false);
  };

  const handleCloseHubCluster = () => {
    setIsSelectCluster(false);
    setIsSelectHub(false);
  };

  useOutsideClick(modalRefHubLogin, handleLoginModal);
  useOutsideClick(modalRefHubCluster, handleCloseHubCluster);

  let data = {
    "form": {
      "id": formName,
      "fields": processArray(fieldList)
    }
  }

  const handleDynamicFormBuild = () => {
    setErrMsg("")
    const deviceList = clusterList.length > 0 ? clusterList.join(',') : "";
    let bodyFormData = new FormData()
    bodyFormData.append("FormName", formName)
    bodyFormData.append("FormType", "Questionnaire")
    bodyFormData.append("FormPayload", JSON.stringify(data))
    bodyFormData.append("ThemeColourCode", themeColorCode)
    // bodyFormData.append("Attachment", "")
    sliderImages.map((image)=>{
      bodyFormData.append("SliderImages", image)
    })
    // bodyFormData.append("Logo", "")
    bodyFormData.append("AuthenticationMedium", authMedium)
    bodyFormData.append("TokenValidity.ValidityType", tokenDetail?.type || "")
    bodyFormData.append("TokenValidity.ValidityPeriod", tokenDetail?.period || "")
    bodyFormData.append("TokenValidity.DataUnit", tokenDetail?.unit || "")
    bodyFormData.append("TokenValidity.DataVolume", tokenDetail?.volume || "")
    bodyFormData.append("DeploymentOption.DeviceListId", deviceList)
    bodyFormData.append("DeploymentOption.DeploymentPlan", clusterList.length > 0 ? "DeviceList" : "AllDevices")

    createForm(bodyFormData)
      .unwrap()
      .then(() => {
        setSuccsess(true)
      })
      .catch((err) => {
        setErrMsg(err?.data?.message || "An Error Occured")
      })
  }


  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">

      <div id="step-3" className="relative flex flex-col bg-white dark:bg-clrDarkBg rounded-lg py-[2.125rem] px-4 md:pl-11 md:pr-5 min-h-[calc(100vh-168px)] after:content[' '] after:absolute after:w-full after:h-[0.4375rem] after:bg-primary dark:after:bg-secondary after:rounded-t-lg after:top-0 after:left-0">
        <h2 className="text-base font-semibold text-clrGray dark:text-white">{t("hubFormSettings")}</h2>

        <div className="flex flex-col mt-11 md:pl-6 gap-10 max-w-[775px]">
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">Hub login access</h4>
                {/* <span className="bg-clrGreen2 bg-opacity-20 py-[3px] px-[6px] rounded-lg self-start">
                  <p className="text-clrGreen2 text-[0.625rem] font-normal">Timed access</p>
                </span> */}
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("hubLoginAccessDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("set")}
                handleClick={() => { setIsLoginAccess(true) }}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">Linked Hubs</h4>
                {/* <span className="bg-clrGreen2 bg-opacity-20 py-[3px] px-[6px] rounded-lg self-start">
                  <p className="text-clrGreen2 text-[0.625rem] font-normal">4 Clusters</p>
                </span> */}
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("linkedHubsDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("set")}
                handleClick={() => {setIsSelectCluster(true)}}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">{t("imageSliders")}</h4>
                <span className="bg-clrGreen2 bg-opacity-20 py-[3px] px-[6px] rounded-lg self-start">
                  <p className="text-clrGreen2 text-[0.625rem] font-normal">{t("maximumImage")}</p>
                </span>
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("imageSlidersDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("set")}
                handleClick={() => handleNavigate(1)}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">{t("formTitle")}</h4>
                <span className="bg-clrGreen2 bg-opacity-20 py-[3px] px-[6px] rounded-lg self-start">
                  <p className="text-clrGreen2 text-[0.625rem] font-normal">{t("networkFeedback")}</p>
                </span>
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("formTitleDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("edit")}
                handleClick={() => { setEditFormTitle(true) }}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">{t("headerTextDescription")}</h4>
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("headerTextDescriptionDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("set")}
                handleClick={() => { setEditFormHeader(true) }}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <div className="mb-2 flex items-center gap-2.5">
                <h4 className="text-base font-medium text-black dark:text-white">{t("themeColor")}</h4>
              </div>
              <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("themeColorDetails")}</p>
            </div>
            <div className="flex flex-col justify-center gap-3 md:justify-end md:w-20">
              <Button
                type="button"
                text={t("set")}
                handleClick={() => { setThemeColor(true) }}
                fontWeight="font-normal"
                py="md:py-2"
                bgColor="bg-primary dark:bg-white"
              />
            </div>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>

        <div className="flex gap-3 mt-auto md:ml-auto md:justify-end md:w-fit">
          <ButtonOutline
            type="button"
            text={t("backToForm")}
            handleClick={() => handleNavigate(0)}
            fontWeight="font-normal"
            borderColor="border-primary dark:border-secondary"
            textColor="text-primary dark:text-secondary"
            wBtn="md:w-[12rem] px-4"
          />
          <Button
            type="button"
            text={t("finish")}
            loading={creatingForm}
            handleClick={handleDynamicFormBuild}
            fontWeight="font-normal"
            wBtn="md:w-[12rem]"
            bgColor="bg-primary dark:bg-white"
          />
        </div>
      </div>
      {isLoginAccess &&
        <HubLoginAccess
          modalRef={modalRefHubLogin}
          handleLoginModal={handleLoginModal}
          handleProceed={handleProceed}
          medium={authMedium}
          loginDetail={tokenDetail as ITokenDetail}
        />}
      
      {/* We only select clusters. This has been redacted. Modal to select links via hubs or cluster */}
      {/* {isSelectHubOrCluster && <ChooseHubOrCluster handleCloseModal={handleLoginModal} handleSubmit={handleHubMethods}/>} */}
        
      {isSelectHub && <ConnectedHubsModal modalRef={modalRefHubCluster} handleCloseModal={handleCloseHubCluster} handleHubList={setClusterList}/>}
      {isSelectCluster && <ConnectedClusterModal modalRef={modalRefHubCluster} handleCloseModal={handleCloseHubCluster} handleHubList={setClusterList}/>}

      {isSuccess &&
        <Success
          buttonTitle="Continue"
          modalRef={undefined}
          title="Form Created Successfully!"
          handleButton={() => { navigate('/home/hub-form'); setSuccsess(!isSuccess) }}
        />}

        {/* Modal for editing the form title */}
        {editFormTitle &&
          <EditFormTitle
            handleModal={() => setEditFormTitle(false)}
          />
        }

        {/* Modal for editing the form header and description */}
        {editFormHeader &&
          <EditFormHeaderModal
            modalRef={modalRefHubLogin}
            handleCloseModal={() => setEditFormHeader(false)}
            formQuestions={fieldList}
            imageUrl={imageUrl}
          />
        }

        {/* Modal for edit the theme color */}
        {themeColor &&
          <ThemeColorModal
            modalRef={modalRefHubLogin}
            handleCloseModal={() => setThemeColor(false)}
            formQuestions={fieldList}
            imageUrl={imageUrl}
            setThemeColorCode={setThemeColorCode}
            themeColorCode={themeColorCode}
          />
        }
    </section>
  )
}

export default FormSetting