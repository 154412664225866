import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { BoostReward, CancelBoost } from "./BoostReward";
import { Success } from "../reusables";
import { useTranslation } from "react-i18next";


interface BoosterFlowProps {
    boosterOption: number;
    setIsBoosterOption: (option: number) => void;
}
//Component with the booster reward overview and modals for handling the booster details
const BoosterFlow: React.FC<BoosterFlowProps> = ({ boosterOption, setIsBoosterOption }) => {
    const {t} = useTranslation();
    
    const [boostOption, setBoostOption] = useState<string>("");
    const navigate = useNavigate();
    const boosterRef = useRef<HTMLElement>(null);

    const handleCloseModal = () => {
        setIsBoosterOption(0);
    };

    const handleBoostReward = (type: string) => {
        setIsBoosterOption(3);
        setBoostOption(type);
    }

    const handleViewHistory = () => {
        navigate("/home/booster/history")
    }

    useOutsideClick(boosterRef, handleCloseModal);

    return (
        <>
            {boosterOption === 1 &&
                <BoostReward
                    modalRef={boosterRef}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={handleBoostReward}
                />}
            {boosterOption === 2 &&
                <CancelBoost
                    modalRef={boosterRef}
                    handleCloseModal={handleCloseModal}
                    handleSubmit={handleBoostReward}
                />}
            {boosterOption === 3 &&
                <Success
                    modalRef={boosterRef}
                    handleButton={handleViewHistory}
                    buttonTitle={t("viewBoostDetails")}
                    title={boostOption === "opt_in" ? t("boostActivated") : t("boostDeactivated")}
                    description={`${t("YouSuccessfully")} ${boostOption === "opt_in" ? t("activated") : t("deactivated")} ${t("restOfYouSuccessfully")}`}
                />}
        </>
    );
};

export default BoosterFlow;

