import { stakingApi } from "./apiSlice";
import { ForceUnlockDetailsResponse, StakeRequest, StakeStatusResponse, StakingOverviewResponse, StakingReportResponse, SuccessResponse } from "./stakingSliceTypes";


const stakingSlice = stakingApi.injectEndpoints({
    endpoints: (build) => ({

       //Staking report for a user
       getStakingReport: build.query<StakingReportResponse, string>({
            query: (userId) => `/stake/user/${userId}`,
            providesTags: ["Wallet", "Staking"]
        }),

        //Staking overview
       getStakingOverview: build.query<StakingOverviewResponse, void>({
            query: () => `/stake-report/overview`,
            providesTags: ["Wallet", "Staking"]
        }),


         //Show stake status
         stakeStatus: build.query<StakeStatusResponse, void>({
            query: () => `/stake/status`,
            providesTags: ["Wallet", "Staking"]
        }),

        //Force unlock status
        getForceUnlockDetails: build.query<ForceUnlockDetailsResponse, void>({
            query: () => `/stake/force-unlock-penalty`,
            providesTags: ["Wallet", "Staking"]
        }),



         //Staking overview
        stake: build.mutation<SuccessResponse, StakeRequest>({
            query: (payload) => ({
                url: `/stake`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Wallet", "Staking"]
        }),

        //UnStaking overview
        unstake: build.mutation<SuccessResponse, void>({
            query: (payload) => ({
                url: `/stake`,
                method: "PUT",
                body: payload
            }),
            invalidatesTags: ["Wallet", "Staking"]
        }),

         //UnStaking overview
        forceunlock: build.mutation<SuccessResponse, void>({
            query: (payload) => ({
                url: `/stake/force-unlock`,
                method: "PUT",
                body: payload
            }),
            invalidatesTags: ["Wallet", "Staking"]
        }),

        //Claiming reward 
        claimReward: build.mutation<SuccessResponse, void>({
            query: (payload) => ({
                url: `/stake/claim-reward`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Wallet", "Staking"]
        }),


    }),
    overrideExisting: true,
})

export const { useGetStakingReportQuery, useGetStakingOverviewQuery, useStakeMutation, useClaimRewardMutation, 
    useStakeStatusQuery, useGetForceUnlockDetailsQuery, useUnstakeMutation, useForceunlockMutation } = stakingSlice