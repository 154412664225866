import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MintBag from "../../assets/icons/hotspot-hubs/bag.svg";
import { ReactComponent as LightIcon } from "../../assets/icons/hotspot-hubs/light.svg";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import CloseIcon from "../../assets/icons/hotspot-hubs/close.svg";
import SearchIcon from "../../assets/icons/hotspot-hubs/search.svg";
import { Button, ButtonYellow, ErrorMsg } from "../reusables";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { Modal } from "../reusables/Modal";
import {
  useSetBillingRateMutation,
  useDeleteDeviceMutation,
} from "../../features/api/deviceSlice";
import { DeleteModal } from "../agents/AgentsModal";

export const Options: React.FC<OptionProps> = ({
  modalRef,
  name,
  id,
  closeFilter,
}) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsEdit(false);
    setIsDelete(false);
    closeFilter();
  };

  const [deleteDevice, { isLoading: deletingDevice }] =
    useDeleteDeviceMutation();
  const [errMsg, setErr] = useState<string>("");

  const handleDeleteDevice = () => {
    setErr("");
    deleteDevice(id as string)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          handleCloseModal();
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  useOutsideClick(modalRef, handleCloseModal);

  return (
    <div>
      <div className="absolute z-30 w-32 p-3 pb-4 bg-white rounded dark:bg-clrDarkBg top-10 right-10">
        <ul className="space-y-2 text-xs font-normal text-[#6b6b6b] dark:text-clrPlaceholder ">
          <li className="py-1 pl-2 transition duration-150 ease-in rounded hover:bg-clrBorder dark:hover:bg-clrDarkBg2">
            <button
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              {t("setBillingRate")}
            </button>
          </li>
          <li
            onClick={() => {
              setIsDelete(!isEdit);
            }}
            className="text-[#DB5F5F] cursor-pointer pl-2 py-1 rounded hover:bg-clrBorder dark:hover:bg-clrDarkBg2 transition duration-150 ease-in"
          >
            {t("deleteHub")}
          </li>
        </ul>
      </div>

      <div
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></div>
      {isEdit && (
        <AddEditDevices
          handleCloseModal={handleCloseModal}
          hubId={id as string}
          hubName={name as string}
        />
      )}
      {isDelete && (
        <DeleteModal
          title={`${t("deleteDevice")}`}
          description={t("deleteDeviceDescription")}
          handleModal={handleCloseModal}
          handleSubmit={handleDeleteDevice}
          errMsg={errMsg}
          isLoading={deletingDevice}
          message={`${t("deleteDeviceMessage")} ${name}`}
        />
      )}
    </div>
  );
};

export const OptionsDisconnect: React.FC<StatusProps> = ({ modalRef }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="absolute z-30 px-6 py-3 bg-white rounded top-10 w-fit right-10">
        <p className="text-xs font-normal text-[#6B6B6B]">{t("disconnect")}</p>
      </div>
      <div
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></div>
    </div>
  );
};

//Component to handle setting billing rate
export const AddEditDevices: React.FC<SetBillingReateProps> = ({
  handleCloseModal,
  hubId,
  hubName,
}) => {
  const { t } = useTranslation();
  const [billingRate, setBillingRate] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");

  const [setRate, { isLoading }] = useSetBillingRateMutation();

  const handleSetBillingRate = () => {
    let data = {
      hubId,
      rateInUSDPerGB: Number(billingRate),
    };
    setErr("");
    setRate(data)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          handleCloseModal();
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });

  };
  return (
    <Modal
      close={true}
      title={t("setBillingRate")}
      description={t("editYourBillingRate")}
      handleCloseModal={handleCloseModal}
      goBack={false}
      handleGoBack={() => { }}
    >
      <>
        <div className="p-4 mb-5 bg-white dark:bg-clrDarkBg">
          <label className="block mb-2 text-[10px] font-semibold text-black dark:text-white">
            {t("hubName")}
          </label>
          <div className="relative flex justify-between items-center border-b-[1px] border-solid border-clrBorder">
            <p className="block w-full pb-1 text-xs focus:outline-none text-clrGray dark:text-clrPlaceholder">
              {hubName}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-5 px-2 py-6 bg-white dark:bg-clrDarkBg">
          <p className="text-[10px] font-semibold text-[#212121] dark:text-white">
            {t("setBillingRate")}
          </p>
          <p className="text-[10px] font-normal text-[#b6b6b6] dark:text-clrPlaceholder">
            {t("tapToEnterRate")}
          </p>
          <div className="flex">
            <input
              className="w-1/2 text-5xl font-extrabold text-right text-black outline-none dark:placeholder:!text-white dark:text-white dark:bg-clrDarkBg"
              type="number"
              placeholder="0"
              value={billingRate}
              onChange={(e) => setBillingRate(e.target.value)}
            />
            <span className="self-end pb-4 text-[10px] font-semibold text-[#7d7d7d] dark:text-clrPlaceholder">
              {" "}
              USD/GB
            </span>
          </div>
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="w-[80%] mt-8 mx-auto ">
          <Button
            text={t("editRate")}
            type="button"
            loading={isLoading}
            handleClick={handleSetBillingRate}
          />
        </div>
      </>
    </Modal>
  );
};

export const ChooseDevice = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute py-3 px-5 -translate-x-1/2 bg-white w-[300px] top-5 left-1/2">
      <h3 className="text-xs font-semibold text-black">{t("chooseHub")}</h3>
      <div className="my-3 flex items-center gap-3 bg-[#fdfdfd] px-2 py-1 border-[1px] border-solid border-[#ddd] rounded-[50px]">
        <img src={SearchIcon} alt="search icon" width={15} height={15} />
        <input
          type="search"
          name=""
          id=""
          placeholder={t("searchDeviceName")}
          className="placeholder:text-[#aeaeae] placeholder:text-[10px] bg-inherit focus:outline-none text-xs font-medium text-clrGray"
        />
      </div>
      <ul className="space-y-4 text-[10px] font-semibold">
        <li className="text-[#323131] hover:text-clrTextBlue">{t("allHubs")}</li>
        <li className="text-[#323131] hover:text-clrTextBlue">{t("bhRouter")}</li>
        <li className="text-[#323131] hover:text-clrTextBlue">{t("enuguWifi")}</li>
        <li className="text-[#323131] hover:text-clrTextBlue">
          {t("openWrtRouter")}
        </li>
        <li className="text-[#323131] hover:text-clrTextBlue">{t("bhRouter2")}</li>
      </ul>
    </div>
  );
};

export const Trackdata: React.FC<TrackdataProps> = ({
  modalRef,
  handleTrackdata,
}) => {
  const { t } = useTranslation();
  const [isChooseDeviceOpen, setIsChooseDeviceOpen] = useState(false);

  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] flex justify-center items-center"
      ref={modalRef}
    >
      <div className="bg-[#F4F4F4] px-4 py-6 z-30 rounded-xl w-72 md:w-[450px]">
        <div className="flex justify-end">
          <button onClick={handleTrackdata}>
            <img
              src={CloseIcon}
              alt={t("closeModal")}
              width={13}
              height={13}
              className="self-end"
            />
          </button>
        </div>
        <div>
          <h1 className="mb-6 text-base font-semibold text-black">
            {t("trackDataSubscription")}
          </h1>

          <div className="grid gap-6 p-4 bg-white rounded">
            <div className="">
              <label className="block mb-2 text-[10px] font-semibold text-black">
                {t("dataValue")} (in GB)
              </label>
              <input
                type="text"
                name=""
                id=""
                placeholder="0"
                className="block w-full placeholder:text-[#aeaeae] placeholder:text-xs pb-1 focus:outline-none text-xs border-b-[1px] border-solid border-clrBorder"
              />
            </div>

            <div>
              <label className="block mb-2 text-[10px] font-semibold text-black">
                {t("subscriptionDate")}
              </label>
              <div className="track-data">
                <input
                  type="date"
                  name=""
                  id=""
                  className="block w-full placeholder:text-[#aeaeae] placeholder:text-[8px] pb-1 focus:outline-none text-xs border-b-[1px] border-solid border-clrBorder"
                />
              </div>
            </div>
            <div className="relative">
              {isChooseDeviceOpen && <ChooseDevice />}
              <label
                htmlFor=""
                className="block mb-2 text-[10px] font-semibold text-black"
              >
                {t("selectHubs")}
              </label>
              <button
                onClick={() => {
                  setIsChooseDeviceOpen(!isChooseDeviceOpen);
                }}
                className=" bg-[#F4F4F4] rounded flex justify-between items-center p-2 w-full"
              >
                <p className="text-[10px] font-normal text-black">
                  {t("selectHubs")}
                </p>
                <img
                  src={ArrowDown}
                  alt="more options"
                  width={20}
                  height={20}
                  className=""
                />
              </button>
            </div>
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-[10px] font-semibold text-black"
              >
                {t("selectValidityPeriod")}
              </label>
              <button className="bg-[#F4F4F4] rounded flex justify-between items-center p-2 w-full">
                <p className="text-[10px] font-normal text-black">{t("sevenDays")}</p>
                <img
                  src={ArrowDown}
                  alt={t("moreOptions")}
                  width={20}
                  height={20}
                  className=""
                />
              </button>
            </div>
          </div>

          <div className="w-[80%] mt-8 mx-auto ">
            <Button text={t("trackData")} type="button" handleClick={() => { }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const EmptyTable = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-6 my-4">
      {/* <img src={LightIcon} alt="Empty" /> */}
      <LightIcon className="dark-light-icon" />
      <p className="text-sm font-normal text-clrGray3 dark:text-clrPlaceholder">
        {t("noResultFound")}
      </p>
      <ButtonYellow text={t("mintHub")} btnIcon={MintBag} to={""} />
    </div>
  );
};

export const MobileFilterSearch: React.FC<MobileProps> = ({ children }) => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFilter, setIsFilter] = useState(false);
  const modalRefFilter = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsFilter(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useOutsideClick(modalRefFilter, handleCloseModal);

  if (windowWidth >= 768) {
    return <>{children}</>;
  } else {
    return (
      <div className="relative flex-1 md:hidden basis-1">
        <button
          onClick={() => {
            setIsFilter(!isFilter);
          }}
          className="flex items-center justify-between relative h-8 w-full px-4 border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl"
        >
          <p className="text-[10px] font-normal text-clrPlaceholder">{t("filter")}</p>
          <img
            src={ArrowDown}
            alt={t("moreOptions")}
            className=""
            width={20}
            height={20}
          />
        </button>

        {/* <input
          type="text"
          name=""
          id=""
          placeholder="Search Name"
          className="flex-1 basis-1/2 w-full border-[1px] border-solid border-clrBorder rounded-3xl h-8 pb-1 px-4 placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit"
        /> */}

        {isFilter && <Filter element={children} modalRef={modalRefFilter} />}
      </div>
    );
  }
};

export const Filter: React.FC<FilterProps> = ({ modalRef, element }) => {
  const { t } = useTranslation();
  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] dark:bg-[#00000080] flex justify-center items-center"
      ref={modalRef}
    >
      <div className="z-30 px-3 pt-6 pb-10 bg-clrBg2 dark:bg-clrDarkBg2 rounded-xl w-72">
        <div>
          <h1 className="mb-3 text-xs font-semibold text-black dark:text-white dark:font-medium">
            {t("filter")}
          </h1>
          <div className="px-2 py-8 bg-white rounded-md dark:bg-clrDarkBg">
            {element}
          </div>
        </div>
      </div>
    </section>
  );
};
