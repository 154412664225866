import { useParams } from "react-router";
import ArrowBack from "../../assets/icons/arrow-right.svg";
import Clock from "../../assets/icons/devices-clock.svg";
import MapDetails from "./MapDetails";
import { useGetTransactionsQuery } from "../../features/api/explorerSlice";
import moment from "moment";
import { EmptyCard, TableLoader } from "../reusables";
import DeviceDetails from "./DeviceDetails";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Transaction = () => {
  const {t} = useTranslation();
  const { tokenId } = useParams();

  //Get the hotspothub statistics
  const { data: transctionHistory, isLoading: gettingTransactionHistory } =
    useGetTransactionsQuery({
      tokenId: tokenId as string,
      page: 1,
      pageSize: 30,
    });

  return (
    <section className="w-[96%] mx-auto pb-6 h-full mt-3 overflow-y-auto">
      <MapDetails />
      <div className="p-4 mt-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-6">
        <>
          <DeviceDetails />
          {gettingTransactionHistory ? (
            <TableLoader />
          ) : (
            transctionHistory?.data.records.map((transaction) => {
              return (
                <Link
                  to={`/home/explorer/session-name/${transaction?.transactionId}`}
                  className=" cursor-pointer flex items-center justify-between bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-3 md:pl-4 md:pr-5  shadow-wxl mb-2"
                >
                  <div>
                    <p className="mb-2 text-xs font-semibold text-black dark:text-white md:text-sm ">
                      {transaction.transactionId}
                    </p>
                    <div className="flex gap-2">
                      <img src={Clock} alt="" width={15} height={15} />
                      <p className="font-normal text-x9 md:text-x10 text-clrTextGray dark:text-clrPlaceholder">
                        {moment(transaction?.sessionTimeStamp)
                          .startOf("hour")
                          .fromNow()}
                      </p>
                    </div>
                  </div>
                  <button onClick={() => {}}>
                    <img
                      src={ArrowBack}
                      alt={t("arrowBack")}
                      width={15}
                      height={15}
                    />
                  </button>
                </Link>
              );
            })
          )}

          {!gettingTransactionHistory &&
            (transctionHistory?.data.records.length || 0) === 0 && (
              <EmptyCard description={t("noHubTransactionHistory")} />
            )}
        </>
      </div>
    </section>
  );
};

export default Transaction;
