import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { DeleteModal } from "../agents/AgentsModal";
import { FormCardProps } from "./types";
import { useDeactivateFormMutation, useDeleteFormMutation } from "../../features/api/dynamicFormSlice";
import { useTranslation } from "react-i18next";

const FormCard = ({status, formId, title, dateCreated}: FormCardProps) => {
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isDeactivate, setIsDeactivate] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [formOptionId, setFormOptions] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [trigger, {isLoading}] = useDeleteFormMutation();
  const [deactivateForm, { isLoading: deactivatingForm }] = useDeactivateFormMutation();


  const handleDelete = () =>{
    setErrMsg("")
    trigger(formId)
    .unwrap()
    .then((res)=>{
      if(res.success){
        setIsDelete(false)
      }
    })
    .catch((err)=>{
      if(err){
        setErrMsg(err?.data?.message || "Something went wrong")
      }
    })
  }

  const handleDeactivate = () =>{
    deactivateForm(formId)
    .unwrap()
    .then((res)=>{
      if(res.success){
        setIsDelete(false)
      }
    })
    .catch((err)=>{
      if(err){
        setErrMsg(err?.data?.message || "Something went wrong")
      }
    })
  }

  useEffect(()=>{
    setErrMsg("")
  }, [])
  
  return (
    <>
      <div onClick={()=> navigate(`/home/hub-form/detail/${formId}`)} className="bg-transparent border border-solid border-[#C4C4C466] grid gap-8 rounded-lg pt-5 w-[20rem] shadow-fxl">
        <div className="flex items-center justify-between px-5 relative">
          <p className="text-sm font-medium text-[#1D1D1D] dark:text-white">{title}</p>
          <button type="button"
             onClick={(e) => {
              e.stopPropagation();
              setFormOptions((prev) => (prev === formId ? "" : formId));
            }}>
            <Menu className="w-4 h-4" />
          </button>
          {formOptionId === formId &&
            <div className="absolute right-5 top-5 bg-white dark:bg-clrDarkBg border border-transparent dark:border-[#C4C4C466] shadow-md rounded px-3 py-2">
               {(status.toLowerCase()  || "")!== "inactive" && <div>
                <button className="text-sm hover:text-primary dark:hover:text-secondary dark:text-white transition duration-300 ease-in" type="button" onClick={(e)=> {e.stopPropagation(); setIsDeactivate(!isDeactivate)}}>  
                  {t("deactivateForm")}
                </button>
              </div>}
              {(status.toLowerCase()  || "")=== "inactive" &&
              <div>
                <button className="text-sm text-[#DB5F5F]" type="button" onClick={(e)=> {e.stopPropagation(); setIsDelete(!isDelete)}}>
                  {t("deleteForm")}
                </button>
              </div>}
              
            </div>
        }
        </div>
        <div className="px-5">
          <div className={` rounded-lg py-1 px-2 w-fit bg-opacity-20 ${status === "InUse" ? "bg-clrGreen2 text-clrGreen2" :  status === "pending" ? "bg-clrYellow2 text-clrYellow2" : "bg-clrRed3 text-clrRed3"}`}>
            <p className="text-[0.65rem] font-medium ">{status}</p>
          </div>
        </div>
        <div className="flex items-center justify-between border-t border-[#6B6B6B33] border-solid h-[3.45rem] px-5">
          <p className="text-xs font-medium text-clrPlaceholder dark:text-clrGray">{t("dateCreated")}: {moment(dateCreated).format('DD-MM-YYYY')} </p>
        </div>
      
      </div>
      {isDelete && (
        <DeleteModal
          title={t("deleteForm")}
          description={""}
          message={`${t("deleteModalDescription")} ${title}`}
          handleModal={() => setIsDelete(false)}
          handleSubmit={handleDelete}
          errMsg={errMsg}
          isLoading={isLoading}
        />
      )}
      {isDeactivate && (
        <DeleteModal
          title={t("deactivateForm")}
          description={""}
          message={
            <>
              {t("deactivateModalDescription")}{" "}
              <span className="font-semibold text-black">{title}</span>
            </>
          }
          handleModal={() => setIsDeactivate(false)}
          handleSubmit={handleDeactivate}
          errMsg={errMsg}
          isLoading={deactivatingForm}
        />
      )}
    </>

  )
}

export default FormCard