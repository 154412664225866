import { useState } from 'react'
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus"
import { EmptyCard, TableLoader } from "../reusables"
import TableMain from "../reusables/ReactDataTable"
// import { ReactComponent as DownlaodCSV } from "../../assets/hub/download-csv.svg";
import { useParams } from "react-router-dom"
import { NetworkHubFormColumn } from "./data"
import { useGetFormResponsesQuery } from '../../features/api/dynamicFormSlice'
import { useTranslation } from 'react-i18next'


const Responses = () => {
  const { t } = useTranslation();
  const { formId } = useParams();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");
  const [deviceToSearch, setDeviceSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);



  const { data, isLoading, isFetching } = useGetFormResponsesQuery({
    formId: formId as string,
    StartDate: startDateTimeStamp,
    EndDate: endDatetimeStamp,
    Page: page,
    PageSize: 30,
    Search: deviceToSearch
  });


  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp((selectedDate.getTime()).toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp((selectedDate.getTime()).toString());
  };

  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6">
        <h2 className='mb-6 text-sm font-semibold text-black dark:text-white'>Hub form response</h2>
        <div className="flex flex-wrap items-end gap-3 mb-8 md:flex-nowrap">
          <div className="flex w-full gap-3 md:w-auto">
            <MobileFilterSearch>
              <div className="relative">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
                  Start Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e)}
                />
              </div>
              <div className="relative">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
                  End Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e)}
                />
              </div>
            </MobileFilterSearch>
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name="Hub Name"
                onChange={(e) => setDeviceSearch(e.target.value)}
                value={deviceToSearch}
                placeholder="Email address"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div>
          {/* <Link
            to="#"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-clrYellow2 rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
          >
            <DownlaodCSV className="w-6 h-6" />
            <p className="text-xs font-medium text-white">Download CSV</p>
          </Link> */}
        </div>
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data?.records || []}
              columns={NetworkHubFormColumn}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage={data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}

        </div>
        {!isLoading && !isFetching && !data?.data?.records?.length && (
          <EmptyCard description="No result found" />
        )}
      </div>
    </section>
  )
}

export default Responses