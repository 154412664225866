import { useState } from "react";
import { ReactComponent as FormTemplate } from "../../assets/hub/form-template.svg";
import { useNavigate } from "react-router-dom";
import { useGetTemplateFormsQuery } from "../../features/api/dynamicFormSlice";
import { FormTemplates } from "../../features/api/dynamicFormSliceTypes";
import { useTranslation } from "react-i18next";


export const ViewTemplate = () => {
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState<string>("");

  const {data: templatedForms, isLoading: gettingTemplateForms} = useGetTemplateFormsQuery({search: templateName })

  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto h-full md:h-full-minus-0.75">
      <div className="bg-white dark:bg-clrDarkBg rounded-lg py-6 md:py-[2.125rem] px-4 md:pl-11 md:pr-5 h-full">
        <div className="flex flex-wrap items-end gap-3 mb-6 md:mb-8 md:flex-nowrap">
          <div className="flex w-full gap-3 md:w-auto">
          <div className="flex-1 md:flex-auto ">
            <label
              htmlFor=""
              className="block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
            >
              Search
            </label>
            <input
              type="text"
              name="Hub Name"
              onChange={(e) => setTemplateName(e.target.value)}
              value={templateName}
              placeholder="Search a template"
              className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
          </div>
          </div>
        </div>
        <div className="space-y-7 md:space-y-10">
          <h4 className="text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder">
            Templates
          </h4>
          {!gettingTemplateForms && <TemplateForms templatedForms={templatedForms?.data as FormTemplates[]} />}
        </div>
      </div>
    </section>)
}


export const TemplateForms = ({templatedForms} : {templatedForms: FormTemplates[]}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap items-center text-center gap-7 md:gap-10">
      {templatedForms?.map((form) => (
        <button onClick={() => navigate(`/home/hub-form/form-content/${form.name}/${form.templateId}`)} key={form.templateId}>
          <FormTemplate className="" />
          <p className="text-xs font-medium text-black mt-2 dark:text-white">{form.name}</p>
        </button>
      ))}
    </div>
  )
}