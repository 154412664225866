import { useState } from "react";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { CheckboxState, MoreResponseModalProps } from "../hubForm/types";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { Button, ButtonOutline, ErrorMsg, TableLoader } from "../reusables";
import { EmptyHubCluster } from "../hubForm/hubModals";
import { useAddClusterMutation } from "../../features/api/clusterSlice";
import { useAppSelector } from "../../app/hooks";
import { useTranslation } from "react-i18next";

export const AddClusterModal = ({ modalRef, handleCloseModal }: MoreResponseModalProps) => {
    const {t} = useTranslation();
    const [deviceStatusList, setIsCheckboxChecked] = useState<CheckboxState>({});
    const { businessId } = useAppSelector((state) => state.auth);
    const [isSelectAll, setSelectApp] = useState<boolean>(true);
    const [deviceToSearch, setDeviceSearch] = useState<string>("");
    const [clusterName, setClusterName] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [section, setSection] = useState<number>(0);

    const { data, isLoading, isFetching } = useGetDevicesQuery({
        deviceName: deviceToSearch,
        deviceStatus: "All",
        page: 1,
        pageSize: 30,
    });

    const [trigger, { isLoading: addingCluster }] = useAddClusterMutation()

    const handleCheckboxChange = (id: string) => {
        setIsCheckboxChecked(prevState => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the checkbox value
        }));
    };

    const handleSelectAll = () => {
        data?.data?.records.map((each) => {
            handleCheckboxChange(each.id)
        })
        setSelectApp(!isSelectAll)
    }


    const handleSave = () => {
        setErrorMsg("");
        const devicesWithTrueStatus = Object.entries(deviceStatusList)
            .filter(([_id, status]) => status === true)
            .map(([id]) => id);
        const clusterData = {
            payload: {
                listName: clusterName,
                deviceIds: devicesWithTrueStatus
            },
            businessId
        }
        if (devicesWithTrueStatus.length > 0) {
            trigger(clusterData)
                .unwrap()
                .then(() => {
                    handleCloseModal()
                })
                .catch((err) => {
                    setErrorMsg(err?.data?.message || "Something went wrong!")
                })

        } else {
            setErrorMsg("Select the devices you want this form applied to")
        }
    }

    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
            {section === 0 ?
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white dark:bg-clrDarkBg w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            {t("addCluster")}
                        </h3>
                    </div>
                    <p className="mt-2 text-xs font-normal text-clrGray mb-4 dark:text-clrPlaceholder">{t("clusterDescription")}</p>

                    <div className="grid gap-5">
                        <label className="text-sm font-semibold text-clrGray dark:text-white">{t("clusterName")}</label>
                        <input
                            type="text"
                            name="" id=""
                            placeholder={t("cluster")}
                            value={clusterName}
                            onChange={(e) => setClusterName(e.target.value)}
                            className="relative flex items-center dark:text-clrPlaceholder justify-between bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
                    </div>
                    <div className="flex mt-auto [&>*]:ml-0">
                        {/* <ButtonOutline
                            type="button"
                            text={"Back"}
                            handleClick={handleCloseModal}
                            fontWeight="font-normal"
                            wBtn="md:w-[8rem]"
                        /> */}
                        <Button
                            type="button"
                            text={t("next")}
                            disabled={clusterName === ""}
                            handleClick={() => setSection(1)}
                            fontWeight="font-normal"
                            wBtn="md:w-[8rem]"
                        />
                    </div>

                </div>
                :
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white dark:bg-clrDarkBg w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            {t("assignHubs")}
                        </h3>
                    </div>
                    <p className="mt-2 text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("assignHubsdescription")}</p>
                    <div className="flex flex-col gap-7 mt-5">
                        {(data?.data?.records.length || 0) > 0 &&
                            <>
                                <div className="flex items-center justify-between">
                                    <p className="text-sm font-semibold text-clrGray dark:text-white">{t("selectHubs")}</p>
                                    <button
                                        type="button"
                                        className="flex gap-2"
                                        onClick={() => handleSelectAll()}
                                    >
                                        {isSelectAll ?
                                            <p className="text-[0.625rem] font-semibold text-secondary">{t("selectAll")}</p> :
                                            <p className="text-[0.625rem] font-semibold text-secondary">{t("deSelectAll")}</p>
                                        }
                                    </button>
                                </div>
                                <div className="mt-1.5">
                                    <label htmlFor="" className="hidden">{t("search")}</label>
                                    <input
                                        type="text"
                                        name="" id=""
                                        placeholder={t("search")}
                                        value={deviceToSearch}
                                        onChange={(e) => setDeviceSearch(e.target.value)}
                                        className="relative flex items-center justify-between bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
                                </div>
                            </>}
                        <div className="space-y-6">
                            {isLoading && <TableLoader />}

                            {data?.data?.records.map((hubs) => {
                                return (
                                    <label
                                        key={hubs?.id}
                                        className="material-checkbox text-[10px] font-normal text-black dark:text-white"
                                    >
                                        {hubs?.name}
                                        <input
                                            checked={deviceStatusList[hubs?.id]}
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(hubs?.id)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                )
                            })}
                        </div>

                        {!isLoading && !isFetching && (data?.data?.records.length || 0) === 0 &&
                            <EmptyHubCluster
                                type="hubs"
                                message={t("emptyHubMessage")}
                                actionBtn={t("buyAHub")}
                                link="https://wicrypt.com/hubs"
                            />}
                    </div>
                    <ErrorMsg errMsg={errorMsg} />
                    {(data?.data?.records.length || 0) > 0 &&
                        <div className="flex justify-between mt-auto">

                            <ButtonOutline
                                type="button"
                                text={t("BackToInfo")}
                                handleClick={() => setSection(0)}
                                fontWeight="font-normal"
                                wBtn="md:w-[8rem]"
                            />
                            <Button
                                type="button"
                                text={t("next")}
                                handleClick={handleSave}
                                fontWeight="font-normal"
                                wBtn="md:w-[8rem]"
                                loading={addingCluster}
                            />
                        </div>
                    }
                </div>
            }

        </div>
    );
};

export const EditClusterModal = ({ modalRef, handleCloseModal }: MoreResponseModalProps) => {
    const {t} = useTranslation();
    const [deviceStatusList, setIsCheckboxChecked] = useState<CheckboxState>({});
    const { businessId } = useAppSelector((state) => state.auth);
    const [isSelectAll, setSelectApp] = useState<boolean>(true);
    const [deviceToSearch, setDeviceSearch] = useState<string>("");
    const [clusterName, setClusterName] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [section, setSection] = useState<number>(0);

    const { data, isLoading, isFetching } = useGetDevicesQuery({
        deviceName: deviceToSearch,
        deviceStatus: "All",
        page: 1,
        pageSize: 30,
    });

    const [trigger, { isLoading: addingCluster }] = useAddClusterMutation()

    const handleCheckboxChange = (id: string) => {
        setIsCheckboxChecked(prevState => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the checkbox value
        }));
    };

    const handleSelectAll = () => {
        data?.data?.records.map((each) => {
            handleCheckboxChange(each.id)
        })
        setSelectApp(!isSelectAll)
    }


    const handleSave = () => {
        setErrorMsg("");
        const devicesWithTrueStatus = Object.entries(deviceStatusList)
            .filter(([_id, status]) => status === true)
            .map(([id]) => id);
        const clusterData = {
            payload: {
                listName: clusterName,
                deviceIds: devicesWithTrueStatus
            },
            businessId
        }
        if (devicesWithTrueStatus.length > 0) {
            trigger(clusterData)
                .unwrap()
                .then(() => {
                    handleCloseModal()
                })
                .catch((err) => {
                    setErrorMsg(err?.data?.message || "Something went wrong!")
                })

        } else {
            setErrorMsg("Select the devices you want this form applied to")
        }
    }

    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
            <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white w-fit md:w-96 dark:bg-clrDarkBg">
                <div className="flex items-center gap-2 mb-6">
                    <button className="btn-reset" onClick={handleCloseModal}>
                        <ArrowBack className="w-5 h-5 dark-arrow" />
                    </button>
                    <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                        {t("editCluster")}
                    </h3>
                </div>

                {/* Tab navigation */}
                <div className="flex gap-3 [&>*]:mx-0 mb-5">
                    <Button
                        type="button"
                        text={t("clusterInfo")}
                        handleClick={() => setSection(0)} // Switch to section 0
                        fontWeight="font-normal"
                        wBtn="md:w-fit"
                        px="!px-4"
                        py="!py-1 md:!py-1"
                        bgColor={section === 0 ? "!bg-secondary" : "white"}
                        borderColor={section === 0 ? "border-none" : "!border !border-secondary"}
                        textColor={section === 0 ? "text-white" : "text-secondary group-hover:!text-white"}
                        hover="hover:bg-secondary"
                    />
                    <Button
                        type="button"
                        text={t("hubsHubs")}
                        handleClick={() => setSection(1)} // Switch to section 1
                        fontWeight="font-normal"
                        wBtn="md:w-[6rem]"
                        px="!px-4"
                        py="!py-1 md:!py-1"
                        bgColor={section === 1 ? "!bg-secondary" : "white"}
                        borderColor={section === 1 ? "border-none" : "!border !border-secondary"}
                        textColor={section === 1 ? "text-white" : "text-secondary group-hover:!text-white"}
                        hover="hover:bg-secondary"
                        loading={addingCluster}
                    />
                </div>

                {/* Section 0: Cluster Information */}
                {section === 0 ? (
                    <div>
                        <p className="mt-2 text-xs font-normal text-clrGray mb-4 dark:text-clrPlaceholder">{t("clusterDescription")}</p>
                        <div className="grid gap-4 mt-2">
                            <label className="text-xs dark:text-white">{t("clusterName")} </label>
                            <input
                                type="text"
                                placeholder={t("cluster")}
                                value={clusterName}
                                onChange={(e) => setClusterName(e.target.value)}
                                className="relative flex items-center justify-between dark:text-clrPlaceholder bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder dark:focus:border-secondary border-primary dark:border-clrGray focus:outline-none" />
                        </div>
                        <div className="grid gap-4 mt-5">
                            <label className="text-xs dark:text-white">{t("clusterName")}</label>
                            <input
                                type="text"
                                placeholder={t("myClusterAddress")}
                                value={clusterName}
                                onChange={(e) => setClusterName(e.target.value)}
                                className="relative flex items-center justify-between dark:text-clrPlaceholder bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder dark:focus:border-secondary border-primary dark:border-clrGray focus:outline-none" />
                        </div>
                    </div>
                ) : (
                    // Section 1: Hub Selection
                    <div>
                        <p className="mt-2 text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("clusterDescription")}</p>
                        <div className="flex flex-col gap-7 mt-5">
                            {(data?.data?.records.length || 0) > 0 && (
                                <>
                                    <div className="flex items-center justify-between">
                                        <p className="text-sm font-semibold text-clrGray dark:text-white">Select Hubs</p>
                                        <button
                                            type="button"
                                            className="flex gap-2"
                                            onClick={handleSelectAll}
                                        >
                                            {isSelectAll ? (
                                                <p className="text-[0.625rem] font-semibold text-secondary">{t("selectAll")}</p>
                                            ) : (
                                                <p className="text-[0.625rem] font-semibold text-secondary">{t("deSelectAll")}</p>
                                            )}
                                        </button>
                                    </div>
                                    <div className="mt-1.5">
                                        <label htmlFor="" className="hidden">{t("search")}</label>
                                        <input
                                            type="text"
                                            placeholder={t("search")}
                                            value={deviceToSearch}
                                            onChange={(e) => setDeviceSearch(e.target.value)}
                                            className="relative flex items-center dark:text-clrPlaceholder justify-between bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder dark:focus:border-secondary border-primary dark:border-clrGray focus:outline-none" />
                                    </div>
                                </>
                            )}

                            <div className="space-y-6">
                                {isLoading && <TableLoader />}
                                {data?.data?.records.map((hubs) => (
                                    <label key={hubs?.id} className="material-checkbox text-[10px] font-normal text-black dark:text-white">
                                        {hubs?.name}
                                        <input
                                            checked={deviceStatusList[hubs?.id]}
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(hubs?.id)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                ))}
                            </div>

                            {!isLoading && !isFetching && (data?.data?.records.length || 0) === 0 && (
                                <EmptyHubCluster
                                    type="hubs"
                                    message={t("emptyHubMessage")}
                                    actionBtn={t("buyAHub")}
                                    link="https://wicrypt.com/hubs"
                                />
                            )}
                        </div>
                        <ErrorMsg errMsg={errorMsg} />
                    </div>
                )}

                {/* Save Button */}
                <div className="flex mt-auto [&>*]:ml-0">
                    <Button
                        type="button"
                        text={t("save")}
                        disabled={clusterName === ""}
                        handleClick={handleSave}
                        fontWeight="font-normal"
                        wBtn="md:w-[8rem]"
                    />
                </div>
            </div>
        </div>

    );
};