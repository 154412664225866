import React, { useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import { ButtonYellow, EmptyCard, TableLoader } from "../reusables";
import Add from "../../assets/icons/wallet/add.svg";
import { InviteStaff } from "./AgentsModal";
import {
  useGetAgentsQuery,
  useCreateAgentMutation,
} from "../../features/api/agentSlice";
import { validateEmail } from "../../Helper";
import { useTranslation } from "react-i18next";
import { useAgentColumns } from "./data";

//Search for agents
const Agents: React.FC = () => {
const {t} = useTranslation();
const columns = useAgentColumns();

  //Controlling the input for searching agents
  const [searchValue, setSearchValue] = useState<string>("");

  //Controlling agent error message
  const [errMsg, setErr] = useState<string>("");

  //Modal to create agent
  const [createAgent, setCreateAgent] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  //Get all agents
  const { data, isLoading, isFetching } = useGetAgentsQuery({
    page,
    pageSize: 30,
    agentName: searchValue,
  });

  //Create agent
  const [createStaff, { isLoading: creatingAgent }] = useCreateAgentMutation();

  const handleCreateAgent = (data: { email: string }) => {
    if (!validateEmail(data.email)) {
      setErr(t("validEmail"));
    } else {
      setErr("");
      createStaff(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            setCreateAgent(false);
          } else {
            setErr(res?.message);
          }
        })
        .catch((err) => {
          setErr(err?.data?.message);
        });
    }
  };

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-8">
        <div className="flex flex-wrap items-end w-full gap-x-3 gap-y-7 md:px-4 md:w-auto">
          <div className="">
            <label
              htmlFor=""
              className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
            >
              {t("search")}
            </label>
            <input
              type="text"
              name="searchValue"
              id="searchValue"
              value={searchValue}
              placeholder={t("agentPlaceholder")}
              onChange={(e) => setSearchValue(e.target.value)}
              className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
          </div>
          <button onClick={() => setCreateAgent(true)}>
            <ButtonYellow text={t("createAgent")} btnIcon={Add} to={""} />
          </button>
        </div>
        {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain 
            defaultData={data?.data || []} 
            columns={columns} 
            totalPages={data?.meta.numberOfPages || 0}
            pageSize={data?.meta.pageSize || 0}
            currentPage = {data?.meta.page || 0}
            setPage={setPage}
          />
        )}

        {!isLoading && !isFetching && (data?.data.length || 0) === 0 && (
          <EmptyCard description={t("agentEmptyCard")} />
        )}
      </div>
      {createAgent && (
        <InviteStaff
          handleModal={() => setCreateAgent(false)}
          isLoading={creatingAgent}
          errMsg={errMsg}
          handleSubmit={handleCreateAgent}
        />
      )}
    </section>
  );
};

export default Agents;
