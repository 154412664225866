import Statistics from "../../assets/auth/statistics.svg";
import Funding from "../../assets/auth/funding.svg";
import Staking from "../../assets/auth/staking.svg";
import Rewards from "../../assets/auth/reward.svg";
import Tokens from "../../assets/auth/token.svg";

export const AUTH_FRAME_DATA = [
  {
    title: "Effortlessly Explore Hub Insights",
    details:
      "Simplifying Complex Data for Instant Insights.",
    image: Statistics,
  },
  {
    title: "Easy Funding of the Wicrypt Tokens",
    details:
      "Empowering Easy Access to Wicrypt Tokens.",
    image: Funding,
  },
  {
    title: "A Seamless Staking Experience",
    details:
      "Seamlessly Lock and Grow Your $WNT with interests",
    image: Staking,
  },
  {
    title: "Weekly Claim of Rewards",
    details:
      "Enjoy Regular Benefits with Ease",
    image: Rewards,
  },
  {
    title: "Generate Tokens for Data Usage",
    details:
      "Empowering Seamless Access and Connectivity",
    image: Tokens,
  },
];
