import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Button } from "../reusables";
import { TimerSuccessfulModal } from "../parentalControl/ParentalControlModals";
import { useState } from "react";
import CaptionIcon from "../../assets/icons/caption-icon.svg";
import { useActivateCaptionMutation } from "../../features/api/advertSlice";
import { useTranslation } from "react-i18next";

export interface AdsCaption {
  created_at: number,
  caption: string,
  is_active: boolean,
  id: string
}

// Advert Caption Column
const adsCaptionColumn = createColumnHelper<AdsCaption>();

const ActivateCaption = ({ captionId, isActiveCaption }: { captionId: string, isActiveCaption: boolean }) => {
  const [isCaptionModal, setCaptionModal] = useState<boolean>(false);
  const [activateCaption, { isLoading }] = useActivateCaptionMutation();

  const handleActivateCaption = () => {
    activateCaption(captionId)
      .unwrap()
      .then(() => {
       setCaptionModal(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }


  return (
    <div className="w-[40%]">
      <Button
        text={isActiveCaption ? "Active" : "Activate"}
        type="button"
        handleClick={() => setCaptionModal(!isCaptionModal)}
        fontWeight="font-medium"
        py="py-1.5"
        bgColor={isActiveCaption ? "bg-clrGreen2" : "bg-primary"}
      />
      {isCaptionModal &&
        <TimerSuccessfulModal
          title={isActiveCaption ? "Deactivate Ads Share" : "Ads Share Activated"}
          btnText="Proceed"
          description={isActiveCaption ? "Adverts will be deactivated on this hub and you will be unable to earn the ads revenue" : `Your hub will show adverts and will be eligible for the ads revenue`}
          handleModal={handleActivateCaption}
          isLoading={isLoading}
          icon= {CaptionIcon}
        />}
    </div>
  )
}


export const useAdsCaption = () => {
  const {t} = useTranslation();

  const adsCaption = [
    adsCaptionColumn.accessor("created_at", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format('Do MMM YYYY | h:mm a')}</p>;
      },
    }),
    adsCaptionColumn.accessor("caption", {
      header: t("caption"),
    }),
    adsCaptionColumn.accessor("is_active", {
      header: t("actionTable"),
      cell: (val) => {
        const actionStatus = val.getValue();
        const captionId = val.row.original.id
        return <ActivateCaption captionId={captionId || ''} isActiveCaption={actionStatus || false} />;
      },
    }),
  ];

  return adsCaption;
}

