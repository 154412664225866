import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";

const AdvertControl = () => {
  const {t} = useTranslation();
  return (
    <SecondaryNav
      paths={[
        { path: "/home/advert-control/overview", title: t("overview") },
        { path: "/home/advert-control/share", title: t("adsShare") },
        { path: "/home/advert-control/caption", title: t("adsCaption") },
      ]}
    />
  );
};

export default AdvertControl;
