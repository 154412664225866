import { useState } from "react";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle.svg";
import InfoIconYellow from "../../assets/icons/wallet/stake-info-circle.svg";
import { ScaleLoader } from "react-spinners";

/**
 * Represents the props for a dashboard card component.
 */
interface MoreDetailsCardProps {
  title: string; // The title of the card.
  info?: string; // Optional additional information for the card.
  // If this is present, we show the arrow icon.
  value: any; // The numerical value displayed on the card.
  // If this is present, user can navigate to another
  // part of the dashboard from the card.
  isLoading: boolean;
}

/**
@component
Creates a Dashboard card component with optional features 
@param {MoreDetailsCardProps} props
* */

export const MoreDetailsCard = ({
  title,
  value,
  info,
  isLoading,
}: MoreDetailsCardProps) => {
  // State to track tooltip visibility
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  // Function to handle the tooltip visibility
  const handleTooltipToggle = () => {
    setTooltipVisible((prevState) => !prevState);
  };
  return (
    <div className="flex justify-between items-center gap-2 md:gap-4 bg-white dark:bg-clrDarkBg2 dark:border-transparent border border-solid border-[#C4C4C433] rounded-lg p-3 md:p-5 h-[90px] md:h-[100px]">
      <div className="flex flex-col justify-between gap-2">
        <p className="whitespace-break-spaces mb-2 font-medium text-x10 text-clrTextLightGray dark:text-clrPlaceholder">
          {title}
        </p>
        <p className="text-base font-medium text-black dark:text-white">
          {isLoading ? (
            <ScaleLoader
            loading={isLoading}
            height={12}
            width={2}
            color={"#1d1d1d"}
            />
          ) : (
            value
          )}
        </p>
      </div>
      {info && (
        <div
          className="relative cursor-pointer"
          onMouseEnter={handleTooltipToggle}
          onMouseLeave={handleTooltipToggle}
        >
          <InfoIcon
            width={"15px"}
            height={"15px"}
            className="w-[13px] h-[13px] md:w-[15px] md:h-[15px] block dark:hidden"
          />
          <img
            src={InfoIconYellow}
            alt="More information"
            width={15}
            height={15}
            className="hidden dark:block"
          />
          <div
            className={`tooltip relative ${
              isTooltipVisible ? "show" : "hidden"
            }`}
          >
            <div className="absolute top-1 left-0 w-0 h-0 border-l-transparent border-solid border-r-transparent border-b-black border-l-[6px] border-r-[6px] border-b-[6px]"></div>
            <div className="font-normal text-x8 absolute bg-black text-white p-1 rounded z-[1] top-2 -right-1 w-32">
              <p className="text-x10 font-normal text-white">{title}</p>
              <p  className="text-x8 font-normal text-[#FBF8FC]">{info}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
