import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";

const Explorer = () => {
  const {t} = useTranslation();
 
  return (
      <SecondaryNav
        paths={[
          { path: "/home/explorer/overview", title: t("overview") },
          { path: "/home/explorer/hotspot-hubs", title: t("hubs") },
          { path: "/home/explorer/claim-history", title: t("claimHistory") },
        ]}
      />
  );
};

export default Explorer;
