import React, { useState, useRef, useCallback } from "react";
import { Button, DropDownContainer, ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { useGenerateTokenMutation } from "../../features/api/tokenSlice";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as CancelIcon }  from "../../assets/general/cancel.svg";
import { filesize } from "filesize";
import moment from "moment";
import { Token } from "./data";
import { useTranslation } from "react-i18next";
import { useTimeDropdownOptions } from "../../Helper";


type GenerateTokenProps = {
  handleModal: () => void;
};

type TimeUnit = 'days' | 'hours';

//Component to generate a token
export const GenerateToken: React.FC<GenerateTokenProps> = ({
  handleModal,
}) => {
  const {t} = useTranslation();
  const modalRef = useRef<HTMLElement>(null);
  const [option, toggleOption] = useState<string>("");
  const [tokenType, setTokenType] = useState<string>(t("data"));
  const [dataUnit, setDataUnit] = useState<string>("");
  const [dataVolume, setDataVolume] = useState<string>("");
  const [tokenQuantity, setTokenQuantity] = useState<string>("");
  const [tokenValidity, setTokenValidity] = useState<TimeUnit>('days');
  const [validityPeriod, setValidityPeriod] = useState<string>("");
  const [errorMsg, setError] = useState<string>("");

  const {renderDays, renderHours} = useTimeDropdownOptions();

  const secondsInOneDay = 24 * 60 * 60;

  //Generate Tokens
  const [generateToken, { isLoading }] = useGenerateTokenMutation();

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const handleGenerateTokens = () => {
    setError("");
    if (!tokenType) {
      setError(t("errorChooseTokenType"));
    } else if (validityPeriod === "" && tokenType === "timed") {
      setError(t("errorIndicateValidityPeriod"));
    } else if (dataVolume === "" && tokenType === "data") {
      setError(t("errorIndicateDataValue"));
    } else if (!tokenQuantity) {
      setError(t("errorSetTokenQuantity"));
    } else {
      let data = {
        customerId: "",
        secondsAllocated:
         tokenValidity === "days" && typeof validityPeriod === "number"
          ? validityPeriod * secondsInOneDay
          : tokenValidity === "days" && typeof validityPeriod === "string"
          ? 730 * secondsInOneDay // 730 days for 2 years
          : typeof validityPeriod === "number"
          ? validityPeriod * 60 * 60
          : 0,
        bytesAllocated:
          dataUnit === "GB"
            ? Number(dataVolume) * 1024 * 1024 * 1024
            : dataUnit === "MB"
            ? Number(dataVolume) * 1024 * 1024
            : Number(dataVolume) * 1024,
        codeValidityInSeconds: 0,
        dataTokenUsageType: tokenType,
        quantity: Number(tokenQuantity),
      };
      generateToken(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleModal();
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setError(err?.data.message);
        });
    }
  };
  const handleValidity = (unit: TimeUnit) => {
    setTokenValidity(unit);
    setValidityPeriod("");
  };
  const formatSelectedPeriod = (period: string | number | undefined, validity: 'days' | 'hours') => {
    if (!period) return t("placeholderSelectValidityPeriod");
    
    // Handle "Unlimited" case
    if (period === "Unlimited") {
      return t("unlimited");
    }

    // Convert period to number for proper typing
    const numericPeriod = typeof period === 'string' ? parseInt(period, 10) : period;
    
    return t('selectedPeriod', {
      count: numericPeriod,
      unit: validity === 'days' ? t('day') : t('hour'),
      defaultValue: `${numericPeriod} ${validity === 'days' ? t('day') : t('hour')}${numericPeriod > 1 ? 's' : ''}`
    });
  };
  return (
    <Modal close={true} title={t("generateTokenTitle")} handleCloseModal={handleModal}>
      <>
        <div className="flex flex-col gap-6 p-4 mb-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5 md:pb-8">
          <div>
            <Filter
              modalRef={modalRef}
              options={[
                { id: 1, value: t("data"), enum: t("data" )},
                { id: 2, value: t("tokenTimed"), enum: t("tokenTimed") },
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              // currentFilterOption={"TOKEN TYPE"}
              currentFilterOption={t("filterTokenType")}
              selectFilterOption={setTokenType}
              selectedOption={tokenType}
              label={t("labelTokenType")}
            />
            <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
              {t("descTokenTypePrompt")}
            </p>
          </div>
          {tokenType === t("data") && (
            <div className="flex flex-wrap items-center justify-between md:flex-nowrap gap-y-6 gap-x-4">
              <div className="w-[80%]">
                <label
                  htmlFor="volume"
                  className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  {t("labelDataVolume")}
                </label>

                <input
                  type="text"
                  name="volume"
                  value={dataVolume}
                  onChange={(e) => setDataVolume(e.target.value)}
                  placeholder={t("placeholderEnterDataVolume")}
                  className="text-clrGray dark:placeholder:!text-clrPlaceholder dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 w-full focus:outline-none"
                />
              </div>
              <div className="w-[25%]">
                <Filter
                  modalRef={modalRef}
                  options={[
                    { id: 1, value: t("tokenTb"), enum: t("tokenTb") },
                    { id: 2, value: t("tokenGb"), enum: t("tokenGb") },
                    { id: 3, value: t("tokenMb"), enum: t("tokenMb") },
                  ]}
                  toggleFilter={toggleOption}
                  selectedFilterOption={option}
                  // currentFilterOption={"DATA UNIT"}
                  currentFilterOption={t("filterDataUnit")}
                  selectFilterOption={setDataUnit}
                  selectedOption={dataUnit}
                  label={t("labelDataUnit")}
                />
              </div>
            </div>
          )}

          {tokenType === t("tokenTimed" )&& (
            <>
              <div className="space-y-6">
                <div className="flex items-center gap-8">
                  <label
                    htmlFor=""
                    className="block mr-3 font-semibold text-black dark:text-white text-x10 md:text-xs"
                  >
                    {t("labelValidity")}
                  </label>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    // onClick={() => {
                    //   setTokenValidity("days");
                    //   setValidityPeriod("");
                    // }}
                    onClick={() => handleValidity('days')}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "days"}
                    />
                    {tokenValidity === "days" && (
                      <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      {t("days")}
                    </label>
                  </div>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    // onClick={() => {
                    //   setTokenValidity("hours");
                    //   setValidityPeriod("");
                    // }}
                    onClick={() => handleValidity('hours')}

                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "hours"}
                    />
                    {tokenValidity === "hours" && (
                      <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      {t("hours")}
                    </label>
                  </div>
                </div>
                <div>
                  <Filter
                    modalRef={modalRef}
                    options={
                      tokenValidity === "days" ? renderDays() : renderHours()
                    }
                    toggleFilter={toggleOption}
                    selectedFilterOption={option}
                    currentFilterOption={t("filterValidity")}
                    selectFilterOption={setValidityPeriod}
                    selectedOption={formatSelectedPeriod(validityPeriod, tokenValidity)}
                    label={""}
                  />
                  <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
                    {t("descTokenDurationPrompt")}
                  </p>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="quantity"
                  className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                   {t("labelQuantity")}
                </label>

                <input
                  type="text"
                  name="quantity"
                  onChange={(e) => setTokenQuantity(e.target.value)}
                  placeholder={t("placeholderEnterTokenQuantity")}
                  className="text-clrGray dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder w-full focus:outline-none"
                />
              </div>
            </>
          )}
          <ErrorMsg errMsg={errorMsg} />
        </div>

        <div className="w-4/5 mx-auto">
          <Button
            text={t("buttonGenerate")}
            type="button"
            loading={isLoading}
            handleClick={handleGenerateTokens}
          />
        </div>
      </>
    </Modal>
  );
};


// Filter options
export const Filter: React.FC<DropdownContainerFilterProps> = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 font-semibold text-black dark:text-white text-x10 md:text-xs">
        {label}
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative w-full mt-[0.875rem] border-b-[1px] border-solid border-clrBorder5"
      >
        <p className="text-xs font-medium text-black dark:text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownContainer
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};

interface TokenDetailsProps {
  isOpen?: boolean;
  onClose: () => void;
  handleDeactivateToken: () => void;
  tokenData?: Token | null;
}

export const TokenDetails: React.FC<TokenDetailsProps> = ({
  isOpen,
  onClose,
  tokenData,
  handleDeactivateToken
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null); 
  useOutsideClick(modalRef, onClose);

  const formatValidityPeriod = (seconds: string) => {
    const duration = moment.duration(Number(seconds) || 0, 'seconds');
    if (Number(seconds) === 0) return "--";
    if (duration.days() > 31) return "Unlimited";
    
    let result = [];
    if (duration.days() > 0) {
      result.push(`${duration.days()} Day${duration.days() > 1 ? 's' : ''}`);
    }
    if (duration.hours() > 0) {
      result.push(`${duration.hours()} Hr${duration.hours() > 1 ? 's' : ''}`);
    }
    return result.join(' ');
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active': return '#00C087';
      case 'exhausted': return '#DB5F5F';
      case 'inactive': return '#CFA809';
      default: return '#000000';
    }
  };

  const details = [
    {
      label: t("token"),
      value: tokenData?.code
    },
    {
      label: t("assignedTo"),
      value: tokenData?.dataTokenOwner?.phoneNumber || tokenData?.dataTokenOwner?.email || 'Not Assigned'
    },
    {
      label: t("dataAllocated"),
      value: filesize(tokenData?.bytesAllocated || 0)
    },
    {
      label: t("dataUsed"),
      value: filesize(tokenData?.bytesUsed || 0)
    },
    {
      label: ("validityPeriod"),
      value: formatValidityPeriod(tokenData?.secondsAllocated || '0')
    },
    {
      label: t("usageType"),
      value: tokenData?.dataTokenUsageType
    },
    {
      label: t("status"),
      value: tokenData?.voucherStatus,
      customStyle: { color: getStatusColor(tokenData?.voucherStatus || '') }
    }
  ];

  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className={`fixed top-0 right-0 z-30 flex flex-col w-full h-screen py-10 px-[1.875rem] bg-white dark:bg-clrDarkBg md:w-96 slide-in-right`}>
        <div className="flex items-center gap-2 mb-6" >
          <button className="btn-reset" onClick={onClose}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="font-semibold text-[0.84rem] leading-[1.17rem] text-black dark:text-white">{t("tokenDetails")}</h3>
        </div>
        <div className="space-y-4">
            {details.map((detail, index) => (
              <div
                key={`${detail.label}-${index}`}
                className="flex justify-between items-center pt-3 pb-4 border-b-[0.5px] border-[#EAE9ED] dark:border-[#C4C4C480]"
              >
                <span className="text-[0.656rem] leading-[0.98rem] text-clrGray dark:text-clrPlaceholder font-medium">
                  {detail.label}
                </span>
                <span
                  className="text-[0.656rem] leading-[0.98rem] text-black dark:text-white font-semibold"
                  style={detail.customStyle}
                >
                  {detail.value}
                </span>
              </div>
            ))}
          </div>

        <div className="w-fit mt-auto flex justify-start">
            <Button
              type="button"
              text={t("deactiviateToken")}
              handleClick={handleDeactivateToken}
              fontWeight="font-normal"
              showIcon={true}
              px="md:!px-4"
              bgColor="bg-clrRed3"
              hover="hover:bg-clrRed3"
              textColor="!text-white"
              btnIcon={<CancelIcon className="h-[0.7rem] w-[0.7rem]" />}
              disabled={tokenData?.voucherStatus.toLowerCase() !== 'active'}
            />
          </div>

      </div>
       
    </div>
  );
};

// const TokenDetails2: React.FC<TokenDetailsProps> = ({
//   isOpen,
//   onClose,
//   tokenData
// }) => {
//   const {t} = useTranslation();
//   const [isEnterPin, setIsEnterPin] = useState(false);
//   const [isReasonToDeactiveOpen, setIsReasonToDeactiveOpen] = useState(false);
//   const modalRefEnterPin = useRef<HTMLElement>(null);
//   const [pin, setPin] = useState<string>("");
  
//   const handleCloseModal = () => {
//     setIsEnterPin(false);
//   };

//   useOutsideClick(modalRefEnterPin, handleCloseModal);

//   const handlePin = (res: string) => {
//     setPin(res);
//   };

//   const handleSendOTP = () => {
//     if (pin.length === 4) {
//       setIsEnterPin(false);
//       setIsReasonToDeactiveOpen(true);
//     }
//   };

//   const modalRef = useRef<HTMLDivElement>(null);

//   const handleDeactivateToken = () => {
//     setIsEnterPin(true);
//   };

//   const handleFinalClose = () => {
//     setIsReasonToDeactiveOpen(false);
//     onClose();
//   };

//   const formatValidityPeriod = (seconds: string) => {
//     const duration = moment.duration(Number(seconds) || 0, 'seconds');
//     if (Number(seconds) === 0) return "--";
//     if (duration.days() > 31) return "Unlimited";
    
//     let result = [];
//     if (duration.days() > 0) {
//       result.push(`${duration.days()} Day${duration.days() > 1 ? 's' : ''}`);
//     }
//     if (duration.hours() > 0) {
//       result.push(`${duration.hours()} Hr${duration.hours() > 1 ? 's' : ''}`);
//     }
//     return result.join(' ');
//   };

//   const getStatusColor = (status: string) => {
//     switch (status.toLowerCase()) {
//       case 'active': return '#00C087';
//       case 'exhausted': return '#DB5F5F';
//       case 'inactive': return '#CFA809';
//       default: return '#000000';
//     }
//   };

//   const details = [
//     {
//       label: t("token"),
//       value: tokenData?.code
//     },
//     {
//       label: t("assignedTo"),
//       value: tokenData?.dataTokenOwner?.phoneNumber || tokenData?.dataTokenOwner?.email || 'Not Assigned'
//     },
//     {
//       label: t("dataAllocated"),
//       value: filesize(tokenData?.bytesAllocated || 0)
//     },
//     {
//       label: t("dataUsed"),
//       value: filesize(tokenData?.bytesUsed || 0)
//     },
//     {
//       label: ("validityPeriod"),
//       value: formatValidityPeriod(tokenData?.secondsAllocated || '0')
//     },
//     {
//       label: t("usageType"),
//       value: tokenData?.dataTokenUsageType
//     },
//     {
//       label: t("status"),
//       value: tokenData?.voucherStatus,
//       customStyle: { color: getStatusColor(tokenData?.voucherStatus || '') }
//     }
//   ];

//   return (
//     <>
//       <div className={`fixed inset-0 -z-10 ${isOpen ? 'visible' : 'invisible'}`}>
//         {/* Backdrop */}
//         <div
//           className={`fixed inset-0 bg-black/30 transition-opacity duration-300 ${
//             isOpen ? 'opacity-100' : 'opacity-0'
//           } ${(isEnterPin || isReasonToDeactiveOpen) ? 'z-30' : 'z-40'}`}
//           onClick={!isEnterPin && !isReasonToDeactiveOpen ? onClose : undefined}
//         />
//         {/* Token Details Modal */}
//         <div
//           ref={modalRef}
//           className={`fixed top-0 right-0 z-20 h-screen w-full md:w-96 bg-white dark:bg-clrDarkBg shadow-lg
//             transform transition-all duration-300 ease-in-out overflow-y-auto py-10 px-[1.875rem] flex flex-col
//             ${isOpen ? 'translate-x-0' : 'translate-x-full'}
//             ${(isEnterPin || isReasonToDeactiveOpen) ? 'opacity-50' : 'opacity-100'}
//           `}
//         >
//           <div className="flex items-center gap-3 pb-6">
//             <button
//               onClick={onClose}
//               disabled={isEnterPin || isReasonToDeactiveOpen}
//               className="hover:bg-gray-100 dark:hover:bg-clrDarkBg2 p-1 rounded-full transition-colors disabled:opacity-50"
//             >
//               <ArrowBack className="h-5 w-5 dark-arrow" />
//             </button>
//             <h3 className="font-semibold text-[0.84rem] leading-[1.17rem] text-black dark:text-white">{t("tokenDetails")}</h3>
//           </div>
//           <div className="space-y-4">
//             {details.map((detail, index) => (
//               <div
//                 key={`${detail.label}-${index}`}
//                 className="flex justify-between items-center pt-3 pb-4 border-b-[0.5px] border-[#EAE9ED]"
//               >
//                 <span className="text-[0.656rem] leading-[0.98rem] text-clrGray dark:text-clrPlaceholder font-medium">
//                   {detail.label}
//                 </span>
//                 <span
//                   className="text-[0.656rem] leading-[0.98rem] text-black dark:text-white font-semibold"



//Get User Pin here
export const ReasonToDeactive2 = ({
    handleModal,
    handleContinue,
    errMsg,
    handleChange,
}: ConfirmModalProps) => {
  const {t} = useTranslation();
  const [reasonText, setreasonText] = useState("");
    return (
        <Modal
            close={true}
            title={t("reasonForDeactivation")}
            description={t("reasonForDeactivationDetails")}
            handleCloseModal={handleModal}
            goBack={false}
        >
            <>
                <div className="flex flex-col gap-2 w-full p-4 bg-white rounded dark:bg-clrDarkBg">
                   <p className="font-medium text-[0.656rem] leading-4 text-clrGray dark:text-clrPlaceholder">{t("reason")}</p>
                   <textarea 
                      name="" 
                      id="" 
                      value={reasonText}
                      onChange={(e) => {
                        const wordLimit = 250;
                        const words = e.target.value.split(/\s+/);
                        if (words.length <= wordLimit) {
                          setreasonText(e.target.value);
                        }
                      }}
                      className="rounded-xl bg-clrBg dark:bg-clrDarkBg2 h-36 w-full text-x10 p-2 text-clrGray dark:text-clrPlaceholder" ></textarea>
                   <p className="font-medium text-[0.656rem] leading-4 text-clrGray ">{reasonText.split(/\s+/).length || 0}{t("numberOfWords250")}</p>
                </div>
                <div className="md:w-[80%] mt-8 mx-auto ">
                    <p className="mb-2 font-medium text-center text-x10 text-clrRed">
                        {errMsg}
                    </p>
                    <Button text="Submit" type="button" handleClick={handleContinue} />
                </div>
            </>
        </Modal>
    );
};


export const ReasonToDeactive = ({
  handleModal,
  handleContinue,
  errMsg,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  const [reasonText, setReasonText] = useState("");
  const WORD_LIMIT = 250;

  const getWordCount = (text: string) => {
    return text.trim() === '' ? 0 : text.trim().split(/\s+/).length;
  };

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const wordCount = getWordCount(newText);
    
    if (wordCount <= WORD_LIMIT) {
      setReasonText(newText);
    }
  }, []);

  const wordsRemaining = WORD_LIMIT - getWordCount(reasonText);
  const isAtLimit = wordsRemaining <= 0;

  return (
    <Modal
      close={true}
      title={t("reasonForDeactivation")}
      description={t("reasonForDeactivationDetails")}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col gap-2 w-full p-4 bg-white rounded dark:bg-clrDarkBg">
          <div className="flex justify-between items-center">
            <p className="font-medium text-xs text-clrGray dark:text-clrPlaceholder">
              {t("reason")}
            </p>
            {/* <p className={`text-xs ${isAtLimit ? 'text-red-500' : 'text-clrGray dark:text-clrPlaceholder'}`}>
              {wordsRemaining} {t("wordsRemaining")}
            </p> */}
          </div>
        
          <textarea
            value={reasonText}
            onChange={handleTextChange}
            className={`rounded-xl bg-clrBg dark:bg-clrDarkBg2 h-36 w-full text-sm p-2
              text-clrGray dark:text-clrPlaceholder
              ${isAtLimit ? 'border-red-500' : 'border-transparent'}
              focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder={t("typeYourReasonHere")}
          />
        
          <div className="flex justify-between items-center">
            <p className="font-medium text-xs text-clrGray dark:text-clrPlaceholder">
              {getWordCount(reasonText)}{t("numberOfWords250")}
            </p>
            {isAtLimit && (
              <p className="text-xs text-red-500">
                {t("wordLimitReached")}
              </p>
            )}
          </div>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto">
          {errMsg && (
            <p className="mb-2 font-medium text-center text-sm text-red-500">
              {errMsg}
            </p>
          )}
          <Button
            text="Submit"
            type="button"
            handleClick={handleContinue}
            disabled={reasonText.trim() === ''}
          />
        </div>
      </>
    </Modal>
  );
};

export default ReasonToDeactive;
