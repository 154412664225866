import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";
import { useParams } from "react-router";

const DeviceName = () => {
  const {t} = useTranslation();
  //Get the tokenid from the route
  const { tokenId } = useParams();

  return (
    <>
      <SecondaryNav
        paths={[
          {
            path: `/home/explorer/devices-name/${tokenId}/statistics`,
            title: t("statistics"),
          },
          {
            path: `/home/explorer/devices-name/${tokenId}/transactions`,
            title: t("transactions"),
          },
        ]}
      />
    </>
  );
};

export default DeviceName;
