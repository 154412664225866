import React from 'react';

interface ErrProps {
  errMsg: string;
}

export const ErrorMsg = ({ errMsg }: ErrProps) => {
  return (
    <p data-testid="error-message" className="align-left text-[#F04438] text-left font-medium text-xs mt-2">
      {errMsg}
    </p>
  );
};
