import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Button } from "../reusables";
import CopyIcon from "../../assets/icons/copy.svg";
import ProfilePic from "../../assets/profile-pic.svg";
import Edit from "../../assets/edit.svg";
import { useNavigate } from "react-router";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";
import { useUpdateProfileMutation } from "../../features/api/profileSlice";
import { ReactComponent as EditIcon } from "../../assets/hub/edit.svg";
import { copyToClipboard, maskPhoneNumber, truncateText } from "../../Helper";
import { useDispatch } from "react-redux";
import { setUser } from "../../features/store/authReducerSlice";
import { DeleteAccount } from "./DeleteAccount";
import { useTranslation } from "react-i18next";
import { useLinkCodeQuery } from "../../features/api/homeSlice";
import { ScaleLoader } from "react-spinners";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ChooseModal } from "../reusables/ModalList";
import { useCancelSubscriptionMutation, useGetActivePackageQuery } from "../../features/api/subscriptionSlice";
import { ReactComponent as Delete2 } from "../../assets/delete2.svg";

const Profile = () => {
  const navigate = useNavigate();
  const { userId } = useAppSelector((state) => state.auth);
  const [profileUrl, setprofileUrl] = useState<string>("");
  const { name, emailAddress, referralCode } =
    useAppSelector((state) => state.auth);
  const {
    data: businessProfile,
    isError,
    isLoading,
  } = useGetBusinessProfileQuery();
  const {t} = useTranslation();
  const dispatch = useDispatch()
  const [copiedText, setCopied] = useState<string>("");
  const [errMsg, setErr] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [updateProfile] = useUpdateProfileMutation();

  // Cancel subscription
  const [isSubscriptionCancelled, toggleSubscription] = useState<boolean>(false);
  const modalRefSubscription = useRef<HTMLElement>(null);
  useOutsideClick(modalRefSubscription, () => toggleSubscription(!isSubscriptionCancelled));

   // About phone number
   const handleEditClick = () => {
    navigate("/home/profile/edit-contact")
  };

  const { data: activePackage } = useGetActivePackageQuery(userId);

    // my link code
    const { data: linkCode, isLoading: gettingLinkCode } = useLinkCodeQuery();

  const handleCopyClick = (code: string) => {
    copyToClipboard(code);
    setCopied(code);

    setTimeout(() => {
      setCopied('');
    }, 3000);
  };

  // Cancel subscription
  const [cancelSubscription, { isLoading: cancelingSubscription }] =
  useCancelSubscriptionMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErr("")
    const selectedFile = e.target.files?.[0];
    const maxSize = 256 * 1024; // 256 KB
    if (selectedFile && selectedFile.size <= maxSize) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setprofileUrl(blobUrl);
      handleProfileUpdate(selectedFile);
    }else{
      setErr(`*${t("maxSizeExceeded")}`)
    }
  };

  const handleProfileUpdate = (selectedFile: File) => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", "");
    bodyFormData.append("LastName", "");
    bodyFormData.append("PhoneNumber", "");
    bodyFormData.append("ProfileImage", selectedFile);
    updateProfile(bodyFormData)
    .unwrap()
    .then((res)=>{
      if(res){
        const existingUser = localStorage.getItem('user');
        const userObject = JSON.parse(existingUser || "");
        userObject.profileImageUrl = res.data.profileImageUrl;
        dispatch(setUser(userObject));
      }
    

    })
    .catch((err)=>{
      setErr(err?.data?.message || t("errorMessage"))
    })
  };

  const handleCancelSub = () => {
    setErr("");
    let subscriptionId = activePackage?.data?.subscriptionId || ""
    cancelSubscription(subscriptionId as string)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          toggleSubscription(false);
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.message ? err?.message : t("Something went wrong"));
      });
  };

  useEffect(()=>{
    setErr("")
  }, [])

  return (
    <section className="w-[96%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
      <div className="flex flex-wrap gap-10 px-4 py-4 bg-white dark:bg-clrDarkBg rounded-t-md md:rounded-md md:px-12 md:gap-24 md:flex-nowrap md:py-7 profile-bg ">
        <div className="flex flex-col items-center w-full md:w-fit md:pt-2 gap-2">
          <div className="relative">
            <img
              src={profileUrl? profileUrl : businessProfile?.data?.profileUrl ? businessProfile?.data?.profileUrl : ProfilePic}
              alt={t("profilePicture")}
              width={150}
              height={150}
              className="rounded-full w-36 pic-aspect"
            />
            <button className="absolute right-0 cursor-pointer bottom-6">
              <div className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-clrTextBlue dark:bg-clrDarkYellow">
                <img
                  src={Edit}
                  alt={t("editImageAlt")}
                  width={15}
                  height={15}
                />
                <input
                  type="file"
                  name="file"
                  id="idCard"
                  onChange={handleChange}
                  accept=".png, .jpeg, .jpg"
                  className="absolute overflow-hidden opacity-0 cursor-pointer z-index--1"
                />
              </div>
            </button>
          </div>
          <div>
            <p className="text-white text-xs mt-0"> {t("maxSize")}</p>
            <p className="text-xs text-red-500"> {errMsg}</p>
          </div>
          <div className="flex flex-col items-center gap-3 justify-center">
            {businessProfile?.data?.verificationStatus !== "VERIFIED" && (
              <div className="">
                <Button
                  type="button"
                  text={t("verifyAccount")}
                  handleClick={() => navigate("/home/profile/verifyID")}
                  textSize="text-x9"
                  py="py-2 !px-6"
                  fontWeight="font-normal"
                />
              </div>
            )}
            <div className="w-1/2 md:w-auto">
                <Button
                  type="button"
                  text={t("deleteAccount")}
                  handleClick={() => setDeleteModal(true)}
                  textSize="text-x9"
                  py="py-2 !px-6"
                  fontWeight="font-normal"
                  bgColor="bg-[#FFDCDC87] dark:bg-[#DB5F5F33]"
                  textColor="text-clrRed3"
                  hover="hover:bg-[#FFDCDC]"
                />
              </div>
            </div>
        </div>
        <div className="space-y-4">
          <div>
            <h3 className="mb-0.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("name").toUpperCase()}:
            </h3>
            <p className="text-sm font-medium text-black md:text-sm md:font-semibold dark:text-white">
              {name}
            </p>
          </div>
          <div>
            <h3 className="mb-0.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("email").toUpperCase()}:
            </h3>
            <p className="text-sm font-medium text-black md:text-sm md:font-semibold dark:text-white">
              {emailAddress}
            </p>
          </div>

          {!isLoading && !isError && (
            <>
              <div className='flex gap-4'>
                <div>
                  <h3 className="mb-0.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                    {t("contactNumber")}:
                  </h3>
                  <p className="text-sm font-medium text-black md:text-base md:font-semibold dark:text-white">
                    {maskPhoneNumber(businessProfile?.data?.phoneNumber || '')}
                  </p>
                </div>
                <button onClick={handleEditClick} className="flex items-end gap-2">
                  <EditIcon className="w-4 h-4" />
                  <p className="font-normal text-x10 text-primary dark:text-secondary">{t("edit")}</p>
                </button>
              </div>
              <div>
                <h3 className="mb-0.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                  {t("businessName").toUpperCase()}:
                </h3>
                <p className="text-sm font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {businessProfile?.data.name}
                </p>
              </div>
              <div>
                <h3 className="mb-0.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                  {t("businessAddress").toUpperCase()}:
                </h3>{" "}
                <p className="text-sm font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {businessProfile?.data.officeAddress}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {!isLoading && !isError && (
        <div className="flex flex-col gap-4 px-4 pb-4 bg-white dark:bg-clrDarkBg rounded-b-md md:rounded-md md:px-8 md:py-6 md:mt-2 md:gap-7 second-profile-bg">
          <div className="flex flex-wrap md:flex-nowrap gap-y-4 justify-between xl:justify-start xl:gap-x-[10.5rem] md:border-b-[0.0625rem] md:border-solid md:border-clrBorder dark:md:border-none pb-1">
            <div>
              <h3 className="mb-1 text-sm font-medium md:mb-2 text-clrGray dark:text-clrPlaceholder">
                {t("billingType")}:
              </h3>
              <div className='flex gap-2'>
                <p className="text-sm font-medium md:text-base text-black dark:text-white">
                  {businessProfile?.data.billingType === 1
                    ? `${t("normal")}`
                    : `${t("subscription")}`}
                </p>
                <span className="block mb-0.5 md:mb-1 bg-clrGreen2 bg-opacity-20 py-[2px] px-1.5 rounded-lg self-end">
                  <p className="font-normal text-clrGreen2 text-x8">{t("basic")}</p>
                </span>
              </div>
              <div className='mt-4 md:mt-7'>
                {activePackage === undefined ?
                  <Button
                    type="button"
                    text={t("subscribeNow")}
                    handleClick={() => navigate('/home/subscription')}
                    textSize="text-x9"
                    py="py-1"
                    fontWeight='font-normal'
                  />
                  : (
                    <Button
                      type="button"
                      text={t("cancelSubscription")}
                      handleClick={() => { toggleSubscription(true) }}
                      textSize="text-x9"
                      py="py-1"
                      bgColor='bg-clrRed3'
                      fontWeight='font-normal'
                      hover="hover:bg-[#db5f59]"
                    />
                    )
                }
              </div>
            </div>
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-sm md:mb-2 text-clrGray dark:text-clrPlaceholder">
                {t("referralCode").toUpperCase()}:
              </h3>
              <p className="text-base font-semibold md:text-xl md:font-extrabold text-clrTextBlue dark:text-white">
                {referralCode}
              </p>
            </div>
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-sm md:mb-2 text-clrGray dark:text-clrPlaceholder">
                {t("myLinkCode")}:
              </h3>
              <p className="relative flex gap-2">
                <span className="text-xs font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {gettingLinkCode
                    ? <ScaleLoader
                        loading={isLoading}
                        height={12}
                        width={2}
                        color={'#3d5170'}
                      />
                    : linkCode?.data?.linkCode}
                </span>
                <img
                  src={CopyIcon}
                  alt="Copy my link code"
                  width={13}
                  onClick={() => handleCopyClick(linkCode?.data?.linkCode || "")}
                  height={13}
                  className='cursor-pointer'
                />
                {linkCode?.data?.linkCode === copiedText && (
                  <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-primary text-x10 ">
                    {' '}
                    {t("copied")}
                  </p>
                )}
              </p>
            </div>
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-sm md:mb-2 text-clrGray dark:text-clrPlaceholder">
                {t("APIKey").toUpperCase()}:
              </h3>
              <p className="relative flex gap-2">
                <span className="text-xs font-medium text-black md:text-base dark:text-white">
                  {truncateText(businessProfile?.data.apiKey || "", 5, 6)}
                </span>
                <img
                  src={CopyIcon}
                  alt="Copy this API key"
                  width={13}
                  onClick={() => handleCopyClick(businessProfile?.data.apiKey as string || "")}
                  height={13}
                  className='cursor-pointer'
                />
                {businessProfile?.data.apiKey === copiedText && (
                  <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-clrYellow text-x10 ">
                    {' '}
                    {t("copied")}
                  </p>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {deleteModal && <DeleteAccount handleModal={()=> setDeleteModal(false)} />}
      {isSubscriptionCancelled && (
        <ChooseModal
          title={`Cancel Subscription`}
          description={`You are about to cancel this subscription from auto-renewal.`}
          handleButton={handleCancelSub}
          icon={<Delete2 className='w-16 h-16' />}
          handleModal={() => toggleSubscription(!isSubscriptionCancelled)}
          isLoading={cancelingSubscription}
          buttonTitle={t("cancel")}
          btnColor="bg-[#DB5F5F]"
          errMsg={errMsg}
          close={true}
        />
      )}
    </section>
  );
};

export default Profile;
