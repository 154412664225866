import { useAppSelector } from "../../../app/hooks";
import Crypto from "./Crypto";
import P2P from "./P2P";
import Swap from "./Swap";
import Transfer from "./Transfer";
import Stakes from "./Stakes";
import TransactionOption from "./TransactionOptions";

const TransactionHistory = () => {
  const { transactionTab } = useAppSelector((state) => state.wallet);

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="p-3 bg-white rounded-lg md:px-4 dark:bg-clrDarkBg md:py-8">
        <TransactionOption />
        <Crypto showFilter={true} show={transactionTab === 1} />
        <P2P showFilter={true} show={transactionTab === 2} />
        <Swap showFilter={true} show={transactionTab === 3} />
        <Transfer showFilter={true} show={transactionTab === 4} />
        <Stakes showFilter={true} show={transactionTab === 5} />
      </div>
    </section>
  );
};

export default TransactionHistory;
