import { useEffect, useRef, useState } from "react";
import { Button, ButtonOutlineWallet } from "../reusables";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
// import SterlingBankIcon from "../../assets/icons/wallet/sterling-logo.svg";
import { ConfirmModal } from "./P2PInfo";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { copyToClipboard } from "../../Helper";
import { useTranslation } from "react-i18next";
import { AccountInfoProps } from "./walletType";

const AccountInfo: React.FC<AccountInfoProps> = ({ handleSuccess, data }) => {
  const { t } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(30 * 60);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const ConfirmModalRef = useRef<HTMLElement>(null);
  const [copiedText, setCopied] = useState<string>("");

  const handleCloseModal = () => {
    setIsConfirmModal(false);
  };

  useOutsideClick(ConfirmModalRef, handleCloseModal);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <>
      <div className="px-8 py-5 bg-white rounded dark:bg-clrDarkBg">
        <h2 className="text-sm md:text-base font-semibold dark:font-medium text-[#212121] dark:text-white mb-2">
          {t("accountDetails")}
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("makeYourTransferToTheFollowingAccountDetails")}
        </p>
        <div className="mt-10 space-y-10">
          <div className="flex items-center justify-between">
            <div>
              <p className="mb-2 font-semibold text-x10 text-clrGray dark:font-medium dark:text-clrPlaceholder">
                {t("accountNumber")}
              </p>
              <p className="text-lg font-bold text-clrTextBlue dark:text-white">
                {
                  data?.data.providerPaymentMethods.orderPaymentMethod
                    .accountNumber
                }
              </p>
            </div>
            <div className="relative">
              <ButtonOutlineWallet
                text={t("copy")}
                type="button"
                showIcon={true}
                btnIcon={<CopyIcon />}
                imgHeight="15px"
                imgWidth="15px"
                handleClick={() => {
                  copyToClipboard(
                    data?.data.providerPaymentMethods.orderPaymentMethod
                      .accountNumber || ""
                  );
                  setCopied(
                    data?.data.providerPaymentMethods.orderPaymentMethod
                      .accountNumber || ""
                  );
                }}
              />
              {data?.data.providerPaymentMethods.orderPaymentMethod
                .accountNumber === copiedText && (
                <p className="absolute right-0 p-1 text-white border-solid rounded bg-clrYellow text-x10 ">
                  {" "}
                  {t("copied")}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            {/* <img
              src={SterlingBankIcon}
              alt="Bank Logo"
              width={50}
              height={50}
            /> */}
            <div>
              <p className="mb-1 font-semibold dark:font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                {t("bankName").toUpperCase()}
              </p>
              <p className="text-xs font-semibold text-black dark:text-white">
                {data?.data.providerPaymentMethods.orderPaymentMethod.bankName}
              </p>
            </div>
            <div>
              <p className="mb-1 font-semibold dark:font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                {t("accountName").toUpperCase()}
              </p>
              <p className="text-xs font-semibold text-black dark:text-white">
                {
                  data?.data.providerPaymentMethods.orderPaymentMethod
                    .accountName
                }
              </p>
            </div>
            <div>
              <p className="mb-1 font-semibold dark:font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                {t("accountNumber").toUpperCase()}
              </p>
              <p className="text-xs font-semibold text-black dark:text-white">
                {
                  data?.data.providerPaymentMethods.orderPaymentMethod.accountNumber
                }
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between px-14">
            <div>
              <p className="mb-3 text-xs font-medium text-[#a6a6a6] dark:text-clrPlaceholder">
                {t("whenYouSend")}
              </p>
              <p className="flex items-center gap-1">
                <span className="text-lg font-bold text-black dark:text-white">
                  {data?.data.payableAmount}
                </span>
                <span className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">
                  {data?.data.payInCurrencyCode}
                </span>
              </p>
            </div>
            <div>
              <p className="mb-3 text-xs font-medium text-[#a6a6a6] dark:text-clrPlaceholder">
                {t("youWillReceive")}
              </p>
              <p className="flex items-center gap-1">
                <span className="text-lg font-bold text-black dark:text-white">
                  {data?.data.receivableAmount}
                </span>
                <span className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">
                  {data?.data.receiveInCurrencyCode}
                </span>
              </p>
            </div>
          </div>
        </div>
        <p className="text-center text-[10px] mt-10 mb-8 font-normal text-clrGray ">
          {t("timeRemaining")}:{" "}
          <span className="font-semibold dark:font-medium dark:text-clrPlaceholder">
            {formatTime(remainingTime)}
          </span>
        </p>
        <div className="md:w-[50%] mt-4 mx-auto ">
          <Button
            text={t("iveMadeThisBankTransfer")}
            type="button"
            handleClick={() => setIsConfirmModal(true)}
          />
        </div>
      </div>
      <p className="text-[8px] font-normal text-clrGray text-center opacity-40 mt-4">
        {t("poweredBy")} Xend Bridge{" "}
      </p>
      {isConfirmModal && (
        <ConfirmModal
          handleModal={handleCloseModal}
          handleContinue={handleSuccess}
          orderId={data?.data.orderId}
          handleChange={function (val: string): void {
            throw new Error("Function not implemented.");
          }}
          errMsg={""}
          resendOtp={function (): void {
            throw new Error("Function not implemented.");
          }}
          loading={false}
        />
      )}
    </>
  );
};

export default AccountInfo;
