import { Button, ErrorMsg } from '../reusables';
import AuthCode from 'react-auth-code-input';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePasswordChangeMutation } from '../../features/api/authSlice';
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const {t} = useTranslation();
  const [isError, setError] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState('');

  const navigate = useNavigate();

  const [passwordChange, { isLoading }] = usePasswordChangeMutation();

  const handleSubmit = () => {
    if (currentPassword.length !== 4) {
      setError(t('validPassword'));
    } else {
      setError('');
      let data = {
        currentPassword,
        newPassword,
      };

      passwordChange(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/home/profile/info');
          } else {
            setError(res.message);
          }
        })
        .catch((err) => setError(err?.message));
    }
  };

  const handleChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setCurrentPassword(res);
  };

  const handleConfrimChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setNewPassword(res);
  };

  return (
    <section className="w-[96%] mx-auto md:pb-6 my-3 overflow-y-auto bg-white dark:bg-clrDarkBg mt-6 rounded-lg">
      <div className="flex items-center flex-col px-4 mt-5 md:mt-10 w-full mx-auto mb-12 md:mb-8 h-full">
        <div className="text-center">
          <h2 className="text-lg md:text-xl font-semibold text-black dark:text-white md:mb-[10px]">{t("createPIN")}</h2>
          <div className="mb-9">
            <p className="text-clrText dark:text-clrPlaceholder  font-medium text-xs lg:w-[82%] mx-auto md:text-sm">
              {t("createPINDetails")}
            </p>
          </div>
        </div>
        <div className="mb-4 text-center">
          <label
            htmlFor="#"
            className="block text-[0.625rem] leading-3 text-black dark:text-white  md:text-sm font-semibold mb-4"
          >
            {t("enterCurrentPIN")}
          </label>
          <div className="mb-3">
            <AuthCode
              inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary dark:text-secondary dark:border-secondary border-transparent dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 border-solid border rounded-md bg-primary/10"
              containerClassName="flex justify-center gap-3"
              ariaLabel="verify-otp"
              isPassword={true}
              autoFocus={true}
              length={4}
              allowedCharacters="numeric"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="text-center">
          <label
            htmlFor="#"
            className="block text-[0.625rem] leading-3 text-black dark:text-white  md:text-sm font-semibold mb-4"
          >
            {t("enterNewPIN")}
          </label>
          <div className="mb-4">
            <AuthCode
              inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary dark:text-secondary dark:border-secondary border-transparent border-solid dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 border rounded-md bg-primary/10"
              containerClassName="flex justify-center gap-3"
              ariaLabel="verify-otp"
              isPassword={true}
              autoFocus={false}
              length={4}
              allowedCharacters="numeric"
              onChange={handleConfrimChange}
            />
            <ErrorMsg errMsg={isError} />
          </div>
        </div>
        <div className="mt-6 lg:w-[20%] max-w-[19rem] mx-auto">
          <Button
            type="submit"
            loading={isLoading}
            handleClick={handleSubmit}
            text={t("setPIN")}
          />
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
