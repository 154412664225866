import React from "react";
import { Button } from "../reusables";
import ProficPhotoId from "../../assets/profile-photo-id.svg";
import IDCardIcon from "../../assets/id-card.svg";
import IDCardIconYellow from "../../assets/id-card-yellow.svg";
import { ReactComponent as Verify } from "../../assets/verifyID.svg";
import { ReactComponent as VerifyYellow } from "../../assets/verify-yellow.svg";
import ProfileIcon from "../../assets/profile-3.svg";
import Camera from "../../assets/camera.svg";
import PassportID from "../../assets/photo-id.svg";
import PassportIDYellow from "../../assets/photo-id-yellow.svg";
import TickIcon from "../../assets/tick-square.svg";
import { useTranslation } from "react-i18next";

export const VerifyPage: React.FC<{ handlePage: () => void }> = ({
  handlePage,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-4 px-4 pt-8 bg-white rounded-md dark:bg-clrDarkBg pb-7 md:p-12">
      <Verify
        width={90}
        height={90}
        className="block dark:hidden w-14 h-14 md:w-[7.375rem] md:h-[7.375rem]"
      />
      <VerifyYellow
        width={90}
        height={90}
        className="hidden dark:block w-14 h-14 md:w-[7.375rem] md:h-[7.375rem]"
      />
      <div className="text-center">
        <h2 className="mb-2 text-base font-semibold text-clrTextBlue dark:text-white">
          {t("verifyYourIdentity")}
        </h2>
        <p className="w-3/4 mx-auto font-normal text-center text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("verifyYourIdentityDetails")}:
        </p>
      </div>
      <ul className="flex flex-wrap items-center md:justify-center w-[70%] md:w-full mx-auto my-3 md:my-5 md:mb-14 md:flex-nowrap gap-x-12 gap-y-4">
        <li className="flex gap-2.5">
          <p className="items-center justify-center hidden w-4 h-4 mr-2 text-white rounded-full dark:text-clrTextBlue md:flex text-x8 bg-clrYellow dark:bg-white">
            {t("one")}
          </p>
          <img
            src={ProfileIcon}
            alt="Take a pic"
            width={20}
            height={20}
            className="self-start -mt-0.5"
          />
          <div className="md:w-[80%]">
            <p className="mb-1.5 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("takeSnapInstruction")}
            </p>
            <p className="font-normal text-x9 text-clrGray dark:text-clrPlaceholder">
              {t("takeSnapInstruction2")}
            </p>
          </div>
        </li>
        <li className="flex gap-2.5">
          <p className="items-center justify-center hidden w-4 h-4 mr-2 text-white rounded-full dark:text-clrTextBlue md:flex text-x8 bg-clrYellow dark:bg-white">
            {t("two")}
          </p>
          <img
            src={Camera}
            alt="Take a selfie"
            width={20}
            height={20}
            className="self-start -mt-0.5"
          />
          <div>
            <p className="mb-1.5 font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("takeSelfieInstruction")}
            </p>
            <p className="font-normal text-x9 text-clrGray dark:text-clrPlaceholder">
              {("takeSelfieInstruction2")}
            </p>
          </div>
        </li>
      </ul>
      <div className="bg-clrTextBlue bg-opacity-10 px-5 py-4 rounded-[0.25rem]">
        <p className="font-normal text-x9 text-clrGray">
          <span className="font-semibold">{("note")}:</span> {("verifySelfieInstruction")}
        </p>
      </div>
      <div className="flex items-center justify-center w-full mt-2 md:w-3/4 xl:w-1/2">
        <Button
          type="button"
          text={t("getStarted")}
          handleClick={handlePage}
          py="py-1.5"
          wBtn="w-3/5 md:w-1/2 xl:w-[30%]"
          fontWeight="font-medium"
        />
      </div>
    </div>
  );
};

export const ValidPhotoID: React.FC<{ handlePage: () => void }> = ({
  handlePage,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-4 px-4 pt-8 bg-white rounded-md dark:bg-clrDarkBg pb-7 md:p-7 md:pb-10">
      <img
        src={PassportID}
        alt="Profile"
        width={150}
        height={150}
        className="block dark:hidden w-32 h-32 md:w-[10.375rem] md:h-[10.375rem]"
      />
      <img
        src={PassportIDYellow}
        alt="Profile"
        width={150}
        height={150}
        className="hidden dark:block w-32 h-32 md:w-[10.375rem] md:h-[10.375rem]"
      />
      <div className="text-center">
        <h2 className="mb-2 text-base font-semibold text-clrTextBlue dark:text-white">
          {t("snapValidPhoto")}
        </h2>
        <p className="w-3/4 mx-auto font-normal text-center text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("checkID")}:{" "}
        </p>
      </div>
      <ul className="flex flex-wrap gap-y-4 md:flex-nowrap items-center justify-between w-[80%] my-3 md:my-5">
        <li className="flex w-full gap-3 md:w-fit">
          <img src={TickIcon} alt="Check" width={15} height={15} />
          <p className="font-medium md:font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
            {t("itExpired")}
          </p>
        </li>
        <li className="flex w-full gap-3 md:w-fit">
          <img src={TickIcon} alt="Check" width={15} height={15} />
          <p className="font-medium md:font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
            {t("itClear")}
          </p>
        </li>
        <li className="flex w-full gap-3 md:w-fit">
          <img src={TickIcon} alt="Check" width={15} height={15} />
          <p className="font-medium md:font-semibold text-x10 text-clrGray dark:text-clrPlaceholder">
            {("enterID")}
          </p>
        </li>
      </ul>
      <div className="bg-clrTextBlue dark:bg-white dark:bg-opacity-10 bg-opacity-10 px-5 py-4 rounded-[0.25rem]">
        <p className="font-normal text-x9 text-clrGray dark:text-clrPlaceholder">
          <span className="font-semibold">{("note")}:</span> {("verifySelfieInstruction")}
        </p>
      </div>
      <div className="flex items-center justify-center w-full mt-4 md:w-3/4 xl:w-1/2">
        <Button
          type="button"
          text={t("letBegin")}
          handleClick={handlePage}
          py="py-1.5"
          wBtn="w-3/5 md:w-1/2 xl:w-[30%]"
          fontWeight="font-medium"
        />
      </div>
    </div>
  );
};

export const VerifyPhotoID: React.FC<{ handlePage: () => void }> = ({
  handlePage,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-4 px-4 pt-8 bg-white rounded-md dark:bg-clrDarkBg pb-7 md:p-7 md:py-10">
      <img
        src={ProficPhotoId}
        alt="Profile"
        width={250}
        height={250}
        className="w-full h-[11rem]"
      />
      <div className="w-4/5 text-center md:w-[18rem] pt-5">
        <h2 className="mb-2 text-base font-semibold text-clrTextBlue dark:text-white">
         {t("snapValidPhoto")}
        </h2>
        <p className="mx-auto font-normal text-center text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("snapValidPhotoDetails")}{" "}
        </p>
      </div>
      <p className="w-[70%] font-normal text-center md:w-full text-x10 text-clrGray dark:text-clrPlaceholder">
        {("phrase")}:
        <span className="font-semibold">
          {" "}
         {t("exampleOfPhrase")}
        </span>
      </p>
      <div className="bg-clrTextBlue dark:bg-white dark:bg-opacity-10 bg-opacity-10 px-5 py-4 rounded-[0.25rem]">
        <p className="font-normal text-x9 text-clrGray dark:text-clrPlaceholder">
          <span className="font-semibold">{("note")}:</span> {("verifySelfieInstruction")}
        </p>
      </div>
      <div className="flex items-center justify-center w-full mt-2 md:w-3/4 xl:w-1/2">
        <Button
          type="button"
          text="Let's Begin"
          handleClick={handlePage}
          py="py-1.5"
          wBtn="w-3/5 md:w-1/2 xl:w-[30%]"
          fontWeight="font-medium"
        />
      </div>
    </div>
  );
};

export const VerifyOnGoing: React.FC<{ handlePage: () => void }> = ({
  handlePage,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center px-4 pt-6 bg-white dark:bg-clrDarkBg rounded-md md:pt-12 gap-7 pb-7 md:pb-10 md:p-7">
      <img
        src={IDCardIcon}
        alt="ID card"
        width={130}
        height={130}
        className="block dark:hidden w-24 md:w-32"
      />
      <img
        src={IDCardIconYellow}
        alt="ID card"
        width={130}
        height={130}
        className="hidden dark:block w-24 md:w-32"
      />
      <div className="text-center">
        <h2 className="mb-2 text-base font-semibold text-clrTextBlue dark:text-white">
          {("We're verifying your account information")}
        </h2>
        <p className="mx-auto font-normal text-center md:w-1/2 text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("verifyOngoingDescription")}{" "}
        </p>
      </div>
      <div className="hidden dark:flex items-center justify-center w-full md:w-3/4 xl:w-1/2">
        <Button
          type="button"
          text="Go back to dashboard"
          handleClick={handlePage}
          py="py-1.5"
          fontWeight="font-medium"
          wBtn="w-3/5 md:w-1/2 xl:w-[30%]"
        />
      </div>
    </div>
  );
};
