// // import React, { useState } from "react";
// // import { ErrorMsg, InputBox, Card, ButtonAuth } from "../../reusables";
// // import { Link } from "react-router-dom";
// // import GoogleAuth from "../googleAuth/GoogleAuth";
// // import AuthFrame from "../../reusables/AuthFrame";
// // import { validateEmail } from "../../../Helper";
// // import { useGenerateEmailOTPMutation } from "../../../features/api/authSlice";

// // type SignupDetailProps = {
// //   email: string;
// //   fname: string;
// //   lname: string;
// //   otp: string;
// //   pin: string;
// // };

// // //Component to check if uer already exists on our system
// // const SignUp = ({
// //   handleNext,
// //   form,
// //   setForm,
// // }: {
// //   form: SignupDetailProps;
// //   setForm: any;
// //   handleNext: (step: number) => void;
// // }) => {
// //   const [authenticating, setAuthenticating] = useState<boolean>(false);
// //   const [isTerms, setTerms] = useState<boolean>(false);
// //   const [isError, setError] = useState<string>("");

// //   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
// //     if (isError) {
// //       setError("");
// //     }
// //     const value = e.target.value;
// //     setForm({ ...form, [e.target.id]: value });
// //   };
// //   const [generateEmailOTP, { isLoading }] = useGenerateEmailOTPMutation();

// //   //function to handle signup authentication
// //   const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
// //     e.preventDefault();
// //     if (!validateEmail(form.email)) {
// //       setError("Please enter a valid email");
// //     } else if (!form.fname) {
// //       setError("Please enter your first name");
// //     } else if (!form.lname) {
// //       setError("Please enter your last name");
// //     } else if (!isTerms) {
// //       setError("Please accept the terms and conditions to proceed");
// //     } else {
// //       setError("");
// //       generateEmailOTP({ emailAddress: form.email })
// //         .unwrap()
// //         .then((res) => {
// //           if (res) {
// //             if (res?.data.didOtpEmailSend) {
// //               handleNext(1);
// //             } else if (res?.data.doesUserExist) {
// //               setError("User already exists");
// //             } else {
// //               setError(res?.message);
// //             }
// //           }
// //         })
// //         .catch((error) => setError(error.data.message));
// //     }
// //   };

// //   return (
// //     <div className="flex">
// //       <Card>
// //         <div className="flex flex-col min-[426px]:w-[350px] mx-auto mb-12 md:mb-8 h-full">
// //           <div className="mb-6 text-center md:text-left">
// //             <h1 className="font-semibold text-black text-lg md:text-3xl md:leading-[54px] mb-5">
// //               Create Account
// //             </h1>
// //             <p className="text-para">
// //               Sign up now to take advantage of the fantastic services we
// //               provide.
// //             </p>
// //           </div>
// //           <GoogleAuth
// //             title="signup_with"
// //             setError={setError}
// //             setAuthenticating={setAuthenticating}
// //           />
// //           <div className="text-center">
// //             <p className="my-3 text-para">or</p>
// //           </div>
// //           <form className="">
// //             <div className="mb-4">
// //               <InputBox
// //                 title="Email address"
// //                 name="email"
// //                 type="email"
// //                 id="email"
// //                 value={form.email}
// //                 handleChange={handleChange}
// //                 placeholder="Youremail@gmail.com"
// //               />
// //             </div>
// //             <div className="mb-4">
// //               <InputBox
// //                 title="First Name"
// //                 name="fname"
// //                 type="text"
// //                 id="fname"
// //                 value={form.fname}
// //                 handleChange={handleChange}
// //                 placeholder="John"
// //               />
// //             </div>

// //             <div className="">
// //               <InputBox
// //                 title="Last Name"
// //                 name="lname"
// //                 type="text"
// //                 id="lname"
// //                 value={form.lname}
// //                 handleChange={handleChange}
// //                 placeholder="Stephen"
// //               />
// //             </div>

// //             <div
// //               className="mt-2 mb-4 md:mb-6"
// //               onClick={() => {
// //                 setTerms(!isTerms);
// //                 setError("");
// //               }}
// //             >
// //               <div className="material-checkbox flex !justify-start">
// //                 <input
// //                   type="checkbox"
// //                   name="terms"
// //                   checked={isTerms}
// //                   id="terms"
// //                   className="mr-1"
// //                 />
// //                 <div className="!ml-0 checkmark"></div>
// //                 <label
// //                   htmlFor="terms"
// //                   className="text-[12px] leading-4 font-normal md:font-medium md:text-sm md:leading-[22px] text-gray-500 italic text-right"
// //                 >
// //                   I agree to the{" "}
// //                   <a
// //                     href="https://wicrypt.com/terms-condition.html"
// //                     target="_blank"
// //                     rel="noopener noreferrer"
// //                     className="text-clrYellow"
// //                   >
// //                     Terms and Conditions
// //                   </a>
// //                 </label>
// //               </div>
// //               <ErrorMsg errMsg={isError} />
// //             </div>

// //             <ButtonAuth
// //               type="submit"
// //               loading={isLoading || authenticating}
// //               handleClick={handleSubmit}
// //               text="Get Started"
// //             />
// //           </form>
// //           <div>
// //             <p className="text-[12px] leading-4 font-normal md:font-medium md:text-[0.9rem] md:leading-[22px] text-[#333333] mt-4">
// //               Already have an account?{" "}
// //               <Link to="/" className="text-clrYellow">
// //                 Login here
// //               </Link>
// //             </p>
// //           </div>
// //         </div>
// //       </Card>
// //       <AuthFrame />
// //     </div>
// //   );
// // };
// // export default SignUp;

// import React, { useState } from "react";
// import { ErrorMsg, InputBox, Card, ButtonAuth } from "../../reusables";
// import { Link } from "react-router-dom";
// import GoogleAuth from "../googleAuth/GoogleAuth";
// import AuthFrame from "../../reusables/AuthFrame";
// import { useGenerateEmailOTPMutation } from "../../../features/api/authSlice";
// import { validateEmail } from "../../../Helper";

// type SignupDetailProps = {
//   email: string;
//   fname: string;
//   lname: string;
//   otp: string;
//   pin: string;
//   phone: string;
// };

// //Component to check if uer already exists on our system
// const SignUp = ({
//   handleNext,
//   form,
//   setForm,
// }: {
//   form: SignupDetailProps;
//   setForm: any;
//   handleNext: (step: number) => void;
// }) => {
//   const [authenticating, setAuthenticating] = useState<boolean>(false);
//   const [isTerms, setTerms] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (isError) {
//       setError("");
//     }
//     const value = e.target.value;
//     setForm({ ...form, [e.target.id]: value });
//   };
//   const [generateEmailOTP, { isLoading }] = useGenerateEmailOTPMutation();

//   //function to handle signup authentication
//   const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
//     e.preventDefault();
//     if (!validateEmail(form.email)) {
//       setError("Please enter a valid email");
//     } else if (!form.phone  && form.phone.length < 10) {
//       setError("Please enter a valid phone number");
//     } else if (!form.fname) {
//       setError("Please enter your first name");
//     } else if (!form.lname) {
//       setError("Please enter your last name");
//     } else if (!isTerms) {
//       setError("Please accept the terms and conditions to proceed");
//     } else {
//       setError("");
//       generateEmailOTP(form.email)
//         .unwrap()
//         .then((res) => {
//           if (res.success) {
//             handleNext(1);
//           } else {
//             setError(res?.message);
//           }
//         })
//         .catch((err)=>{
//           setError(err?.data?.message || "Something went wrong")
//         })
//     }
//   };

//   return (
//     <div className="flex">
//       <Card>
//         <div className="flex flex-col min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
//           <div className="mb-6 md:text-left">
//             <h1 className="font-semibold text-black text-lg md:text-3xl md:leading-[54px] mb-5">
//               Create Account
//             </h1>
//             <p className="text-para">
//               Sign up now to take advantage of the fantastic services we
//               provide.
//             </p>
//           </div>
//           <GoogleAuth
//             title="signup_with"
//             setError={setError}
//             setAuthenticating={setAuthenticating}
//           />
//           <div className="text-center">
//             <p className="my-3 text-para">or</p>
//           </div>
//           <form className="">
//             <div className="mb-4">
//               <InputBox
//                 title="Email address"
//                 name="email"
//                 type="email"
//                 id="email"
//                 value={form.email}
//                 handleChange={handleChange}
//                 placeholder="Youremail@gmail.com"
//               />
//             </div>
//             <div className="mb-4">
//               <InputBox
//                 title="Phone"
//                 name="phone"
//                 type="tel"
//                 id="phone"
//                 value={form.phone}
//                 handleChange={handleChange}
//                 placeholder="08030000000"
//               />
//             </div>
//             <div className="mb-4">
//               <InputBox
//                 title="First Name"
//                 name="fname"
//                 type="text"
//                 id="fname"
//                 value={form.fname}
//                 handleChange={handleChange}
//                 placeholder="John"
//               />
//             </div>

//             <div className="">
//               <InputBox
//                 title="Last Name"
//                 name="lname"
//                 type="text"
//                 id="lname"
//                 value={form.lname}
//                 handleChange={handleChange}
//                 placeholder="Stephen"
//               />
//             </div>
//             <ErrorMsg errMsg={isError} />
//             <div
//               className="mt-2 mb-4 md:mb-6"
//               onClick={() => {
//                 setTerms(!isTerms);
//                 setError("");
//               }}
//             >
//               <div className="material-checkbox flex !justify-start">
//                 <input
//                   type="checkbox"
//                   name="terms"
//                   checked={isTerms}
//                   id="terms"
//                   className="mr-1"
//                 />
//                 <div className="!ml-0 checkmark"></div>
//                 <label
//                   htmlFor="terms"
//                   className="text-[12px] leading-4 font-normal md:font-medium md:text-sm md:leading-[22px] text-gray-500 text-right"
//                 >
//                   I agree to the{" "}
//                   <a
//                     href="https://wicrypt.com/terms-condition.html"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="text-primary"
//                   >
//                     Terms and Conditions
//                   </a>
//                 </label>
//               </div>
              
//             </div>

//             <ButtonAuth
//               type="submit"
//               loading={isLoading || authenticating}
//               handleClick={handleSubmit}
//               text="Sign Up"
//             />
//           </form>
//           <div>
//             <p className="text-[12px] leading-4 font-normal md:font-medium md:text-[0.9rem] md:leading-[22px] text-[#333333] mt-4">
//               Already have an account? {" "}
//               <Link to="/" className="text-primary">
//                 Login
//               </Link>
//             </p>
//           </div>
//         </div>
//       </Card>
//       <AuthFrame />
//     </div>
//   );
// };
// export default SignUp;

import React, { useState } from "react";
import { ErrorMsg, InputBox, Card, ButtonAuth } from "../../reusables";
import { Link } from "react-router-dom";
import GoogleAuth from "../googleAuth/GoogleAuth";
import AuthFrame from "../../reusables/AuthFrame";
import { useGenerateEmailOTPMutation } from "../../../features/api/authSlice";
import { validateEmail } from "../../../Helper";

type SignupDetailProps = {
  email: string;
  fname: string;
  lname: string;
  otp: string;
  pin: string;
  phone: string;
};

//Component to check if uer already exists on our system
const SignUp = ({
  handleNext,
  form,
  setForm,
}: {
  form: SignupDetailProps;
  setForm: any;
  handleNext: (step: number) => void;
}) => {
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [isTerms, setTerms] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setError("");
    }
    const value = e.target.value;
    setForm({ ...form, [e.target.id]: value });
  };
  const [generateEmailOTP, { isLoading }] = useGenerateEmailOTPMutation();

  //function to handle signup authentication
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setError("Please enter a valid email");
    } else if (!form.phone  && form.phone.length < 10) {
      setError("Please enter a valid phone number");
    } else if (!form.fname) {
      setError("Please enter your first name");
    } else if (!form.lname) {
      setError("Please enter your last name");
    } else if (!isTerms) {
      setError("Please accept the terms and conditions to proceed");
    } else {
      setError("");
      generateEmailOTP(form.email)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleNext(1);
          } else {
            setError(res?.message);
          }
        })
        .catch((err)=>{
          setError(err?.data?.message || "Something went wrong")
        })
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex flex-col min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="mb-6 md:text-left">
            <h1 className="font-semibold text-black text-lg md:text-3xl md:leading-[54px] mb-5">
              Create Account
            </h1>
            <p className="text-para">
              Sign up now to take advantage of the fantastic services we
              provide.
            </p>
          </div>
          <GoogleAuth
            title="signup_with"
            setError={setError}
            setAuthenticating={setAuthenticating}
          />
          <div className="text-center">
            <p className="my-3 text-para">or</p>
          </div>
          <form className="">
            <div className="mb-4">
              <InputBox
                title="Email address"
                name="email"
                type="email"
                id="email"
                value={form.email}
                handleChange={handleChange}
                placeholder="Youremail@gmail.com"
              />
            </div>
            <div className="mb-4">
              <InputBox
                title="Phone"
                name="phone"
                type="tel"
                id="phone"
                value={form.phone}
                handleChange={handleChange}
                placeholder="08030000000"
              />
            </div>
            <div className="mb-4">
              <InputBox
                title="First Name"
                name="fname"
                type="text"
                id="fname"
                value={form.fname}
                handleChange={handleChange}
                placeholder="John"
              />
            </div>

            <div className="">
              <InputBox
                title="Last Name"
                name="lname"
                type="text"
                id="lname"
                value={form.lname}
                handleChange={handleChange}
                placeholder="Stephen"
              />
            </div>
            <ErrorMsg errMsg={isError} />
            <div
              className="mt-2 mb-4 md:mb-6"
              onClick={() => {
                setTerms(!isTerms);
                setError("");
              }}
            >
              <div className="material-checkbox flex !justify-start">
                <input
                  type="checkbox"
                  name="terms"
                  checked={isTerms}
                  id="terms"
                  className="mr-1"
                />
                <div className="!ml-0 checkmark"></div>
                <label
                  htmlFor="terms"
                  className="text-[12px] leading-4 font-normal md:font-medium md:text-sm md:leading-[22px] text-gray-500 text-right"
                >
                  I agree to the{" "}
                  <a
                    href="https://wicrypt.com/terms-condition.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
              
            </div>

            <ButtonAuth
              type="submit"
              loading={isLoading || authenticating}
              handleClick={handleSubmit}
              text="Sign Up"
            />
          </form>
          <div>
            <p className="text-[12px] leading-4 font-normal md:font-medium md:text-[0.9rem] md:leading-[22px] text-[#333333] mt-4">
              Already have an account? {" "}
              <Link to="/" className="text-primary">
                Login
              </Link>
            </p>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};
export default SignUp;
