import { useState } from "react";
import {
  useTransferToBeneficiaryMutation,
  useLazySendTransferOTPQuery,
} from "../../features/api/walletSlice";
import { WithdrawEnterPin, WithdrawSuccess, WithdrawTransfer, WithdrawVerifyEmail } from "./WithdrawInfo";
import { Currency } from "../../features/api/walletSliceTypes";


const TransferFlow = ({  step, setStep }: TransferFlowProps) => {

  const [amt, setTransferAmt] = useState<number>(0);
  const [pin, setPin] = useState<string>("");
  const [errMsg, setError] = useState<string>("");
  const [recipientId, setRecipientId] = useState<string>("");
  const [transferCurrency, setTransferCurrency] = useState<Currency>({
    id: 0,
    code: "",
    name: "",
    iconPng: "",
    iconSvg: ""
  });

  const [otp, setOtp] = useState<string>("");

  //Send OTP to the user for the transfer request 
  const [trigger] = useLazySendTransferOTPQuery();

  //Initiate transfer for the user 
  const [transferToUser, { data: transferResponse, isLoading }] = useTransferToBeneficiaryMutation();

  // function to close all modals
  const handleCloseModal = () => {
    setStep(0);
  };

  //Save transfer amount and currency for the transfer 
  const handleVerifyEmail = (currency: Currency, amount: number, recipientId: string) => {
    setTransferAmt(amount);
    setTransferCurrency(currency);
    setRecipientId(recipientId);
    setStep(2);
  }

  //Send Transfer OTP to the user
  const handleSendOTP = () => {
    setStep(3);
    trigger(transferCurrency?.id.toString());
  };

  //Set the User's pin
  const handlePin = (res: string) => {
    setPin(res);
  };

  //Save the user's OTP
  const handleOtp = (res: string) => {
    setOtp(res);
  };

  //Transfer to another user
  const handleWithdrawalByTransfer = () => {
    setError("");
    let data = {
      payload: {
        amount: amt,
        pin: pin,
        otp: otp,
      },
      currencyId: transferCurrency?.id,
      recipientId: recipientId,
    }
    transferToUser(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(4);
        } else {
          setError(res?.message)
        }

      })
      .catch((err) => { setError(err.data.message) })
  }


  const renderTransferOption = () => {
    switch (step) {

      case 1:
        return (
          <WithdrawTransfer
            handleCloseModal={handleCloseModal}
            handleModal={() => setStep(0)}
            handleContinue={handleVerifyEmail}
          />
        )
      case 2:
        return (
          <WithdrawEnterPin
            handleModal={handleCloseModal}
            handleContinue={handleSendOTP}
            handleChange={handlePin}
            errMsg={""}
            resendOtp={() => console.log("resend OTP")}
            loading={false}
          />
        )
      case 3:
        return (
          <WithdrawVerifyEmail
            handleModal={handleCloseModal}
            resendOtp={() => trigger(transferCurrency?.code)}
            handleContinue={handleWithdrawalByTransfer}
            handleChange={handleOtp}
            errMsg={errMsg}
            loading={isLoading}
          />
        )
      case 4:
        return (
          <WithdrawSuccess
            handleModal={handleCloseModal}
            data={transferResponse?.data}
          />
        )
      default:
        return
    }
  }

  return (
    <>
      {renderTransferOption()}
    </>
  );
};

export default TransferFlow;
