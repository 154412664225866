import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import TableMain from "../reusables/ReactDataTable";
import { useBooster } from "./data";
import { useGetRewardHistoryQuery } from "../../features/api/rewardSlice";

import { EmptyCard, TableLoader } from "../reusables";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const RewardHistory = () => {
  const {t} = useTranslation();
  const {rewardHistoryColumn} = useBooster();
  const { userId } = useAppSelector((state) => state.auth);
  const { cycleId } = useParams();
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetRewardHistoryQuery({
    userId: userId,
    cycleId: cycleId as string,
    Page: page,
    PageSize: 30,
  });

  return (
    <section className="w-[96%] mx-auto mb-6 overflow-y-auto bg-white dark:bg-clrDarkBg mt-2">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-10">
        <div>
          {isLoading ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data.records || []}
              columns={rewardHistoryColumn}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage={data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading &&
          !isFetching &&
          (data?.data.records.length ?? 0) === 0 && (
            <EmptyCard description={t("emptyBoosterTable")} />
          )}
      </div>
    </section>
  );
};

export default RewardHistory;
