import { useTranslation } from "react-i18next";
import { Button, ErrorMsg } from "../reusables";
import { numberWithCommas } from "../../Helper";
import { useGetTransactionChargeQuery } from "../../features/api/walletSlice";
import { ConfirmProps } from "./walletType";


const Confirm = ({
  handleSubmit,
  outBoundValue,
  inBoundValue,
  selectedBank,
  isLoading,
  inboundCurrency,
  outboundCurrency,
  type,
  errMsg,
}: ConfirmProps) => {
  const { t } = useTranslation();
  // const { selectedCurrency: inboundCurrency, outboundCurrency } =
  //   useAppSelector((state) => state.wallet);
  const { data: transactionCharge, isLoading: gettingCharge } =
    useGetTransactionChargeQuery({
      transactionCategory: type === "FUND" ? "Funding" : "Withdrawal",
      currencyId: outboundCurrency.id,
      channel: "XendBridge",
      amount: outBoundValue,
    });

  return (
    <>
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-8 md:py-5">
        <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-1.5 md:mb-3">
          {t("confirm")}
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("confirmYourP2P")} {type === "FUND" ? t("funding") : t("withdrawal")}
        </p>
        <div className="flex flex-col items-center justify-center gap-4 my-5">
          <p className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder opacity-60 dark:opacity-100">
            {t("whenYou")} {type === "FUND" ? t("send") : t("withdraw")}
          </p>
          <p>
            <span className="text-4xl font-bold text-black md:text-6xl dark:text-white">
              {outBoundValue && numberWithCommas(outBoundValue)}
            </span>
            <span className="font-normal text-x10 text-clrGray">
              {outboundCurrency.code}
            </span>
          </p>
          <p className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder opacity-60 dark:opacity-100">
            {t("youWillReceive")}
          </p>
          <p>
            <span className="text-4xl font-bold text-black dark:text-white md:text-6xl">
              {inBoundValue && numberWithCommas(inBoundValue)}
            </span>
            <span className="font-normal text-x10 text-clrGray">
              {inboundCurrency.code}
            </span>
          </p>
          <p className="font-semibold text-x8 text-clrGray dark:text-clrPlaceholder opacity-60 dark:opacity-100">
            Transaction fee:{" "}
            <span className="text-[#E5B910]">
              {gettingCharge
                ? "..."
                : transactionCharge?.data.transactionCharge}{" "}
              {outboundCurrency.code}
            </span>
          </p>
          <p className="font-normal text-x10 text-clrGray66 dark:text-clrPlaceholder">
            {t("payingWithTheFollowingBankDetails")}:
          </p>
        </div>
        <div className="flex items-center justify-center gap-2">
          <img src={selectedBank?.iconUrlSvg} alt="Bank logo" width={20} height={20} />
          <div>
            <p className="mb-1 font-semibold text-black md:text-xs text-x10 dark:text-white dark:font-medium ">
              {selectedBank?.bankName} (...
              {selectedBank?.accountNumber.slice(-5)})
            </p>
            <p className="text-[10px] font-normal text-clrGray dark:text-clrPlaceholder">
              {selectedBank?.name}{" "}
            </p>
          </div>
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="md:w-[50%] mt-8 mx-auto ">
          <Button
            loading={isLoading}
            text={t("confirm")}
            type="button"
            disabled={isLoading}
            handleClick={handleSubmit}
          />
        </div>
      </div>
      <p className="text-[8px] font-normal text-clrGray text-center opacity-40 mt-4">
        {t("poweredBy")} Xend Bridge{" "}
      </p>
    </>
  );
};

export default Confirm;
