import { useState } from "react";
import AuthCode from "react-auth-code-input";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { Button, ErrorMsg } from "../reusables";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import CloseIcon from "../../assets/icons/hotspot-hubs/close.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/wallet/success.svg";
import TickSquare from "../../assets/icons/wallet/tick-square.svg";
import { numberWithCommas } from "../../Helper";
import { useLazyGetTransferBeneficiaryQuery } from "../../features/api/walletSlice";
import { Modal } from "../reusables/Modal";

//Withdraw by transferring to another user
export const WithdrawTransfer: React.FC<WithdrawalTransferProps> = ({
  handleModal,
  handleCloseModal,
  handleContinue,
}) => {
  const { t } = useTranslation();
  const [recipientEmail, setRecipientEmail] = useState("");

  const [transferAmt, setTransferAmt] = useState("");
  const { selectedCurrency } = useAppSelector((state) => state.wallet);
  const [trigger, { isLoading }] = useLazyGetTransferBeneficiaryQuery();

  const [isError, setError] = useState("");

  const handleSubmit = () => {
    if (Number(transferAmt) === 0) {
      setError(t("pleaseEnterAValidAmount"));
    } else if (!recipientEmail) {
      setError(t("pleaseEnterAValidEmail"));
    } else {
      setError("");
      trigger(recipientEmail)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleContinue(selectedCurrency, Number(transferAmt), res?.data.id);
          } else {
            setError(res?.message);
          }
        })
        .catch((err) => setError(err?.data.message));
    }
  };

  return (
    <Modal
      close={true}
      title={t("sendToAUser")}
      description={`${t("sendToAUserDescription", { currency: selectedCurrency.code })}`}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <>
        <div className="space-y-4">
          <button className="flex items-center justify-between w-full p-3 bg-white rounded dark:bg-clrDarkBg">
            <div>
              <h3 className="mb-2 font-semibold text-x10 md:text-xs text-clrTextBlue dark:text-white">
                {t("chooseCurrency")}
              </h3>
              <p className="text-xs font-medium text-start text-clrGray dark:text-clrPlaceholder">
                {selectedCurrency.code}
              </p>
            </div>
            <img src={ArrowDown} alt="more options" width={20} height={20} />
          </button>
          <div className="flex flex-col items-center justify-center gap-4 py-4 bg-white dark:bg-clrDarkBg">
            <p className="text-x10 font-medium text-[#A6A6A6] dark:text-white">
              {t("amountToTransfer")}
            </p>
            <div className="flex justify-center w-full">
              <input
                className="flex-initial text-4xl font-extrabold text-right text-black dark:bg-inherit dark:text-white dark:placeholder:!text-white outline-none dark:border-clrGray md:text-5xl w-56"
                placeholder="0"
                value={transferAmt}
                onChange={(e) => setTransferAmt(e.target.value)}
              />
              <div className="text-x10 font-medium text-[#A6A6A6] flex-initial w-40 dark:text-clrPlaceholder">
                <p className="mt-11">{selectedCurrency.code}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full p-4 bg-white rounded dark:bg-clrDarkBg">
            <label className="mb-2 font-semibold text-x10 md:text-xs text-clrTextBlue dark:text-white">
              {t("emailAddress")}
            </label>
            <input
              type="text"
              name="recepientEmail"
              id="recepientEmail"
              placeholder={t("emailPlaceholder")}
              onChange={(e) => setRecipientEmail(e.target.value)}
              className="border-b-[1px] border-solid border-[#D2CBCB] dark:text-clrPlaceholder dark:placeholder:!text-clrPlaceholder pb-1.5 dark:border-clrGray placeholder:text-clrPlaceholder
               placeholder:text-x10 focus:outline-none text-x10 opacity-80 outline-none dark:bg-inherit"
            />
            <p className="mt-1.5 text-x8 font-medium text-[#707070] dark:text-clrPlaceholder">
              {t("emailAddressOfTheRecipient")}
            </p>
            <ErrorMsg errMsg={isError} />
          </div>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto ">
          <Button
            text={t("transfer")}
            type="button"
            loading={isLoading}
            handleClick={handleSubmit}
          />
        </div>
      </>
    </Modal>
  );
};

interface WithdrawSuccessProps {
  data: TransferSuccess | undefined;
  handleModal: () => void;
}
//Successful withdrawal
export const WithdrawSuccess: React.FC<WithdrawSuccessProps> = ({
  handleModal,
  data,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      close={false}
      title={""}
      description={""}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-8 bg-white rounded dark:bg-clrDarkBg md:space-y-3 ">
          <SuccessIcon />
          <div className="text-center md:mb-5">
            <h2 className="mb-3 text-sm font-semibold md:text-base text-clrGreen2">
              {t("success")}
            </h2>
            <p className="text-x10 font-semibold md:font-medium text-clrGray66 dark:text-clrPlaceholder w-[70%] mx-auto md:w-full">
              {data?.currencyCode} Transferred to{" "}
              <span>{data?.recipientEmail}</span>
            </p>
          </div>
          <div className="space-y-1 text-center">
            <p className="text-[9px] font-semibold md:font-medium text-clrGray66 dark:text-clrPlaceholder">
              {t("amount")}
            </p>
            <p className="text-lg font-bold text-black md:text-xl dark:text-white">
              {data?.amount} {data?.currencyCode}
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button type="button" text={t("close")} handleClick={handleModal} />
        </div>
      </>
    </Modal>
  );
};

//Confirm that user wants to withdraw into currency
export const ConfirmModalWithdraw: React.FC<ConfirmCryptoWithdrawal> = ({
  modalRef,
  handleModal,
  handleOpenSuccess,
  withdrawalDetails,
}) => {
  const { t } = useTranslation();
  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] flex justify-center items-center"
      ref={modalRef}
    >
      <div className="bg-[#F4F4F4] px-4 py-6 z-30 rounded-xl w-72 md:w-[450px]">
        <div className="flex justify-end">
          <button onClick={handleModal}>
            <img
              src={CloseIcon}
              alt="Close modal"
              width={13}
              height={13}
              className="self-end"
            />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center p-8 mt-4 bg-white">
          <div className="flex items-center justify-center bg-[#EEE1A9] w-12 h-12 rounded-[50%]">
            <img src={TickSquare} alt="" width={30} height={30} />
          </div>
          <h1 className="mt-4 text-sm font-semibold md:text-base text-clrTextBlue">
            {t("confirmTransaction")}
          </h1>
          <p className="my-3 font-normal text-x9 text-clrGray66">
            {t("aboutToWithdraw")}
          </p>
          <p className="font-semibold text-clrTextGray">
            <span className="text-2xl font-extrabold md:text-5xl">
              {numberWithCommas(withdrawalDetails?.amtToWithdraw)}
            </span>{" "}
            <span className="text-x10">
              {withdrawalDetails?.selectedBalance.symbol}
            </span>
          </p>
          <div className="mt-2 text-center">
            <p className="text-x10 font-normal text-clrGray66 mb-1.5">
              {t("network")}:
            </p>
            <p className="font-semibold text-x10 text-clrTextGray">Arbitrum</p>
          </div>
          <div className="mt-3 text-center">
            <p className="text-x9 md:text-x10 font-normal text-clrGray66 mb-1.5">
              {t("toTheFollowingCryptoAddress")}:
            </p>
            <p className="font-semibold text-x9 md:text-x10 text-clrTextGray">
              {withdrawalDetails?.walletAddress}
            </p>
          </div>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto ">
          <Button
            text={t("withdraw")}
            type="button"
            handleClick={handleOpenSuccess}
          />
        </div>
      </div>
    </section>
  );
};

//Get User Pin here
export const WithdrawEnterPin: React.FC<ConfirmModalProps> = ({
  handleModal,
  handleContinue,
  errMsg,
  handleChange,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      close={true}
      title={t("enterPin")}
      description={t("enterYourPinToProceed")}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded dark:bg-clrDarkBg">
          <div className="mb-4 text-center">
            <label
              htmlFor="pin"
              className="block mb-4 font-bold leading-3 text-x10 text-clrTextBlue dark:text-white md:text-sm"
            >
              {t("enterPin")}
            </label>
            <div className="mb-4">
              <AuthCode
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 rounded-md bg-[#f5f5f5]"
                containerClassName="flex justify-center gap-3"
                ariaLabel="verify-otp"
                isPassword={true}
                autoFocus={true}
                length={4}
                allowedCharacters="numeric"
                onChange={handleChange}
              />
            </div>
          </div>
          <Link
            to={"/reset"}
            className="mt-5 font-normal underline text-x10 text-clrTextBlue dark:text-clrPlaceholder"
          >
            {t("forgotPin")}
          </Link>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto ">
          <p className="mb-2 font-medium text-center text-x10 text-clrRed">
            {errMsg}
          </p>
          <Button text={t("submit")} type="button" handleClick={handleContinue} />
        </div>
      </>
    </Modal>
  );
};

//Modal to verify email by entering OTP
export const WithdrawVerifyEmail: React.FC<ConfirmModalProps> = ({
  handleModal,
  handleContinue,
  handleChange,
  resendOtp,
  errMsg,
  loading,
}) => {
  const { emailAddress } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  return (
    <Modal
      close={true}
      title={t("verifyOTP")}
      description={`${t("pleaseEnterTheSixDigitPinWeSentTo", { emailAddress })}`}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded dark:bg-clrDarkBg">
          <div className="mb-4 text-center">
            <label
              htmlFor="pin"
              className="block mb-4 font-bold leading-3 text-x10 text-clrTextBlue dark:text-clrPlaceholder md:text-sm"
            >
              {t("enterCode")}
            </label>
            <div className="mb-4">
              <AuthCode
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] dark:text-secondary dark:border-secondary dark:bg-clrDarkBg2 rounded-md bg-[#f5f5f5]"
                containerClassName="flex justify-center gap-3"
                ariaLabel="verify-otp"
                isPassword={true}
                autoFocus={true}
                length={6}
                allowedCharacters="numeric"
                onChange={handleChange}
              />
            </div>
          </div>
          <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder ">
            {t("didNotReceiveAnOTP")}
          </p>
          <p
            className="underline mt-5 text-[10px] font-normal text-clrTextBlue dark:text-white cursor-pointer"
            onClick={resendOtp}
          >
            {t("resendOTP")}
          </p>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto ">
          <p className="mb-2 font-medium text-center text-x10 text-clrRed">
            {errMsg}
          </p>
          <Button
            loading={loading}
            disabled={loading}
            text={t("submit")}
            type="button"
            handleClick={handleContinue}
          />
        </div>
      </>
    </Modal>
  );
};
