import { ButtonOutlineWallet } from "../../reusables";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { handleTransactionType } from "../../../features/store/walletReducerSlice";
import { useTranslation } from "react-i18next";


const TransactionOption = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { transactionTab } = useAppSelector((state) => state.wallet);

    const handleTab = (tab: number) =>{
        dispatch(handleTransactionType (tab))
    }

    return (
        <div className="flex flex-wrap gap-3 mb-6 md:mb-10">
            <ButtonOutlineWallet
                type="button"
                text={t("crypto")}
                handleClick={()=> handleTab(1)}
                active={transactionTab === 1}
            />
            <ButtonOutlineWallet
                type="button"
                text="P2P"
                handleClick={()=> handleTab(2)}
                active={transactionTab === 2}
            />
            <ButtonOutlineWallet
                type="button"
                text={t("swap")}
                handleClick={()=> handleTab(3)}
                active={transactionTab === 3}
            />
            <ButtonOutlineWallet
                type="button"
                text={t("transfer")}
                handleClick={()=> handleTab(4)}
                active={transactionTab === 4}
            />
            <ButtonOutlineWallet
                type="button"
                text={t("stake")}
                handleClick={()=> handleTab(5)}
                active={transactionTab === 5}
            />
        </div>
    );
};

export default TransactionOption;
