import React from "react";
import SuccessCheck from "../../assets/auth/successcheck.svg";

interface StatusPageProps {
  user: string;
  msg: any;
  title: string;
}

export const StatusPage = ({ msg, user, title }: StatusPageProps) => {
  return (
    <div className="relative flex justify-center items-center bg-white dark:bg-clrDarkBg w-full max-w-[800px] mx-auto mb-12 md:mb-8 h-full rounded-xl">
      <div className="flex items-center flex-col mt-8 mb-4">
        <img
          src={SuccessCheck}
          alt="Success Check logo"
          className="mb-6"
          width={90}
          height={90}
        />
        <div className="space-y-5 mb-6 text-center">
          <h1 className="text-xl font-medium  text-[#00C087]">{title}</h1>
          <p className="text-[0.625rem] md:text-base font-medium text-black dark:text-white">
            {user}
          </p>
          <p className="text-[0.625rem] md:text-sm font-medium text-[#666] dark:text-clrPlaceholder">
            {msg}
          </p>
        </div>
      </div>
    </div>
  );
};
