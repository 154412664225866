import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, TableLoader } from "../reusables";
import CopyIcon from "../../assets/icons/copy.svg";
import QRCode from "react-qr-code";
import { copyToClipboard } from "../../Helper";

import {
  useGetSupportedCurrenciesQuery,
  useGetAddressForCryptoFundingQuery,
} from "../../features/api/walletSlice";
import { Modal } from "../reusables/Modal";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";

/**
 * ChooseCurrency component.
 * Renders a currency selection modal.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the wallet selection modal.
 * @param description - Description of the wallet selection modal.
 * @param handleSelectedCurrency - Function to handle wallet selection.
 * @param type - Type of the transaction category.
 * @returns React functional component.
 */

export const ChooseCurrency: React.FC<ChooseCurrencyProps> = ({
  handleModal,
  title,
  description,
  handleSelectedCurrency,
  type,
}) => {
  const { t } = useTranslation();
  const { data: currencies, isLoading: loadingCurrencies } =
    useGetSupportedCurrenciesQuery(type?.toLowerCase() as string);

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();
  const navigate = useNavigate();
  return (
    <Modal
      close={true}
      title={title}
      description={businessProfile?.data?.verificationStatus !== "VERIFIED" ? "" : description}
      handleCloseModal={handleModal}
    >
      <div className="space-y-2">
        {businessProfile?.data?.verificationStatus !== "VERIFIED" ?
          <button
            onClick={() => navigate('/home/profile')}
            className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
          >
            <div>
              <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                {t("verifyAccount")}
              </h3>
              <p className="font-medium text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
                {t("pleaseVerifyYourAccountTo")} {title}
              </p>
            </div>
            <img
              src={ArrowDown}
              alt={t("moreOptions")}
              width={20}
              height={20}
              className="-rotate-90"
            />
          </button>
          : loadingCurrencies ? (
            <TableLoader />
          ) : (
            currencies?.data?.currencies.map((each) => (
              <button
                key={each.id}
                onClick={() => handleSelectedCurrency(type, each)}
                className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
              >
                <div>
                  <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                    {each.code}
                  </h3>
                  <p className="font-medium text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
                    {each.name}
                  </p>
                </div>
                <img
                  src={ArrowDown}
                  alt={t("moreOptions")}
                  width={20}
                  height={20}
                  className="-rotate-90"
                />
              </button>
            ))
          )}
      </div>
    </Modal>
  );
};

/**
 * ChoosePreferredMethod component.
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the method selection modal.
 * @param description - Description of the method selection modal.
 * @param handleCryptoFunding- Function to handle crypto-funding option.
 * @param handleP2P - Fundtion to handle p2p funding.
 * @returns React functional component.
 */
export const ChoosePreferredMethod: React.FC<ChoosePreferredMethodProps> = ({
  handleModal,
  handleCloseModal,
  title,
  description,
  handleCryptoFunding,
  handleP2P,
  channels,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      close={true}
      title={title}
      description={description}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <div className="space-y-2">
        {isLoading && <TableLoader />}
        {!isLoading && channels?.map(channel => {
          return (
            <button
              onClick={channel?.name === "Crypto" ? handleCryptoFunding : handleP2P}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
            >
              <div>
                <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                  {channel.name}
                </h3>
                <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                  {channel.description}
                </p>
              </div>

              <img
                src={ArrowDown}
                alt={t("moreOptions")}
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          )
        })}
      </div>
    </Modal>
  );
};

/**
 * ChoosePreferredMethod for withdraw.
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the method selection modal.
 * @param description - Description of the method selection modal.
 * @param handleCrypto- Function to handle crypto-withdrawal option.
 * @param handleWithdrawP2P - Function to handle p2p withdrawal.
 * @param handleCloseModal - Fundtion to handle close modal.
 * @param channels - List of supported channels for a particular category
 * @param isLoading - boolean to get channels
 * @returns React functional component.
 */
export const ChooseWalletMethod: React.FC<ChooseWithdrawalProps> = ({
  handleModal,
  title,
  description,
  handleTransfer,
  handleWithdrawP2P,
  handleCrypto,
  handleCloseModal,
  channels,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      close={true}
      title={title}
      description={description}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <div className="space-y-2">
        {isLoading && <TableLoader />}
        {!isLoading && channels?.map(channel => {
          return (
            <button
              onClick={channel.name === "Peer To Peer" ? handleWithdrawP2P : channel.name === "Transfer to User" ? handleTransfer : handleCrypto}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
            >
              <div>
                <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                  {channel.name}
                </h3>
                <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                  {channel.description}
                </p>
              </div>

              <img
                src={ArrowDown}
                alt={t("moreOptions")}
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          )
        })}
      </div>
    </Modal>
  );
};

/**
 * CryptoFundingDetail component.
 * Renders a modal displaying wallet address detail for crypto funding
 * @param handleCloseModal - Function to handle modal close.
 * @param handleModal - Function switching between modals.
 * @param currencyId - Id of the selected currency.
 * @returns React functional component.
 */
export const CryptoFundingDetail: React.FC<FundCryptoWalletProps> = ({
  handleCloseModal,
  handleModal,
  currencyId,
  currencyCode,
}) => {
  const { t } = useTranslation();
  const { data: cryptofunding, isLoading: gettingCryptoFundingDetails } =
    useGetAddressForCryptoFundingQuery(currencyId as number);
  const [copiedText, setCopied] = useState<string>("");
  return (
    <Modal
      close={false}
      title={t("fundWallet")}
      description={t("useTheDetailsBelow")}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <>
        <div className="flex flex-col items-center justify-center gap-3 px-3 py-5 bg-white rounded dark:bg-clrDarkBg">
          <p className="text-[10px] md:w-1/2 text-center mx-auto font-medium text-[#707070] dark:text-clrPlaceholder">
            {t("scanTheQRCodeBelowToGetTheWalletAddress")}
          </p>
          {gettingCryptoFundingDetails ? (
            <TableLoader />
          ) : (
            <div className="flex items-center justify-center w-32 h-32 p-2 QRcode-bg">
              <div className="relative z-[4]">
                {cryptofunding ? (
                  <QRCode size={100} value={cryptofunding?.data?.destination} />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <p className="text-[11px] md:text-xs font-semibold text-black dark:text-white">
            {t("address").toUpperCase()}
          </p>
          <p className="flex gap-2 items-center text-x8 md:text-xs font-medium text-[#707070] dark:text-clrPlaceholder relative">
            <span>{cryptofunding?.data?.destination}</span>
            {cryptofunding && (
              <img
                onClick={() => {
                  copyToClipboard(cryptofunding?.data?.destination);
                  setCopied(cryptofunding?.data?.destination);
                }}
                src={CopyIcon}
                width={15}
                height={15}
                className="cursor-pointer"
                alt={t("copyTheCode")}
              />
            )}
            {cryptofunding?.data?.destination === copiedText && (
              <p className="absolute right-0 p-1 mt-10 text-white border-solid rounded dark:text-clrPlaceholder bg-clrYellow text-x10 ">
                {" "}
                {t("copied")}
              </p>
            )}
          </p>
        </div>
        <div className="bg-[#25346A1A] p-3 mt-2 mb-4 text-x9 md:text-x10 font-medium text-clrGray dark:text-clrPlaceholder rounded">
          <h5 className="mb-1">{t("important")}</h5>
          <ul className="pl-3 list-disc list-outside">
            {cryptofunding?.data.instructions.map((each) => (
              <li>{each}</li>
            ))}
          </ul>
        </div>
        <div className="md:w-[80%] mt-8 mx-auto ">
          <Button text={t("close")} type="button" handleClick={handleCloseModal} />
        </div>
      </>
    </Modal>
  );
};
