import { apiSlice } from "./apiSlice";
import {
    RewardBoosterHistoryRequest, EstimatedInterestResponse,
    RewardBoosterHistoryResponse, BoosterStatusRequest,
    RewardBoosterResponse, ToggleBoosterRequest,
    EstimatedInterestRequest,
    ToggleBoostResponse,
    AprResponse,
    RewardHistoryRequest,
    RewardHistoryResponse,
    FaqRequest,
    FaqResponse
} from "./rewardSliceTypes";


const rewardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Booster Status
        getBoosterStatus: build.query<BoosterStatusRequest, string>({
            query: (userId) => `/v4/reward-booster/user/${userId}/status`,
            providesTags: ['Reward']
        }),

        //Get Dashboard Summary
        getRewardBoosterDetails: build.query<RewardBoosterResponse, string>({
            query: (userId) => `/v4/reward-booster/user/${userId}`,
            providesTags: ['Reward']
        }),

        //Get Booster History
        getRewardBoosterHistory: build.query<RewardBoosterHistoryResponse, RewardBoosterHistoryRequest>({
            query: ({ userId, StartDateTimeStamp, EndDateTimeStamp, Page, PageSize }) => `/v4/reward-booster/user/${userId}/cycle-history?StartDateTimeStamp=${StartDateTimeStamp}&EndDateTimeStamp=${EndDateTimeStamp}&Page=${Page}&PageSize=${PageSize}`,
            providesTags: ['Reward']
        }),

        //Get APR for the reward booster 
        getApr: build.query<AprResponse, string>({
            query: (lockPeriod) => ({ url: `/v4/reward-booster/apr?lockPeriod=${lockPeriod}` }),
            providesTags: ['Reward']
        }),

        //Get estimated interest and reward 
        getEstimatedInterest: build.mutation<EstimatedInterestResponse, EstimatedInterestRequest>({
            query: (payload) => ({
                url: `/v4/reward-booster/estimated-interest`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Reward"]
        }),

        //Opt in or out of the rewarrd booster program
        toggleRewardBoosterParticipation: build.mutation<ToggleBoostResponse, ToggleBoosterRequest>({
            query: ({ userId, payload }) => ({
                url: `/v4/reward-booster/status/user/${userId}`,
                method: "PUT",
                body: payload
            }),
            invalidatesTags: ["Reward"]
        }),

        //Get Reward History
        getRewardHistory: build.query<RewardHistoryResponse,  RewardHistoryRequest>({
            query: ({ userId, cycleId, Page, PageSize }) => `/v4/reward-booster/user/${userId}/cycle-history/${cycleId}?Page=${Page}&PageSize=${PageSize}`,
            providesTags: ['Reward']
        }),

         //Get Booster FAQs
        getBoosterFaq: build.query<FaqResponse,  FaqRequest>({
            query: ({ Page, PageSize }) => `/v4/reward-booster/faq/?Page=${Page}&PageSize=${PageSize}`,
        }),


    }),
    overrideExisting: true,
})

export const { useGetRewardBoosterDetailsQuery, useGetRewardBoosterHistoryQuery,
    useGetBoosterStatusQuery, useGetEstimatedInterestMutation,
    useGetAprQuery, useGetRewardHistoryQuery, useGetBoosterFaqQuery,
    useToggleRewardBoosterParticipationMutation } = rewardSlice