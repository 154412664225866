import { useParams } from 'react-router-dom';
import { BarChart } from "./BarChartSession";
import { useState } from "react";
import { BarChartUptimeProps } from "./Uptime";
import { BarChartUsageProps } from "./DataUsage";
import { SearchRow } from './common';
import { exportTableData } from '../../Helper';
import { useGetDataUsageConnectedClientGraphResultsQuery, useGetUptimeConnectedClientGraphResultsQuery } from '../../features/api/analyticsSlice';
import { useTranslation } from 'react-i18next';



const ConnectionDetails = () => {
  const { t } = useTranslation();
  const {analyticId, connectedUserId } = useParams();
  // State for filters
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');


  const { data: uptimeGraph } = useGetUptimeConnectedClientGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear,
    connectedClient: connectedUserId || ''
  });

  const { data: dataUsageGraph } = useGetDataUsageConnectedClientGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear,
    connectedClient: connectedUserId || ''
  });

  const chartUptimeData: BarChartUptimeProps = {
    labels: uptimeGraph?.data.map(item => item.timePeriod) || [],
    data: uptimeGraph?.data.map(item => ({
      uptime: item.uptime,
      uptimeInSeconds: item.uptimeInSeconds,
      timePeriod: item.timePeriod
    })) || []
  };

  const chartData: BarChartUsageProps = {
    labels: dataUsageGraph?.data.map(item => item.timePeriod) || [],
    data: dataUsageGraph?.data.map(item => ({
      dataUsed: item.dataUsed,
      dataUsedInBytes: item.dataUsedInBytes,
      timePeriod: item.timePeriod,
    })) || []
  };

  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <div className="grid gap-1 mb-6 md:mb-8">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            {t("dataUsage")}
          </h3>
          <SearchRow 
            setFilterYear={setFilterYear} 
            filterYear={filterYear} 
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            exportTable={()=> exportTableData(dataUsageGraph?.data || [], "Overall Uptime")}
          />
          <BarChart chartData={chartData} />
        </div>
        <div className="grid gap-4 pb-4">
          <h3 className="text-sm font-semibold text-clrGray dark:text-white">
            {t("uptime")}
          </h3>
          <BarChart chartData={chartUptimeData} />
        </div>
      </div>
    </section>
  )
}

export default ConnectionDetails