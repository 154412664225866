import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ErrorMsg } from "../reusables";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { BankAccount } from "./P2PInfo";
import {
  useGetConversionRateQuery,
  useGetSupportedP2PCurrenciesQuery,
  useGetMinimumAmountQuery,
  useGetBalancesQuery,
  useLazyGetTransactionChargeQuery,
} from "../../features/api/walletSlice";
import { setOutBoundCurrency } from "../../features/store/walletReducerSlice";
import { numberWithCommas} from "../../Helper";
import { Currency } from "../../features/api/walletSliceTypes";
import { useTranslation } from "react-i18next";


interface P2PAmountProps {
  handleAmountConfirm: () => void;
  setOutBoundValue: (value: string) => void;
  outBoundValue: number;
  inBoundValue: number;
  setInboundValue: (value: string) => void;
  setSelectedBank: (bank: Bank) => void;
  selectedBank: Bank | null;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string;
  type: "WITHDRAWAL" | "FUND";
  inboundCurrency: Currency;
  outboundCurrency: Currency;
}


let BALANCE_STATE = {
  symbol: "",
  name: "",
  iconUrlSvg: "",
  iconUrlPng: "",
  balance: 0,
  usdBalances: 0,
  currencyId: 0,
};

const P2PAmount = ({
  handleAmountConfirm,
  setOutBoundValue,
  setInboundValue,
  setSelectedBank,
  selectedBank,
  setPhoneNumber,
  phoneNumber,
  outBoundValue,
  inboundCurrency,
  outboundCurrency,
  type,
}: P2PAmountProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorMsg, setError] = useState<string>("");
  const { userId } = useAppSelector((state) => state.auth);
  // const { selectedCurrency: inboundCurrency, outboundCurrency } =
  //   useAppSelector((state) => state.wallet);

  const [trigger] = useLazyGetTransactionChargeQuery();
  const [isAddBank, setIsAddBank] = useState<boolean>(false);
  const [transactionCharge, setTransactionCharge] = useState<number>(0);
  const [chargeableAmount, setChargeableAmount] = useState<number>(0);
  const [isp2pCurrencies, setShowCurrencies] = useState<boolean>(false);

  const { data: p2pcurrencies, isLoading: loadingCurrency } =
    useGetSupportedP2PCurrenciesQuery(type === "FUND" ? "Buy" : "Sell");
  const { data: minimumAmount, isLoading: gettingMinimumAmount } =
    useGetMinimumAmountQuery();

  const [selectedBalance, setSelectedBalance] =
    useState<BalanceProps>(BALANCE_STATE);
  //Get user's total balance
  const { data: balances } = useGetBalancesQuery(userId, {skip: !userId});
  const { selectedCurrency } = useAppSelector((state) => state.wallet);

  const { data: rate } = useGetConversionRateQuery({
    inboundCurrencyId: inboundCurrency.id,
    outBoundCurrencyId: outboundCurrency.id,
  }, {
    skip: !inboundCurrency.id || !outboundCurrency.id,
  });

  const handleBankSelection = (bank: Bank) => {
    setSelectedBank(bank);
  };

  //check if we have selected currency. if we do, set that as the current balance, otherwise,
  //set the first balance as the current balance
  useEffect(() => {
    if (balances) {
      if (selectedCurrency) {
        let currBalance = balances?.data.balances.find(
          (each) => each.symbol === selectedCurrency.code
        );
        currBalance && setSelectedBalance(currBalance);
      } else {
        setSelectedBalance(balances?.data.balances[0]);
      }
    }
  // }, [balances?.data.balances, selectedCurrency]);
  }, [balances, selectedCurrency]);


  //Function to validate p2p
  const handleNext = () => {
    let minimum = minimumAmount?.data.minimumAmount || 0;
    //TODO: Validate that phone number does not contain text
    if (!inboundCurrency.name) {
      setError(
        t("pleaseSelectCurrency")
      );
    } else if (!outboundCurrency.name) {
      setError(
        `${t("pleaseSelectA")} ${type === "FUND" ? t("funding") : t("withdrawal")} ${t("currency")}`
      );
    } else if (!phoneNumber) {
      setError(t("pleaseEnterValidPhoneNumber"));
    } else if (outBoundValue < minimum) {
      setError(
        `${t("minimum")} ${type === "FUND" ? t("fund") : t("withdrawal")} ${t("amountIs")} ${minimum + " " + outboundCurrency?.code
        }`
      );
    } else if (!selectedBank?.bankName) {
      setError(t("pleaseSelectABankForTheTransaction"));
    } else {
      setError("");
      if (type === "FUND") {
        setInboundValue((outBoundValue / (rate?.data?.rate || 0)).toString());
      } else {
        setInboundValue((outBoundValue * (rate?.data?.rate || 0)).toString());
      }
      handleAmountConfirm();
    }
  };



  useEffect(()=>{
    if(outboundCurrency){
      let data = {
        transactionCategory: type === "FUND" ? "Funding" : "Withdrawal",
        currencyId: outboundCurrency.id,
        channel: "XendBridge",
        amount: outBoundValue,
      }
      trigger(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTransactionCharge(res.data.transactionCharge)
          setChargeableAmount(res.data.chargeableAmount)
        }
      });
    }
  // }, [outBoundValue, type ])
  }, [outBoundValue, type, outboundCurrency, trigger]);


  const handleMax = () =>{
    let data = {
      transactionCategory: type === "FUND" ? "Funding" : "Withdrawal",
        currencyId: outboundCurrency.id,
        channel: "XendBridge",
        amount: (selectedBalance?.balance || 0),
    }
    trigger(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          let transactionCharge = res?.data.transactionCharge;
          let max = (selectedBalance?.balance || 0) - (transactionCharge || 0)
          setOutBoundValue(max.toString())
        }
      });
  }

  return (
    <>
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-8 md:py-5">
        <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-1.5 md:mb-3">
          {t("amount")}
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          {t("enterThe")} {type === "FUND" ? t("funding") : t("withdrawal")} {t("amount")}
        </p>
        <div className="mt-6 md:px-3 md:mt-4">
          <label
            htmlFor=""
            className="block mb-1 font-medium md:mb-2 text-x10 text-clrTextBlue dark:text-clrPlaceholder"
          >
            {t("currency")}
          </label>
          <button
            className="flex justify-between items-center pb-1 w-full border-b-[0.7px] border-solid border-[#25346a99] dark:border-clrGray "
            onClick={() => setShowCurrencies(!isp2pCurrencies)}
          >
            <p className="font-semibold text-x10 text-clrGray opacity-60">
              {type === "FUND" ? outboundCurrency?.name : inboundCurrency?.name}
            </p>

            <img
              src={ArrowDown}
              alt={t("moreOptions")}
              width={20}
              height={20}
              className=""
            />
          </button>
          <div className="absolute z-20 w-1/5">
            {isp2pCurrencies && (
              <div className="p-3 space-y-2 bg-white border-2 rounded-md dark:bg-clrDarkBg border-clrBg">
                {loadingCurrency
                  ? "Loading.."
                  : p2pcurrencies?.data.currencies.map((currency) => {
                    return (
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        key={currency.id}
                        onClick={() => {
                          dispatch(setOutBoundCurrency(currency));
                          setShowCurrencies(!isp2pCurrencies);
                        }}
                      >
                        <p className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                          {currency.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-4 my-5 ">
          <p className="text-xs font-semibold text-clrGray opacity-60 dark:text-clrPlaceholder dark:font-medium">
            {t("clickToEnterAmount")}
          </p>
          <div className="flex">
          <span className="flex">
            <input
              value={Number(outBoundValue || 0)}
              className="text-5xl font-bold text-center text-black outline-none h-[3.2rem] md:text-6xl dark:text-white dark:placeholder:text-white dark:bg-clrDarkBg"
              style={{ width: `${outBoundValue.toString().length * 35}px` }}
              type="text"
              placeholder="0"
              onChange={(e) => setOutBoundValue(e.target.value)}
            />
            <span className="self-end pb-2 font-normal text-x10 text-clrGray md:pb-0">
              {outboundCurrency.code}
            </span>
          </span>
          {type === "WITHDRAWAL"&& <div>
            <p
              onClick={() =>
                handleMax()
              }
              className="text-[10px] cursor-pointer mt-1 font-semibold text-clrTextGray dark:text-clrPlaceholder bg-white dark:bg-clrDarkBg px-1 border-[1px] border-solid border-clrBorder2 rounded-md"
            >
              {t("max")}
            </p>
            </div>}
              </div>
          <p className="text-xs font-semibold text-clrGray opacity-60 dark:font-medium dark:text-clrPlaceholder">
            {t("youWillReceive")}
          </p>

          <p className="flex h-[3.5rem]">
            <span className="text-5xl font-bold text-black dark:text-white md:text-6xl">
              {" "}
              {rate && type === "FUND"
                ? numberWithCommas(outBoundValue / (rate?.data?.rate || 0))
                : numberWithCommas(outBoundValue * (rate?.data?.rate || 0))}
            </span>
            <span className="self-end font-normal text-x10 text-clrGray">
              {inboundCurrency.code}
            </span>
          </p>
            <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder">
              {t("minimum")} {type === "FUND" ? t("fund") : t("withdrawal")} {t("amount")}:{" "}
              <span className="text-[#E5B910]">
                {" "}
                {gettingMinimumAmount
                  ? "Loading..."
                  : minimumAmount?.data.minimumAmount}{" "}
                {outboundCurrency.code}
              </span>
            </p>
        </div>

        <button
          onClick={() => setIsAddBank(true)}
          className="flex gap-3 w-full border-[#C4C4C4] dark:border-clrGray border-solid border-[1px] p-3.5 items-center rounded"
        >
          <img
            src={selectedBank ? selectedBank.iconUrlSvg : AddIcon}
            alt={t("bankLogo")}
            width={20}
            height={20}
          />
          <p
            className={`text-[10px]  mr-auto  ${selectedBank
                ? "font-semibold text-black dark:text-white"
                : "font-normal text-clrGray dark:text-clrPlaceholder"
              }`}
          >
            {selectedBank
              ? `${selectedBank.name} (${selectedBank.accountNumber})`
              : "Select a bank account"}
          </p>
          <img src={ArrowDown} alt="more options" width={20} height={20} />
        </button>

        <div className="mt-4 md:px-3">
          <label
            htmlFor=""
            className="block font-semibold dark:font-medium md:mb-2 text-x10 text-clrTextBlue dark:text-clrPlaceholder"
          >
            {t("phoneNumber")}
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={phoneNumber}
            placeholder="08000000000"
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="text-x10 font-normal text-clrText   dark:!text-clrPlaceholder placeholder:!text-[#98989866] dark:placeholder:!text-clrPlaceholder dark:focus:!bg-clrDarkBg dark:!bg-clrDarkBg pb-1 w-full border-b-[0.7px] border-solid border-[#25346a99] dark:!border-clrGray focus:outline-none"
          />
        </div>
        {type === "WITHDRAWAL" &&
        <div className="mt-4 md:px-3">
          <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder mb-1">
            {t("availableBalance")}:{" "}
            <span className="text-[#E5B910]">
              {" "}
              {numberWithCommas((selectedBalance?.balance || 0), 4)}{" "}
              {outboundCurrency.code}
              
            </span>
          </p>
          <p className="font-normal text-x8 text-clrRed dark:text-clrPlaceholder mb-1">
            {t("transactionCharge")}:{" "}
            <span className="text-clrRed">
              {" "}
              {transactionCharge || 0}{" "}
              {outboundCurrency.code}
              
            </span>
          </p>
        
          <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder mt-1">
            {t("totalChargeableAmount")}:{" "}
            <span className="text-[#E5B910]">
              {" "}
              {chargeableAmount}{" "}
              {outboundCurrency.code}
              
            </span>
          </p>
          {(chargeableAmount > selectedBalance?.balance ) && (outBoundValue !==0) && <ErrorMsg errMsg={"*Total Chargeable amount cannot be greater than your available balance"} /> } 
        </div>}
        <ErrorMsg errMsg={errorMsg} />

        {type === "WITHDRAWAL" && <div className="md:w-[50%] mt-8 mx-auto ">
          <Button text={t("continue")} type="button" disabled={chargeableAmount > selectedBalance?.balance || outBoundValue ===0 } handleClick={handleNext} />
        </div>}
        {type === "FUND" && <div className="md:w-[50%] mt-8 mx-auto ">
          <Button text={t("continue")} type="button" handleClick={handleNext} />
        </div>}
      </div>
      <p className="text-[8px] font-normal text-clrText text-center opacity-40 mt-4">
        {t("poweredBy")} Xend Bridge{" "}
      </p>
      {isAddBank && (
        <BankAccount
          handleModal={() => setIsAddBank(false)}
          handleBankSelection={handleBankSelection}
        />
      )}
    </>
  );
};

export default P2PAmount;
