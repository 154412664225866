import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";

const Booster = () => {
  const {t} = useTranslation();
  return (
    <SecondaryNav 
      paths={[
        {path: "/home/booster/overview", title: t("overview")},
        {path: "/home/booster/history", title: t("boostHistory") }
      ]}
    />
   
  );
};

export default Booster;
