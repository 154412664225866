import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowYellow } from "../../assets/icons/arrow-down-Yellow.svg";

/**
 * Represents the props for a dashboard card component.
 */
interface QuickActionCardProps {
    icon: ReactNode; // The icon component for the card.
    title: string; // The title of the card.
    link?: string; // Optional link associated with the card.
    id?: string; // id of the card.
}

/**
@component
Creates a Dashboard card component with optional features 
@param {QuickActionCardProps} props
* */

export const QuickActionCard = ({
    icon,
    title,
    link,
    id,
}: QuickActionCardProps) => {
    return (
        <div id={id} className="flex gap-2 md:gap-4 bg-white dark:bg-clrDarkBg2 border border-solid border-[#C4C4C433] dark:border-none rounded-[7px] p-3 pl-[1.4rem] h-[90px] md:h-[100px]">
            <div className="flex flex-col justify-between mr-auto">
                <div className="flex flex-col gap-2 md:mt-1.5">
                    {icon}
                    <p className="text-xs font-medium text-black md:text-sm dark:text-white whitespace-break-spaces">
                        {title}
                    </p>
                </div>
            </div>
            {link && (
                <Link to={link} className="self-center">
                    <ArrowYellow
                        width={"20px"}
                        height={"20px"}
                        className="transform -rotate-90"
                    />
                </Link>
            )}
        </div>
    );
};
