import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useRef, useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = ({ data }: { data: any[] }) => {
  const chartRef = useRef<ChartJS | null>(null);

  const chartData = {
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 0,
      },
    ],
  };

  const options: any = {
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  const plugins = [
    {
      id: 'centerText',
      beforeDraw: (chart: any) => {
        const { ctx, chartArea: { width, height } } = chart;
        const textLine1 = "Traffic";
        const textLine2 = "Analytics";

        ctx.save();
        ctx.font = '600 18px Work Sans';
        ctx.fillStyle = '#fff';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Draw the first line
        ctx.fillText(textLine1, width / 2, height / 2 - 10);

        // Draw the second line, slightly below the first
        ctx.fillText(textLine2, width / 2, height / 2 + 15);
        ctx.restore();
      }
    }
  ];

  return (
    <div className="doughnut-container">
      <Doughnut ref={chartRef as any} data={chartData} options={options} plugins={plugins} />
    </div>
  );
};
