import { Button } from "../reusables";
import TimerIcon from "../../assets/timer.svg";
import { useParams } from "react-router-dom";
import { ScheduleTimer, TimerSuccessfulModal } from "./ParentalControlModals";
import { useState } from "react";
import CopyIcon from "../../assets/icons/copy.svg";
import ParentalCategories from "./ParentalCategories";
import { useGetParentalControlDeviceQuery, useSetRestrictionTimeMutation, useToggleParentalControlMutation } from "../../features/api/parentalControlSlice";
import { convertTo12HourFormat, copyToClipboard } from "../../Helper";
import { useTranslation } from "react-i18next";

const ParentalControlSetup = () => {
    const { deviceId } = useParams();
    const { t } = useTranslation();
    const [trigger, {isLoading: toggleParentalControl}] = useToggleParentalControlMutation()
    const [isScheduleTimer, toggleScheduleTimer] = useState<boolean>(false);
    const [copiedText, setCopiedText] = useState<string>('');
    const [timerSuccessfulStatus, setTimerSuccessfulStatus] = useState<boolean>(false);

    const { data: device } = useGetParentalControlDeviceQuery(deviceId as string);

    const [startTime, setStartTime] = useState<string>("");
    const [stopTime, setStopTime] = useState<string>("");

    // Callback function to update startTime
    const handleStartTimeUpdate = (newStartTime: string) => {
        setStartTime(newStartTime);
    };

    // Callback function to update stopTime
    const handleStopTimeUpdate = (newStopTime: string) => {
        setStopTime(newStopTime);
    };


    const handleParentalControl = () =>{
        let controlData = {
            deviceId: device?.data.deviceId as string,
            payload: {
                isParentalControlEnabled: !device?.data?.isParentalControlEnabled
            }
        }
        trigger(controlData)
        .unwrap()
        .then((res)=>{
            console.log(res, 'i am the res')
            // showNotification({
            //     level: 'success',
            //     text: device?.data?.isParentalControlEnabled ? "Control successfully deactivated" : "Control successfully activated",
            //   });
        })
        .catch((err)=>{
            console.log(err, 'i am error message')
            // showNotification({
            //     level: 'warning',
            //     text: err?.message ? err?.message : 'Something went wrong',
            //   });
        })
    }
    const [update, { isLoading: updateloading }] = useSetRestrictionTimeMutation();

    const scheduleRestrictionTime = () => {
        let data = {
            deviceId: deviceId as string,
            payload: {
                enableRestrictionTimer: false,
            }
        }
        update(data)
            .unwrap()
            .then((res) => {
                console.log(res, "i am the nres");
            })
            .catch((err) => {
                console.log(err, 'i am nerr')
            })
    }

    const handleCopyClick = () => {
        const bssid = device?.data?.bssid as string;
        copyToClipboard(bssid);
        setCopiedText(bssid);

        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };


    return (
        <section className="w-[96%] mx-auto mt-3 mb-6 overflow-y-auto">
            <div className="flex flex-wrap items-center gap-2 mb-4">
                <div className="flex flex-col flex-grow px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
                    <div className="flex items-center gap-4">
                        <div className="h-24">
                            <img 
                                className="h-24"
                                src={device?.data?.imageUrl} 
                                alt={device?.data?.deviceName} 
                            />
                        </div>
                        <div>
                            <p className="text-lg font-semibold dark:text-white">{device?.data?.deviceName}</p>
                            <p className="relative flex gap-2">
                                <span className="text-base font-medium text-clrTextLightGray">
                                    {device?.data?.bssid}
                                </span>
                                <img
                                    src={CopyIcon}
                                    alt="Copy this BSSID"
                                    width={16}
                                    height={16}
                                    className="cursor-pointer"
                                    onClick={handleCopyClick}
                                />
                                {device?.data?.bssid === copiedText && (
                                    <p className="absolute p-1 px-2 mt-4 text-white border-solid rounded right-3 top-1 bg-primary text-x10 ">
                                        {t("copied")}
                                    </p>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="self-end">
                        {device?.data?.isParentalControlEnabled ? (
                            <Button
                                text={t("deactivateControl")}
                                handleClick={handleParentalControl}
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-medium"
                                py="py-2"
                                textSize="text-x11"
                                bgColor="bg-clrRed"
                                loading={toggleParentalControl}
                            />
                        ) : (
                            <Button
                                handleClick={handleParentalControl}
                                text={t("activateControl")}
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-normal"
                                py="py-2"
                                textSize="text-x11"
                                loading={toggleParentalControl}
                            />
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-full px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-4 md:py-8 md:w-1/4">
                    <div className="flex gap-3 h-[100px]">
                        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primary bg-opacity-10 aspect-square">
                            <img src={TimerIcon} alt="timer" className="w-7 h-7" />
                        </div>
                        
                        <div className="mt-2">
                            <p className="mb-2 text-sm font-semibold text-clrTextLightGray dark:text-white">Restriction Timer</p>
                            <p className="text-xs text-clrPlaceholder">
                                { device?.data?.usagePeriod?.isRestrictionTimerEnabled ? 
                                `Will restrict internet access from ${convertTo12HourFormat(device?.data?.usagePeriod?.startPeriod || "00:00:00")} to 
                                ${convertTo12HourFormat(device?.data?.usagePeriod?.endPeriod || "00:00:00")}`
                                : "No time restriction set"}
                            </p>
                        </div>
                    </div>
                    <div className="self-end">
                       {device?.data?.usagePeriod?.isRestrictionTimerEnabled ? (
                            <Button
                                text={"Cancel Timer"}
                                handleClick={scheduleRestrictionTime}
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-medium"
                                py="py-2"
                                textSize="text-x11"
                                bgColor="bg-clrRed"
                                disabled={!device?.data?.isParentalControlEnabled}
                                loading={updateloading}
                            />)
                            : (<Button
                                text={"Set Timer"}
                                handleClick={() => toggleScheduleTimer(!isScheduleTimer)}
                                type="button"
                                wBtn="w-fit bg-primary"
                                fontWeight="font-medium"
                                py="py-2"
                                textSize="text-x11"
                                disabled={!device?.data?.isParentalControlEnabled}
                            />)
                        }
                    </div>
                </div>
            </div>

            <ParentalCategories />

            {isScheduleTimer && 
                <ScheduleTimer 
                    deviceId={deviceId as string} 
                    handleModal={()=> toggleScheduleTimer(!isScheduleTimer)}
                    handleSuccessModalOpen={() => setTimerSuccessfulStatus(true)}
                    handleStartTimeUpdate={handleStartTimeUpdate}
                    handleStopTimeUpdate={handleStopTimeUpdate}
                />
            }
            
            {timerSuccessfulStatus && (
                <TimerSuccessfulModal
                    title={"Timer set"}
                    btnText="Done"
                    description={`Will restrict internet access from ${startTime} to ${stopTime}`}
                    handleModal={() => setTimerSuccessfulStatus(false)}
                />
            )}
        </section>
    );
};

export default ParentalControlSetup;