import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { numberWithCommas } from "../../Helper";
import { useTranslation } from "react-i18next";


type Referrals = {
  createdAt: string;
  name: string;
  email: string;
  bonus: number;
  status: string;
  isClaimed: boolean;
};

const referral = createColumnHelper<Referrals>();

export const useReferralColumn = () => {
  const {t} = useTranslation();
  
  const referralColumn = [
    referral.accessor("createdAt", {
      header: "#",
      cell: (row) => {
        return <p>{Number(row?.row.id) + 1}</p>;
      },
  
    }),
    referral.accessor("createdAt", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    referral.accessor("name", {
      header: t("refereeName"),
    }),
  
    referral.accessor("email", {
      header: t("refereeEmail"),
    }),
  
    referral.accessor("bonus", {
      header: t("accountEarned"),
      cell: (val) => {
        const bonus = val.getValue();
        return <p> {numberWithCommas(bonus)}</p>;
      },
    }),
  
    referral.accessor("isClaimed", {
      header: t("claimed"),
      cell: (info) => {
        const status = info.getValue();
  
        let color = "";
        let updatedStatus = "";
  
        if (status === true) {
          color = "#00C087";
          updatedStatus = "Yes";
        } else {
          color = "#E5B910";
          updatedStatus = "No";
        }
  
        return (
          <p
            style={{
              color,
            }}
          >
            {updatedStatus}
          </p>
        );
      },
    }),
  
  ];

  return referralColumn;
}

