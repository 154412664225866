import { apiSlice } from "./apiSlice";
import { CustomCodeResponse, DashboardResponse, DashboardRevenue, GuidedTourRequest, GuidedTourResponse, LinkCodeResponse, MiningRewardResponse, RevenueRewardResponse } from "./homeSliceTypes";


const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Dashboard Summary
        getDashboardSummary: build.query<DashboardResponse, void>({
            query: () => `/v4/users/dashboard`,
            providesTags: ['Agents', 'Vouchers', 'Agents', 'Devices']
        }),

        getUserRevenue: build.query<DashboardRevenue, void>({
            query: () => `/v4/users/dashboard/revenue`,
        }),

        getMiningReward: build.query<MiningRewardResponse, string>({
            query: (deviceId) => `/v4/miningReward?deviceId=${deviceId}`,
        }),

        getSessionHistory: build.query<RevenueRewardResponse, string>({
            query: (deviceId) => `/v4/sessions?deviceId=${deviceId}`,
        }),

        //Set Link Code
        linkCode: build.query<LinkCodeResponse, void>({
            query: () =>
                `/v4/hub-link-code`,
        }),

        // Get Custom Code
        customCode: build.query<CustomCodeResponse, void>({
            query: () =>
                `/v4/custom-code`,
            providesTags: ["CustomCode"]
        }),
        // Set a custom code
        setCustomCode: build.mutation<CustomCodeResponse, string>({
            query: (code) => ({
                url: `/v4/custom-code`,
                method: 'PUT',
                body: { code }
            }),
            invalidatesTags: ["CustomCode"]
        }),

        //Get a user's guided tours
        getGuidedToursDetail: build.query<GuidedTourResponse, string>({
            query: (userId) =>
                `/v4/guided-tours/uncompleted-tours/user/${userId}`,
            providesTags: ["Tours"]
        }),

        // Set a custom code
        updateTourStatus: build.mutation<CustomCodeResponse, GuidedTourRequest>({
            query: ({ tourId, data }) => ({
                url: `/v4/guided-tours/${tourId}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ["Tours"]
        }),


    }),
    overrideExisting: true,
})

export const { 
    useGetDashboardSummaryQuery, 
    useGetUserRevenueQuery, 
    useGetMiningRewardQuery, 
    useGetSessionHistoryQuery, 
    useLinkCodeQuery,
    useCustomCodeQuery,
    useSetCustomCodeMutation,
    useGetGuidedToursDetailQuery,
    useUpdateTourStatusMutation
} = dashboardSlice