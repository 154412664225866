import { explorerSlice } from "./apiSlice";
import { ClaimHistoryRequest, ClaimHistoryResponse, 
    ClaimRewardRequest, ConnectWalletExplorerRequest, ExplorerTransactionDetail, ExplorerTransactionResponse, ExplorerTransactionsRequest, 
    GetDevicesToSyncRequest, GetDevicesToSyncResponse, HolderResponse, HotspotHubRequest, 
    HotspotHubResponse, HotspotHubStatisticsRequest, 
    HotspotHubStatisticsResponse, 
    MarketQuoteResponse, 
    NftRequest, 
    OnboardingResponse, 
    SyncDeviceRequest, 
    WalletOverviewRequest, WalletOverviewResponse } from "./explorerTypes";

const explorer = explorerSlice.injectEndpoints({
    endpoints: (build) => ({

        //Connect user to the explorer
        getWalletOverview: build.query<WalletOverviewResponse, WalletOverviewRequest>({
            query: ({ address, period, lookbackperiodIndays }) => `/myExplorer/walletOverview?walletAddress=${address}&lookbackPeriodInHours=${period}&chartLookbackPeriodInDays=${lookbackperiodIndays}`,
            providesTags: ['Explorer']
        }),

         //Connect wallet to explorer
         connectWalletToExplorer: build.mutation<any, ConnectWalletExplorerRequest>({
            query: (data) => ({
                url: `/Auth/connect`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["Explorer"]
        }),

        //Get transactions for a particular hub
        getTransactions: build.query<ExplorerTransactionResponse, ExplorerTransactionsRequest>({
            query: ({tokenId, page, pageSize}) => `/myExplorer/Transactions?tokenId=${tokenId}&pageNumber=${page}&pageSize=${pageSize}`,
            providesTags: ['Explorer']
        }),

        //Get transactions for a particular  hub
        getNFTs: build.query<HotspotHubResponse, NftRequest>({
            query: ({ page, pageSize}) => `/DeviceNft/MyNftDevices?pageNumber=${page}&pageSize=${pageSize}`,
            providesTags: ['Explorer']
        }),

        //Get transactions for a particular session
        getTransactionDetail: build.query<ExplorerTransactionDetail, string>({
            query: (transactionId) => `/Block/TransactionMetadata?transactionId=${transactionId}`,
            providesTags: ['Explorer']
        }),


        //Claim reward
        claimRewards: build.mutation<any, Partial<ClaimRewardRequest>>({
            query: (payload) => ({
                url: `/Reward/claimreward`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["Wallet", "Explorer"]
        }),

        //Claim reward history
        getClaimHistory: build.query<ClaimHistoryResponse, ClaimHistoryRequest>({
            query: ({ walletAddress, walletName, paymentStatus, duration, pageNumber, pageSize }) => `/Reward/payouthistory?walletAddress=${walletAddress}&walletName=${walletName}&paymentStatus=${paymentStatus}&duration=${duration}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            providesTags: ['Explorer']
        }),

        //Get Hotspot hub
        getHotspothub: build.query<HotspotHubResponse, HotspotHubRequest>({
            query: ({ deviceName, syncStatus, page, pageSize }) => `/myExplorer/devices?deviceName=${deviceName}&synced=${syncStatus}&pageNumber=${page}&pageSize=${pageSize}`,
            providesTags: ['Explorer']
        }),

        //Get Hotspot hub statistics
        getHotspothubstatistics: build.query<HotspotHubStatisticsResponse, HotspotHubStatisticsRequest>({
            query: ({tokenId, lookbackPeriodInHours, chartLookbackPeriodInDays  }) => `/myExplorer/DeviceStatistics?tokenId=${tokenId}&lookbackPeriodInHours=${lookbackPeriodInHours}&chartLookbackPeriodInDays=${chartLookbackPeriodInDays}`,
            providesTags: ['Explorer']
        }),

        //Check if user has seen the onboarding before 
        showUserOnboarding: build.query<OnboardingResponse, void>({
            query: () => `/myExplorer/introduction`,
        }),

         //Get Holder Id
        getHolderId: build.query<HolderResponse, void>({
            query: () => `/myExplorer/holder`,
            providesTags: ['Explorer']
        }),

        //Sync Device
        syncDevice: build.mutation<any, Partial< SyncDeviceRequest>>({
            query: ({deviceId, tokenId}) => ({
                url: `/myExplorer/syncdevice/${deviceId}/?tokenId=${tokenId}`,
                method: 'PUT',
            }),
            invalidatesTags: ["Explorer"]
        }),

        //UnSync Device
        unsyncDevice: build.mutation<any, Partial<number>>({
            query: (tokenId) => ({
                url: `/myExplorer/unsyncdevice?tokenId=${tokenId}`,
                method: 'PUT',
            }),
            invalidatesTags: ["Explorer"]
        }),

         //Devices to Sync
        getDevicesToSync: build.query<GetDevicesToSyncResponse, GetDevicesToSyncRequest>({
            query: ({tokenId, searchParam, pageNumber, pageSize}) => `/myExplorer/devices/unsynced/${tokenId}?searchParam=${searchParam}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
            providesTags: ['Explorer']
        }),

        //Get Market Quote
        getMarketQuote: build.query<MarketQuoteResponse, void>({
            query: () => `https://api-calculator.wicrypt.com/api/MarketQuote`,
        }),
        

    }),
    overrideExisting: true,
})

export const { useShowUserOnboardingQuery, useGetNFTsQuery, useConnectWalletToExplorerMutation,
     useGetWalletOverviewQuery, useGetClaimHistoryQuery, useLazyGetHolderIdQuery, useGetTransactionDetailQuery,
    useGetHotspothubQuery, useClaimRewardsMutation, useGetDevicesToSyncQuery, useGetMarketQuoteQuery,
    useGetHotspothubstatisticsQuery, useGetTransactionsQuery, useSyncDeviceMutation, useUnsyncDeviceMutation } = explorer