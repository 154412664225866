import { useState } from "react";
import { Button } from "../reusables";
import { Modal } from "../reusables/Modal";
import { AddNewCaptionProps, RequestInputProps, RequestWithdrawalProps } from "./types";
import { SideModal } from "../reusables/ModalList";
import { useTranslation } from "react-i18next";

// Modal for creating the name of a form
export const AddNewCaption = ({ handleModal, handleClick, isLoading}: AddNewCaptionProps) => {
  const {t} = useTranslation();
  const [captionText, setCaptionText] = useState<string>("");

  return (
    <Modal
      close={true}
      title={t("addNewCaption")}
      handleCloseModal={handleModal}
    >
      <>
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5 mb-4">
            <label
              htmlFor="captionText"
              className="block pb-1 font-medium text-clrGray dark:text-white text-x10 md:text-xs"
            >
              {t("captionSentence")}
            </label>

            <input
              type="text"
              name="captionText"
              placeholder={t("captionSentencePlaceholder")}
              value={captionText}
              // onChange={(e) => setCaptionText(e.target.value)}
              onChange={(e) => {
                const wordLimit = 20;
                const words = e.target.value.split(/\s+/);
                if (words.length <= wordLimit) {
                  setCaptionText(e.target.value);
                }
              }}
              className="w-full pb-1 font-normal border-b border-solid placeholder:text-x11 text-x10 text-clrPlaceholder border-clrBorder5 focus:border-primary dark:focus:border-secondary dark:border-clrGray focus:outline-none dark:bg-transparent"
            />
            <p className="text-x8 font-normal text-clrGray mt-4">{captionText.split(/\s+/).length || 0}{t("numberOfWords20")}</p>
        </div>

        <div className="w-4/5 mx-auto mt-6">
          <Button
            text={t("save")}
            type="button"
            disabled={captionText ? false : true}
            handleClick={() => handleClick({caption: captionText, is_active: true})}
            loading={isLoading}
          />
        </div>
      </>
    </Modal>
  )
};
  
const RequestInput = ({ label, value, inputType, name, onChange, placeholder, error, onBlur }: RequestInputProps) => (
  <div className="space-y-4">
    <label htmlFor={name} className="text-xs font-semibold text-black dark:text-white">{label}</label>
    <input 
        type={inputType} 
        name={name} 
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="w-full pb-1 font-normal border-b border-solid text-clrGray placeholder:text-x11 text-x11 dark:text-clrPlaceholder border-clrBorder5 focus:border-primary dark:border-clrGray focus:outline-none dark:bg-transparent"
    />
    {error && <p className="text-xs text-red-500">{error}</p>}
  </div>
);

interface FormData {
  accountNumber: string;
  accountName: string;
  phoneNumber: string;
}

interface Errors {
  accountNumber: string;
  accountName: string;
  phoneNumber: string;
}

interface Touched {
  accountNumber: boolean;
  accountName: boolean;
  phoneNumber: boolean;
}


export const RequestWithdrawal = ({ handleModal }: RequestWithdrawalProps) => {
  const {t} = useTranslation();

  const labelMap = {
    accountNumber: t('MoMoAccountNumber'),
    accountName: t('accountName'),
    phoneNumber: t('phoneNumber'),
  };

  const [formData, setFormData] = useState<FormData>({
    accountNumber: '',
    accountName: '',
    phoneNumber: '',
  });
  const [errors, setErrors] = useState<Errors>({
    accountNumber: '',
    accountName: '',
    phoneNumber: '',
  });
  const [touched, setTouched] = useState<Touched>({
    accountNumber: false,
    accountName: false,
    phoneNumber: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name: keyof FormData, value: string): string => {
    switch (name) {
      case 'accountNumber':
        if (!value) return t('errors.accountNumber.required');
        if (!/^\d{10,}$/.test(value)) return t('errors.accountNumber.invalid');
        break;
      case 'accountName':
        if (!value) return t('errors.accountName.required');
        if (value.length < 3) return t('errors.accountName.tooShort');
        if (!/^[A-Za-z\s]+$/.test(value)) return t('errors.accountName.invalid');
        break;
      case 'phoneNumber':
        if (!value) return t('errors.phoneNumber.required');
        if (!/^\d{11,}$/.test(value)) return t('errors.phoneNumber.invalid');
        break;
      default:
        break;
    }
    return '';
  };

  const validateForm = (newFormData: FormData) => {
    const newErrors = {
      accountNumber: validateField('accountNumber', newFormData.accountNumber),
      accountName: validateField('accountName', newFormData.accountName),
      phoneNumber: validateField('phoneNumber', newFormData.phoneNumber),
    };

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every(error => error === '');
    setIsFormValid(isValid);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);

    // Validate the entire form whenever an input changes
    validateForm(newFormData);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setTouched(prev => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof FormData, formData[name as keyof FormData]);
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const constructEmailContent = (formData: FormData) => {
    const subject = `New Withdrawal Request: ${formData.accountName}`;
    const body = `
                  Account Name: ${formData.accountName}
                  MoMo Account Number: ${formData.accountNumber}
                  Phone Number: ${formData.phoneNumber}
                      `;
    return { subject, body };
  };

  const handleSubmit = () => {
    const newTouched = {
      accountNumber: true,
      accountName: true,
      phoneNumber: true,
    };
    setTouched(newTouched);

    validateForm(formData);

    if (!isFormValid) return;
    setIsLoading(true);

    const { subject, body } = constructEmailContent(formData);
    const mailtoLink = `mailto:hello@wicrypt.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;

    setIsLoading(false);
    handleModal();
  };

  return (
    <SideModal
      goBack={true}
      close={true}
      handleCloseModal={handleModal}
      title={t("requestWithdrawal")}
    >
      <>
        <p className="text-xs font-normal text-clrGray mb-4 mt-6">{t("requestDetails")}</p>
        <div className="space-y-5">
          {(Object.keys(formData) as Array<keyof FormData>).map((field) => (
            <RequestInput
              key={field}
              label={labelMap[field]}
              placeholder={field === 'accountNumber' ? '1234567890' : field === 'phoneNumber' ? '08030000000' : 'Alexander Okafor'}
              name={field}
              inputType={field === 'accountNumber' || field === 'phoneNumber' ? 'number' : 'text'}
              onChange={handleInputChange}
              onBlur={handleBlur}
              value={formData[field]}
              error={touched[field] ? errors[field] : ''}
            />
          ))}
        </div>
        <div className="flex flex-col justify-start gap-3 mt-auto md:w-[30%]">
          <Button
            type="button"
            text={t("request")}
            handleClick={handleSubmit}
            fontWeight="font-normal"
            disabled={!isFormValid || isLoading}
            loading={isLoading}
          />
        </div>
      </>
    </SideModal>
  );
};

