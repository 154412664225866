import { useAppDispatch, useAppSelector } from "../../app/hooks";
import MetaMask from "../../assets/icons/wallet/meta-mask.svg";
// import WalletConnectIcon from "../../assets/icons/wallet/wallet-connect.svg";
import { ButtonOutlineWallet, ErrorMsg } from "../reusables";
import React, { useState } from "react";
import { saveWalletDetails } from "../../features/store/authReducerSlice";
import { ethers } from "ethers";

// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import { arbitrum, mainnet, polygon } from 'wagmi/chains';
// import { useWeb3Modal } from '@web3modal/react';
// import { useAccount, useSignMessage } from 'wagmi'
import { Modal } from "../reusables/Modal";
import { useConnectWalletToExplorerMutation } from "../../features/api/explorerSlice";
import { useTranslation } from "react-i18next";

// const chains = [arbitrum, mainnet, polygon]
// const projectId = '0f437a57cff0a8799fcebefde3f71e28'

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient
// })
// const ethereumClient = new EthereumClient(wagmiConfig, chains)

interface CustomWindow extends Window {
  ethereum?: any;
}

export const ExplorerChooseWallet: React.FC<ExplorerProps> = ({
  handleCloseModal,
}) => {
  const {t} = useTranslation();
  const [agreed, setAgreed] = useState<boolean>(false);
  const [errorMsg, setError] = useState<string>("");
  const { connectWalletDetails } = useAppSelector((state) => state.auth);

  return (
    <Modal
      close={true}
      title={t("connectWallet")}
      description={t("connectWalletDetails")}
      goBack={false}
      handleCloseModal={handleCloseModal}
    >
      <>
        <div
          className={`space-y-2 ${
            agreed || connectWalletDetails?.walletAddress
              ? ""
              : "opacity-50 pointer-events-none"
          }`}
        >
          <MetamaskWallet
            active={connectWalletDetails?.walletName === "Metamask"}
            handleCloseModal={handleCloseModal}
            setError={setError}
          />

          {/* <WagmiConfig config={wagmiConfig}>
            <WalletConnect active={connectWalletDetails?.walletName === "WalletConnect"} handleCloseModal={handleCloseModal}  setError={setError}/>
          </WagmiConfig> */}
          {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
        </div>
        <ErrorMsg errMsg={errorMsg} />
        {/* handle when to accept terms and conditions */}
        {!connectWalletDetails?.walletAddress && (
          <div className="mt-3">
            <p className="font-normal text-center text-x8 text-clrTextGray dark:text-clrPlaceholder">
              {t("readAgree")}{" "}
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-clrYellow2"
              >
                ({t("userAgreement")})
              </a>
            </p>
            <div className="flex items-center mt-2 radio-input">
              <input
                type="radio"
                name=""
                id=""
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
              />
              <div className="circle !-left-[0.8em]"></div>
              <label className="font-normal text-x8 text-clrTextGray dark:text-clrPlaceholder">
                {t("readAndAgree")}
              </label>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

const MetamaskWallet = ({
  active,
  handleCloseModal,
  setError,
}: {
  active: boolean;
  handleCloseModal: () => void;
  setError: (err: string) => void;
}) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [connectWalletToExplorer] = useConnectWalletToExplorerMutation();
  const { userId } = useAppSelector((state) => state.auth);

  //Get all a user's wallet address
  async function requestAccount() {
    //check if metamask exists
    if ((window as CustomWindow).ethereum) {
      try {
        const accounts = await (window as CustomWindow).ethereum.request({
          method: "eth_requestAccounts",
        });
        return accounts[0];
      } catch (error) {
        console.error(error);
        setError(t("errorMessage"));
      }
    } else {
      setError(t("instructionToInstallMetamask"));
    }
  }

  //Switch or add a network to the preferred Chain
  async function switchOrAddNetworkToMetamask(chainId: number) {
    const hexChainId = `0x${chainId.toString(16)}`;
    try {
      if ((window as CustomWindow).ethereum) {
        // if not in the selected network, switch to the selected network
        await (window as CustomWindow).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: hexChainId }],
          rpcUrls: [process.env.REACT_APP_POLYGON_RPC_URL],
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
        });
      } else {
        console.log("Please install a metamask wallet on your browser");
      }
    } catch (e: any) {
      //if we need to add a new network
      if (e.code === 4902) {
        let params = {};

        // add polygon mainnet
        if (chainId === 137) {
          params = {
            chainId: hexChainId,
            chainName: "Polygon Mainnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "matic",
              decimals: 18,
            },

            rpcUrls: [process.env.REACT_APP_POLYGON_RPC_URL],
            blockExplorerUrls: ["https://explorer.matic.network/"],
          };
        }
        // add polygon testnet
        if (chainId === 80001) {
          params = {
            chainId: hexChainId,
            chainName: "Polygon Testnet",
            nativeCurrency: {
              name: "MATIC",
              symbol: "matic",
              decimals: 18,
            },
            rpcUrls: process.env.REACT_APP_POLYGON_RPC_URL,
            blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
          };
        }

        try {
          // now that the network is added, try to run this again
          await (window as CustomWindow).ethereum.request({
            method: "wallet_addEthereumChain",
            params: [params],
          });
        } catch (e) {
          //setError("Something went wrong")
          console.warn(e);
        }
      }
    }
  }

  async function connectWallet() {
    //check if metamask exists
    if (typeof (window as CustomWindow).ethereum !== undefined) {
      let account = await requestAccount();
      try {
        const provider = new ethers.BrowserProvider(
          (window as CustomWindow).ethereum
        );
        await switchOrAddNetworkToMetamask(137);
        let signer = await provider.getSigner();
        //Sign the message to grant permission
        let signature = await signer.signMessage(
          process.env.REACT_APP_SIGNATURE_MESSAGE as string
        );
        let data = {
          walletAddress: account,
          walletName: "Metamask",
          walletSignature: signature,
        };
        
        let explorerData = {
          userId: userId,
          walletAddress: account,
          message:  process.env.REACT_APP_SIGNATURE_MESSAGE as string,
          signedMessageSignature: signature
        }
        connectWalletToExplorer(explorerData)
        .unwrap()
        .then((res)=>{
          if(res){
            dispatch(saveWalletDetails(data));
            handleCloseModal();
          }else{
            setError(t("errorMessage"))
          }
        })
        .catch((err)=>{
          setError(err?.data.message || t("errorMessage"))
        })

       
      } catch (error: any) {
        //setError(error.message)
      }
    } else {
      setError(t("instructionToInstallMetamask"));
    }
  }

  const handleWallet = () => {
    if (active) {
      let data = {
        walletAddress: "",
        walletName: "",
        walletSignature: "",
      };
      dispatch(saveWalletDetails(data));
      handleCloseModal();
    } else {
      connectWallet();
    }
  };

  return (
    <button
      onClick={handleWallet}
      className="flex gap-4 items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded focus:border-[1px] focus:border-solid focus:border-clrYellow"
    >
      <div className="w-8 h-8 p-1 flex items-center justify-center rounded-md bg-[#d3d3d3]">
        <img src={MetaMask} alt={t("metaMask")} width={20} height={20} />
      </div>
      <h3 className="mr-auto font-semibold text-left text-black dark:text-white text-x10">
        {t("metaMask")}
      </h3>
      {active && (
        <ButtonOutlineWallet
          handleClick={() => console.log("disconnect")}
          text={t("disconnet")}
          type="button"
          bgColor="bg-clrYellow2"
          textColor="text-white"
        />
      )}
    </button>
  );
};

// const WalletConnect = ({active, handleCloseModal, setError}: {active: boolean, handleCloseModal: ()=> void, setError: (err: string)=> void }) => {
//   const dispatch = useAppDispatch();
//   const { open, close,  setDefaultChain} = useWeb3Modal();
//   const { address, isConnected } = useAccount();
//   const { data, signMessage, variables } = useSignMessage()

//   const handleWalletConnect = () =>{
//     if(isConnected){
//       open();
//       let data = {
//         walletAddress: "",
//         walletName: "",
//         walletSignature: ""
//       }
//       dispatch(saveWalletDetails(data));
//      handleCloseModal();
//     }else{
//       console.log("we re here")
//       open();
//       setDefaultChain(137);
//       signMessage({message: process.env.REACT_APP_SIGNATURE_MESSAGE as string})
//       handleCloseModal();
//     }
//   }

//   useEffect(()=>{
//     let connectionData = {
//       walletAddress: address,
//       walletName: "WalletConnect",
//       walletSignature: ""
//     }
//     dispatch(saveWalletDetails(connectionData));

//   }, [address])

//   return (
//     <button
//       onClick={handleWalletConnect}
//       className="flex gap-4 items-center justify-between w-full p-2.5 md:p-4 bg-white rounded focus:border-[1px] focus:border-solid focus:border-clrYellow"
//     >
//       <div className="w-8 h-8 p-1 flex items-center justify-center rounded-md bg-[#d3d3d3]">
//         <img
//           src={WalletConnectIcon}
//           alt="walletconnect"
//           width={30}
//           height={30}
//         />
//       </div>
//       <h3 className="mr-auto font-semibold text-left text-black text-x10">
//         WalletConnect
//       </h3>
//       {isConnected &&
//       <ButtonOutlineWallet
//         handleClick={() => console.log("disconnect")}
//         text="Disconnet"
//         type="button"
//         bgColor="bg-clrYellow2"
//         textColor="text-white"
//       />}
//     </button>
//   )
// }
