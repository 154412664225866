import { useTranslation } from "react-i18next";
import SecondaryNav from "../reusables/SecondaryNav";

const HotspotHubs = () => {
  const { t } = useTranslation();
  return (
    <SecondaryNav
      paths={[
        { path: "/home/hotspot-hubs/my-hubs", title: t("hubs") },
        { path: "/home/hotspot-hubs/live-session", title: t("liveSession") },
        { path: "/home/hotspot-hubs/hubs-report", title: t("hubsReport") },
      ]}
    />
  );
};

export default HotspotHubs;
