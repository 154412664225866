import { RefObject, useState } from "react";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { useTranslation } from "react-i18next";
import { ChooseModal } from "../reusables/ModalList";
import { ReactComponent as Delete2 } from "../../assets/delete2.svg";

export interface TVAccountsProps {
    modalRef: RefObject<HTMLElement>;
    handleCloseModal: ()=>void;
    id?: string;
    handleAddTVAccount: ()=>void;
  }

export const TVAccount = ({ modalRef, handleCloseModal, handleAddTVAccount }: TVAccountsProps) => {
    const {t} = useTranslation();
    const [isSubscriptionCancelled, toggleSubscription] = useState(false);
    
    // TV Accounts array with active state
    const [tvAccounts, setTvAccounts] = useState([
        { 
            id: 1, 
            email: "Alex@gmail.com", 
            country: "Nigeria", 
            phone: "+2348189031845", 
            isActive: true 
        },
        { 
            id: 2, 
            email: "Jane@gmail.com", 
            country: "Nigeria", 
            phone: "+2348189031846", 
            isActive: false 
        }
    ]);

    // Select active account
    const selectActiveAccount = (id: number) => {
        setTvAccounts(tvAccounts.map(account => ({
            ...account, 
            isActive: account.id === id
        })));
    };

    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white dark:bg-clrDarkBg w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            {t("tvAccounts")}
                        </h3>
                    </div>
                    <div className="grid gap-[1.375rem]">
                        <p className="text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("tvAccountsDescription")}</p>
                        <button className="bg-transparent text-secondary text-xs font-normal text-left" onClick={handleAddTVAccount}>Add new account</button>
                        <div className="grid gap-5">
                            {tvAccounts.map((account) => (
                                <div 
                                    key={account.id} 
                                    className={`flex gap-6 border-solid border rounded-lg py-5 px-4 ${
                                        account.isActive 
                                            ? 'border-[#25346A99] dark:border-[#e5b91099]' 
                                            : 'border-[#6B6B6B33] dark:border-[#c4c4c433]'
                                    }`}
                                >
                                    <label className="flex items-start">
                                        <input 
                                            type="radio" 
                                            name="tvAccount"
                                            checked={account.isActive}
                                            onChange={() => selectActiveAccount(account.id)}
                                            className="hidden"
                                        />
                                        <span 
                                            className={`
                                                w-5 h-5 rounded-full border-2 flex items-center justify-center mr-4 
                                                ${account.isActive 
                                                    ? 'border-primary dark:border-secondary' 
                                                    : 'border-[#6B6B6B33]'}
                                            `}
                                        >
                                            {account.isActive && (
                                                <span className="w-2.5 h-2.5 bg-primary dark:bg-secondary rounded-full"></span>
                                            )}
                                        </span>
                                        <div className="grid gap-3">
                                            <h4 className="text-xs font-semibold text-black dark:text-white">{account.email}</h4>
                                            <p className="text-x11 font-medium text-clrGray dark:text-clrPlaceholder">{account.country}</p>
                                            <p className="text-x11 font-medium text-clrGray dark:text-clrPlaceholder">{account.phone}</p>
                                        </div>
                                    </label>
                                   {!account.isActive && (
                                        <button 
                                            className="ml-auto self-center" 
                                            onClick={() => {
                                                toggleSubscription(true);
                                                
                                            }}
                                        >
                                            <DeleteIcon className="w-[1rem]" />
                                        </button>
                                    )}
                               </div>
                            ))}
                        </div>
                    </div>
                </div>
                {isSubscriptionCancelled && (
                    <ChooseModal
                        title={`Delete TV Account`}
                        description={`This will remove this account from the existing list`}
                        handleButton={() => {}}
                        icon={<Delete2 className='w-16 h-16' />}
                        handleModal={() => toggleSubscription(!isSubscriptionCancelled)}
                        isLoading={false}
                        buttonTitle="Delete"
                        btnColor="bg-[#DB5F5F]"
                        errMsg={""}
                        close={true}
                    />
                )}
        </div>
    );
};