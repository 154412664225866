import React, { useRef, useState } from "react";
import ArrowYellow from "../../assets/icons/arrow-down-Yellow.svg";
import { ReactComponent as Phone2 } from "../../assets/icons/phone.svg";
import BearIcon from "../../assets/icons/bear.svg";
import LogOutIcon from "../../assets/icons/logout-1.svg";
import WalletConnectIcon from "../../assets/icons/wallet/wallet-connect.svg";
import Profile from "../../assets/profile-pic.svg";
import CustomerSupport from "../home/CustomerSupport";
import TopRightMenu from "../home/TopRightMenu";
import { ReactComponent as Menu2 } from "../../assets/icons/menu.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ButtonOutlineWallet } from "../reusables";
import { ExplorerChooseWallet } from "../explorer/ChooseWallet";
import { useAppSelector } from "../../app/hooks";
import { useGetHotspothubstatisticsQuery } from "../../features/api/explorerSlice";
import RouterLogin from "../routerLogin";
import { useTranslation } from "react-i18next";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";

interface NotifyProps {
  handleMenu: () => void;
}

const Notify: React.FC<NotifyProps> = ({ handleMenu }) => {
  const [showSupport, setShowSupport] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const { t } = useTranslation();

  // const { agentName, businessUserId, businessId, sessionId, cycleId, tokenId } =
  //   useParams();

    const {
      agentName,
      businessUserId,
      businessId,
      sessionId,
      tokenId,
      planId,
      billingType,
      deviceName,
      deviceId,
      formId,
      formName,
      analyticName,
      analyticId,
      category,
      categoryId,
      trafficId,
      trafficCategory,
      connectedUser,
      connectedUserId,
      cycleId
    } = useParams();

  // Getting subscription type
  // const [packageDetail, setPackageDetail] = useState<PackageDetail>();
  // const [trigger] = useLazyGetPackageQuery();
  // useEffect(() => {
  //   if (planId) {
  //     trigger(planId)
  //       .unwrap()
  //       .then((res) => {
  //         setPackageDetail(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }, [planId, trigger])

  const { connectWalletDetails, appModal } = useAppSelector(
    (state) => state.auth
  );

  const modalRef = useRef<HTMLElement>(null);
  const modalRefProfile = useRef<HTMLElement>(null);

  const location = useLocation();

  const handleCloseModal = () => {
    setShowSupport(false);
    setShowProfile(false);
  };
  const handleProfileClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProfile((prev) => !prev);
  };

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRefProfile, handleCloseModal);

  //Get the hotspothub statistics
  const { data: hubDetail } = useGetHotspothubstatisticsQuery({
    tokenId: tokenId as string,
    lookbackPeriodInHours: "",
    chartLookbackPeriodInDays: "",
  });

  const titleMap: { [key: string]: string } = {
    "/home/dashboard": "Home",
    "/home/dashboard/business-reg": "Business Registration",
    "/home/hotspot-hubs": "hubs",
    "/home/wallet": "wallet",
    "/home/hotspot-hubs/my-hubs": "hubs",
    "/home/hotspot-hubs/live-session": "hubs",
    "/home/hotspot-hubs/hubs-report": "hubs",
    "/home/hotspot-hubs/session-history": "Session History",
    "/home/wallet/transactionHistory": "transactionHistory",
    "/home/wallet/peer-to-peer": "Peer to Peer(P2P)",
    "/home/wallet/withdraw-P2P": "Peer to Peer(P2P)",
    "/home/wallet/crypto-withdraw": "Crypto Withdrawal",
    "/home/wallet/add-bank-details": "Add Bank Details",
    "/home/wallet/stake": "stake",
    "/home/wallet/explorer": "explorer",
    "/home/explorer/overview": "explorer",
    "/home/explorer/explorer-devices": "explorer",
    "/home/explorer/claim-history": "explorer",
    "/home/explorer/hotspot-hubs": "explorer",
    "/home/booster/overview": "booster",
    "/home/booster/history": "booster",
    "/home/booster/booster-faq": "Booster FAQ’S",
    "/home/booster/history/:cycleId": "Reward History",
    "/home/explorer/devices-name/:tokenId/statistics": "hubName",
    "/home/explorer/devices-name/:tokenId/transactions": "hubName",
    "/home/profile/info": "profile",
    "/home/profile/verifyID": "Verify Identity",
    "/home/token": "token",
    "/home/vouchers": "Voucher",
    "/home/agents": "agents",
    "/home/referrals": "referrals",
    "/home/parental-control": "parentalControl",
    "/home/subscription": "subscription",
    "/home/hub-form": "hubForm",
    "/home/analytics": "analytics",
    "home/cluster": "cluster",
    "/home/advert-control/overview": "Advert Control",
    "/home/advert-control/share": "Advert Control",
    "/home/advert-control/caption": "Advert Control",
    "/home/cluster": "My cluster",
    "/home/profile/edit-contact": "Edit Contact Number",
    "/home/profile/security/2fa": "2 Factor Authenticator",
    "/home/profile/security/2fa-code": "2 Factor Authenticator",
    "/home/profile/security/reset-password": "Login Pin",
    "/home/profile/security": "Profile",
    "/home/profile/settings": "Profile",
    "/home/parental-control/:deviceId/:deviceName": "Device Name",
    "/home/parental-control/:deviceId/:deviceName/:category/:categoryId": "Category",
    "/home/hub-form/form-content/:formName/:?formId": "Network Feedback Form",
    "/home/hub-form/detail/:formId": "Hub Form",
    "/home/hub-settings": "Network Feedback Form",
    "/home/hub-form/form-template": "Hub Templates",
  };

  const defaultTitle = "Dashboard";
  //const title = titleMap[location.pathname] || defaultTitle;

  // Regular expression to match dynamic route parameters
  const agentRoutePattern = /^\/home\/agents\/[\w-/%]+$/;
  const sessionRoutePattern = /^\/home\/explorer\/session-name\/[\w-/%]+$/;
  const boostRoutePattern = /^\/home\/booster\/history\/[\w-/%]+$/;
  const statisticsRoutePattern =
    /^\/home\/explorer\/devices-name\/[\w-/%]+\/(?:statistics|transactions)$/;
  const analyticRoutePattern = /^\/home\/analytics\/[\w-/%]+$/;
  // const subscriptionRoutePattern = /^\/home\/subscription\/[\w-/%]+\/[\w-/%]+$/;
  const categoryRoutePattern = /^\/home\/parental-control\/[\w-/%]+\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+$/;
  const trafficCategoryRoutePattern = /^\/home\/analytics\/traffic\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+$/;
  const hubFormDetailRoutePattern = /^\/home\/hub-form\/detail\/[\w-.%]+$/;
  const hubFormResponseRoutePattern = /^\/home\/hub-form\/detail\/responses\/[\w-.%]+$/;
  const connectionSessionRoutePattern = /^\/home\/analytics\/connections\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+\/[\w-/%]+$/;

  // Function to get the title based on the route
  const getTitleFromRoute = (route: string): string => {
    if (titleMap[route]) {
      return t(titleMap[route]);
    } else if (agentRoutePattern.test(route)) {
      return agentName as string; // Or any other dynamic title you want for the matched pattern
    } else if (sessionRoutePattern.test(route)) {
      return sessionId?.slice(0, 4) + "..." + sessionId?.slice(-4);
    } else if (boostRoutePattern.test(route)) {
      return "Boost Reward";
    } else if (statisticsRoutePattern.test(route)) {
      return hubDetail?.data?.deviceName || "Hub Name";
    } else if (trafficCategoryRoutePattern.test(route)) {
      return trafficCategory as string;
    } else if (hubFormDetailRoutePattern.test(route) || hubFormResponseRoutePattern.test(route)) {
      return "Hub Form";
    } else if (connectionSessionRoutePattern.test(route)) {
      return connectedUser as string;
    } else if (analyticRoutePattern.test(route)) {
      // let analyticName = new URLSearchParams(window.location.search).get(
      //   'analyticName'
      // );
      return analyticName as string;
    } else if (categoryRoutePattern.test(route)) {
      return category ? category : "Category";
    } else if (deviceId && deviceName) {
      return deviceName;
    } else if (formName) {
      return formName;
    } 
    // else if (subscriptionRoutePattern.test(route)) {
    //   return packageDetail?.name ? `${packageDetail?.name} Plan` : "Subscription Plan";
    // }
    return defaultTitle;
  };

  let title = getTitleFromRoute(location.pathname);

  // const isSpecificPath =
  //   location.pathname === "/home/hotspot-hubs/session-history" ||
  //   location.pathname === "/home/wallet/transactionHistory" ||
  //   location.pathname === "/home/wallet/peer-to-peer" ||
  //   location.pathname === "/home/wallet/withdraw-P2P" ||
  //   location.pathname === "/home/wallet/crypto-withdraw" ||
  //   location.pathname === "/home/wallet/add-bank-details" ||
  //   location.pathname === "/home/wallet/stake" ||
  //   decodeURI(location.pathname) ===
  //     `/home/explorer/session-name/${sessionId}` ||
  //   decodeURI(location.pathname) === `/home/booster/history/${cycleId}` ||
  //   decodeURI(location.pathname) ===
  //     `/home/explorer/devices-name/${tokenId}/statistics` ||
  //   decodeURI(location.pathname) ===
  //     `/home/explorer/devices-name/${tokenId}/transactions` ||
  //   location.pathname === "/home/booster/booster-faq" ||
  //   location.pathname === "/home/profile/verifyID" ||
  //   location.pathname === "/home/dashboard/business-reg" ||
  //   decodeURI(location.pathname) ===
  //     `/home/agents/${businessUserId}/${agentName}/${businessId}`;
  

  const isSpecificPath =
    location.pathname === '/home/hotspot-hubs/session-history' ||
    location.pathname === '/home/wallet/transactionHistory' ||
    location.pathname === '/home/wallet/peer-to-peer' ||
    location.pathname === '/home/wallet/withdraw-P2P' ||
    location.pathname === '/home/wallet/crypto-withdraw' ||
    location.pathname === '/home/wallet/add-bank-details' ||
    location.pathname === '/home/wallet/bank-transfer' ||
    location.pathname === '/home/wallet/ussd' ||
    location.pathname === '/home/wallet/withdrawal-bank-transfer' ||
    location.pathname === '/home/wallet/user' ||
    location.pathname === '/home/wallet/stake' ||
    location.pathname === '/home/hotspot-hubs/session-history' ||
    location.pathname === '/home/wallet/transactionHistory' ||
    location.pathname === '/home/wallet/peer-to-peer' ||
    location.pathname === '/home/wallet/add-bank-details' ||
    decodeURI(location.pathname) ===
      `/home/explorer/session-name/${sessionId}` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/statistics` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/transactions` ||
    location.pathname === '/home/booster/booster-faq' ||
    location.pathname === '/home/profile/verifyID' ||
    location.pathname === '/home/profile/edit-contact' ||
    location.pathname === '/home/profile/security/2fa-code' ||
    location.pathname === '/home/profile/security/2fa' ||
    location.pathname === '/home/profile/security/reset-password' ||
    location.pathname === '/home/dashboard/business-reg' ||
    decodeURI(location.pathname) ===
      `/home/agents/${businessUserId}/${agentName}/${businessId}` ||
    decodeURI(location.pathname) === `/home/analytics/connections` ||
    location.pathname === '/home/subscription-plan' ||
    location.pathname === `/home/hub-form/detail/${formId}` ||
    location.pathname === `/home/hub-form/detail/responses/${formId}` ||
    location.pathname === '/home/hub-setting' ||
    location.pathname === '/home/profile/verifyID' ||
    location.pathname === '/home/dashboard/business-reg' ||
    decodeURI(location.pathname) ===
      `/home/agents/${businessUserId}/${agentName}/${businessId}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/connections/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/uptime/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/data-usage/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/speed/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/traffic/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/traffic/${trafficId}/${analyticName}/${trafficCategory}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/connections/${analyticId}/${analyticName}/${connectedUserId}/${connectedUser}` ||
    location.pathname === `/home/subscription/${planId}/${billingType}` ||
    location.pathname === `/home/hub-form/detail/${formId}` ||
    decodeURI(location.pathname) === `/home/hub-form/form-content/${formName}` ||
    decodeURI(location.pathname) === `/home/hub-form/form-content/${formName}/${formId}` ||
    location.pathname === `/home/hub-form/detail/responses/${formId}` ||
    location.pathname === `/home/parental-control/${deviceId}/${deviceName}` ||
    decodeURI(location.pathname) === `/home/parental-control/${deviceId}/${deviceName}/${category}/${categoryId}` ||
    location.pathname === '/home/hub-setting' ||
    location.pathname === '/home/hub-form/form-template' ||
    location.pathname === `/home/booster/history/${cycleId}` ||
    location.pathname === '/home/dashboard/hub-setup';

  const navigate = useNavigate();

  // to open the modal in explorer screen
  const [chooseWallet, setChooseWallet] = useState(false);

  const handleModal = () => {
    setChooseWallet(false);
  };

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();

  return (
    <div className="flex items-center p-5 bg-white dark:bg-clrDarkBg md:px-10 md:py-6">
      <div className="flex gap-4 mr-auto">
        <button onClick={handleMenu} className="block lg:hidden">
          <Menu2 className="dark-menu" />
        </button>
        <div className="flex items-center gap-2">
          {isSpecificPath && (
            <button className="btn-reset" onClick={() => navigate(-1)}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}
          <h1 className="text-sm font-semibold md:text-[1.125rem] md:leading-[1.69rem] text-clrTitle dark:text-white">
            {title}
          </h1>
        </div>
      </div>
      <div className="items-center hidden gap-4 md:flex">
        {location.pathname.includes("explorer") && (
          <>
            {!connectWalletDetails?.walletAddress ? ( // Show ButtonOutlineWallet if choice is not made
              <ButtonOutlineWallet
                handleClick={() => setChooseWallet(true)}
                text="Connect Wallet"
                type="button"
              />
            ) : (
              // Show custom button if choice is made
              <button
                onClick={() => setChooseWallet(true)}
                className="flex gap-2 justify-between items-center py-1.5 px-4 bg-clrYellow2 bg-opacity-10 rounded-[22px]"
              >
                <img
                  src={
                    connectWalletDetails?.walletName === "Metamask"
                      ? BearIcon
                      : WalletConnectIcon
                  }
                  alt="wallet-logo"
                  width={15}
                  height={15}
                />
                <p className="font-semibold text-x9 text-clrTextGray">
                  {connectWalletDetails?.walletAddress.slice(0, 4) +
                    "..." +
                    connectWalletDetails?.walletAddress.slice(-4)}
                </p>
                <img src={LogOutIcon} alt="copy now" width={12} height={12} />
              </button>
            )}
            {chooseWallet && (
              <ExplorerChooseWallet handleCloseModal={handleModal} />
            )}
          </>
        )}
        <div className="relative">
          <button
            className="flex items-center gap-2 px-2 py-1 rounded-lg h-fit bg-clrBtnGray dark:bg-clrDarkBg2"
            onClick={() => setShowSupport(!showSupport)}
          >
            {/* <img src={Phone} alt="Call" width={15} height={15} /> */}
            <Phone2 className="w-[0.9375rem] h-[0.9375rem] dark-phone" />
            <p className="text-[0.625rem] font-normal text-[#515151] dark:text-clrGray tracking-[0.01em]">
              Support
            </p>
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showSupport && <CustomerSupport modalRef={modalRef} />}
        </div>
        <img
          src={ businessProfile?.data?.profileUrl ? businessProfile?.data?.profileUrl : Profile}
          alt="Profile"
          width={40}
          height={40}
          className="w-10 rounded-full pic-aspect"
        />
        <div className="relative">
          <button
            onClick={handleProfileClick}
            className="mt-[5px]"
          >
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showProfile && <TopRightMenu modalRef={modalRefProfile} />}
        </div>
      </div>
      {appModal?.id === "routerCode" && <RouterLogin />}
    </div>
  );
};

export default Notify;
