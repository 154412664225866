import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useRef, useState } from "react";
import { DeviceList } from "../../features/api/clusterSliceTypes";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { Button } from "../reusables";
import { CellRenderer } from "../agents/data";
import { EditClusterModal } from "./clusterModals";
import { useTranslation } from "react-i18next";


// My Clusters Column
const clusterColumn = createColumnHelper<DeviceList>();

export const dataCluster = [{

  deviceListId: "string",
  listName: "string",
  deviceCount: "number",
  createdAtTimeStamp: "number",
  businessInfo: {
    id: "string",
    name: "string",
    userId: "string"
  }
},
{

  deviceListId: "string",
  listName: "string",
  deviceCount: "number",
  createdAtTimeStamp: "number",
  businessInfo: {
    id: "string",
    name: "string",
    userId: "string"
  }
}
]


export const useMyClusterColumn = () => {
  const {t} = useTranslation();

  const myCluster = [
    clusterColumn.accessor("createdAtTimeStamp", {
      header: t("createdAt"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format('Do MMM YYYY | h:mm a')}</p>;
      },
    }),
    clusterColumn.accessor("listName", {
      header: t("clusterName"),
    }),
    clusterColumn.accessor("deviceCount", {
      header: t("hubsHubs"),
    }),
    // clusterColumn.accessor("deviceListId", {
    //   header: "Data Used",
    // }),
    clusterColumn.accessor("deviceListId", {
      header: "",
      cell: (val) => {
        const id = val.getValue();
        return (
          <EditCluster id={id as string}  />
        );
      },
    }),
    clusterColumn.accessor("deviceListId", {
      header: "",
      cell: (info) => {
        return (<CellRenderer id={info?.row?.original?.deviceListId as string} name={info?.row?.original?.listName as string} />)
      },
    }),
  ];

  return myCluster;
}


export const EditCluster = ({ id }: { id: string }) => {

  const {t} = useTranslation();
  const [isEditDetails, setIsEditDetails] = useState(false);

  const modalRefMoreResponse = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsEditDetails(false);
  };

  useOutsideClick(modalRefMoreResponse, handleCloseModal);

  return (
    <>
      <Button
        text={t("editDetails")}
        type={"button"}
        handleClick={() => setIsEditDetails(!isEditDetails)}
        textSize={"text-[0.5rem]"}
        lineHeight={"leading-[0]"}
        wBtn="!w-fit"
      />
      {isEditDetails && (
        <EditClusterModal handleCloseModal={handleCloseModal} modalRef={modalRefMoreResponse} />
      )}
    </>
  );
}