import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ButtonOutlineWallet } from "../reusables";
// import ScanIcon from "../../assets/icons/wallet/scan.svg";
import PaperIcon from "../../assets/icons/wallet/paper-download.svg";
import ArrowRightGray from "../../assets/icons/wallet/arrow-right-gray.svg";
import SuccessIcon from "../../assets/icons/wallet/success.svg";
import TickGreen from "../../assets/icons/wallet/tick-green.svg";
import TickYellow from "../../assets/icons/wallet/tick-yellow.svg";
import { ReactComponent as SwitchSwap } from "../../assets/icons/wallet/switch-swap.svg";
import {
  ConfirmModalWithdraw,
  WithdrawEnterPin,
  WithdrawVerifyEmail,
} from "./WithdrawInfo";
import useOutsideClick from "../../Hooks/useOutsideClick";
import {
  useGetBalancesQuery,
  useLazyValidateAddressForCryptoWithdrawalQuery,
  useGetTransactionChargeQuery,
  useLazySendWithdrawalOTPQuery,
  useInitiateCryptoWithdrawalMutation,
} from "../../features/api/walletSlice";
import moment from "moment";
import { CryptoWithdrawalProps, DataPayload } from "./walletType";

let BALANCE_STATE = {
  symbol: "",
  name: "",
  iconUrlSvg: "",
  iconUrlPng: "",
  balance: 0,
  usdBalances: 0,
  currencyId: 0,
};

const CryptoWithdraw = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [pin, setPin] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  // const [isError, setError] = useState<string>("");
  const [withdrawalErr, setWithdrawalErr] = useState<string>("");
  const [otpError, setOTPError] = useState<string>("");

  //toggle confirm pin modal
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [withdrawalDetails, setWithdrawalDetails] = useState({
    walletAddress: "",
    amtToWithdraw: 0,
    selectedBalance: BALANCE_STATE,
  });

  const [isEnterPinOpen, setIsEnterPinOpen] = useState(false);
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);

  const [trigger] = useLazySendWithdrawalOTPQuery();
  const [initateWithdrawal, { data, isLoading: initiatigOrder }] =
    useInitiateCryptoWithdrawalMutation();

  const ConfirmModalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsConfirmOpen(false);
    setIsEnterPinOpen(false);
    setIsVerifyEmailOpen(false);
  };
  useOutsideClick(ConfirmModalRef, handleCloseModal);

  const handleChange = (res: string) => {
    setPin(res);
  };

  const handleOtp = (res: string) => {
    setOtp(res);
  };

  const handleNext = (data: DataPayload) => {
    setWithdrawalDetails(data);
    setIsConfirmOpen(true);
  };

  //If user confirms, send OTP
  const handleSendOTP = () => {
    if (pin.length === 4) {
      trigger({
        currencyId: withdrawalDetails?.selectedBalance.currencyId,
        channel: "SwitchWallet",
      });
      setIsEnterPinOpen(false);
      setIsVerifyEmailOpen(true);
    } else {
      setOTPError(t("pleaseEnterAValidPin"));
      setWithdrawalErr(t("pleaseEnterAValidPin"));
    }
  };

  //handle cryptowithdrawal
  const handleCryptoWithdrawal = () => {
    let data = {
      walletAddress: withdrawalDetails?.walletAddress,
      amount: withdrawalDetails?.amtToWithdraw,
      pin: pin,
      otp: otp,
    };
    if (otp.length !== 6) {
      setOTPError(t("pleaseEnterTheCodeThatWasSentToYou"));
    } else {
      initateWithdrawal({
        payload: data,
        currencyId: withdrawalDetails?.selectedBalance.currencyId,
      })
        .unwrap()
        .then((res) => {
          if (res.success) {
            setIsVerifyEmailOpen(false);
            setStep(2);
          }
        })
        .catch((error) => setWithdrawalErr(error.data.message));
    }
  };

  //Function to handle cryptowithdrawal step flor
  const renderStep = () => {
    switch (step) {
      case 1:
        return <CryptoWithdrawalAmt handleNext={handleNext} />;

      case 2:
        return <CryptoWithdrawSuccess data={data?.data} />;
      default:
        return;
    }
  };

  return (
    <>
      <section className="bg-clrBg dark:bg-clrDarkBg2 w-[91%] max-w-[36rem] mx-auto pb-6 overflow-y-auto">
        <div className="flex items-center justify-between px-8 my-3 md:my-5 md:w-[65%] mx-auto">
          <h3 className="text-x10 font-semibold text-[#1a1a1a] dark:text-white">
            {t("amount")}
          </h3>
          <img
            src={ArrowDown}
            alt={t("moreOptions")}
            width={20}
            height={20}
            className="-rotate-90"
          />
          <h3
            className={`font-medium text-x10 ${
              step > 1
                ? "text-[#1a1a1a] dark:text-white"
                : "text-clrGray dark:text-clrPlaceholder"
            }`}
          >
            {t("confirm")}
          </h3>
          <img
            src={step > 1 ? ArrowDown : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
          <h3
            className={`font-medium text-x10 ${
              step > 1
                ? "text-[#1a1a1a] dark:text-white"
                : "text-clrGray dark:text-clrPlaceholder"
            }`}
          >
            {t("success")}
          </h3>
        </div>
        {renderStep()}
      </section>
      {isConfirmOpen && (
        <ConfirmModalWithdraw
          modalRef={ConfirmModalRef}
          handleModal={handleCloseModal}
          withdrawalDetails={withdrawalDetails}
          handleOpenSuccess={() => {
            setIsConfirmOpen(false);
            setIsEnterPinOpen(true);
          }}
        />
      )}
      {isEnterPinOpen && (
        <WithdrawEnterPin
          handleModal={handleCloseModal}
          handleContinue={handleSendOTP}
          handleChange={handleChange}
          errMsg={otpError}
          resendOtp={function (): void {
            throw new Error("Function not implemented.");
          }}
          loading={false}
        />
      )}
      {isVerifyEmailOpen && (
        <WithdrawVerifyEmail
          handleModal={handleCloseModal}
          resendOtp={handleSendOTP}
          handleContinue={handleCryptoWithdrawal}
          handleChange={handleOtp}
          errMsg={withdrawalErr}
          loading={initiatigOrder}
        />
      )}
    </>
  );
};

export default CryptoWithdraw;

const CryptoWithdrawSuccess = ({
  data,
}: {
  data: CryptoWithdrawal | undefined;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="p-4 bg-white rounded dark:bg-clrDarkBg">
      <div className="flex flex-col items-center justify-center gap-4 px-3 pt-4 pb-6 bg-white rounded dark:bg-clrDarkBg ">
        <img src={SuccessIcon} alt={t("success")} width={60} height={60} />
        <div className="text-center">
          <h2 className="mb-4 text-sm font-bold text-clrGreen2">
            {t("requestSubmitted")}
          </h2>
          <p className="text-2xl font-bold text-black dark:text-white">
            {data?.amount} {data?.currencySymbol}
          </p>
        </div>
      </div>
      <div className="mb-4 space-y-2">
        <div className="flex items-center gap-4">
          <img src={TickGreen} width={20} height={20} alt="Successfull" />
          <div>
            <p className="text-x10 font-semibold text-[#525252] dark:text-clrPlaceholder">
              {t("withdrawalRequestSubmitted")}
            </p>
            <p className="font-medium text-x8 text-clrPlaceholder">
              {moment(data?.submittedAtTimeStamp).format("MMMM Do YYYY")}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <img src={TickGreen} width={20} height={20} alt="Successfull" />
          <div>
            <p className="text-x10 font-semibold text-[#525252] dark:text-clrPlaceholder">
              {t("systemProcessing")}
            </p>
            <p className="font-medium text-x8 text-clrPlaceholder">
              {t("withdrawalExpectedToArriveWithin15mins")}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <img src={TickYellow} width={20} height={20} alt="Successfull" />
          <div>
            <p className="text-x10 font-semibold text-[#525252] dark:text-clrPlaceholder">
              {t("withdrawalRequestSuccessful")}
            </p>
            <p className="font-medium text-x8 text-clrPlaceholder">
              {moment(data?.submittedAtTimeStamp).format("MMMM Do YYYY")}
            </p>
          </div>
        </div>
      </div>
      <div>
        <p className="mb-3 font-semibold text-x10 text-clrTextGray dark:text-clrPlaceholder">
          {t("transactionDetails")}
        </p>
        <div className="space-y-3">
          <p className="flex justify-between">
            <span className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("date")}
            </span>
            <span className="font-semibold text-x10 text-clrTextGray dark:text-white">
              {moment(data?.submittedAtTimeStamp).format("MMMM Do YYYY")}
            </span>
          </p>
          <p className="flex justify-between">
            <span className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              {t("amount")}
            </span>
            <span className="font-semibold text-x10 text-clrTextGray dark:text-white">
              {data?.amount} {data?.currencySymbol}
            </span>
          </p>
          <p className="flex justify-between">
            <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
              {t("network")}
            </span>
            <span className="text-[10px] font-semibold text-clrTextGray dark:text-white">
              Arbitrum
            </span>
          </p>
          <p className="flex justify-between">
            <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
              {t("address")}
            </span>
            <span className="text-[10px] font-semibold text-clrTextGray dark:text-white">
              {data?.destinationWalletAddress}
            </span>
          </p>
          <p className="flex justify-between">
            <span className="text-[10px] font-medium text-clrGray dark:text-clrPlaceholder">
              {t("networkFee")}
            </span>
            <span className="text-[10px] font-semibold text-clrTextGray dark:text-white">
              {data?.transactionCharge} {data?.transactionChargeCurrencySymbol}
            </span>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center mx-auto mt-8">
        <ButtonOutlineWallet
          text={t("backToWallet")}
          type="button"
          handleClick={() => navigate("/home/wallet")}
        />
      </div>
    </div>
  );
};

const CryptoWithdrawalAmt = ({ handleNext }: CryptoWithdrawalProps) => {
  const { t } = useTranslation();
  const [walletAddress, setAddress] = useState<string>("");
  const [amtToWithdraw, setAmtToWithdraw] = useState<string>("");
  const [isError, setError] = useState<string>("");
  const [isUserBalance, setUserBalance] = useState<Boolean>(false);

  const [selectedBalance, setSelectedBalance] =
    useState<BalanceProps>(BALANCE_STATE);

  const { userId } = useAppSelector((state) => state.auth);
  const { selectedCurrency } = useAppSelector((state) => state.wallet);

  //Get user's total balance
  const { data: balances } = useGetBalancesQuery(userId);

  //Make request to validate the user's wallet address on input blur
  const [trigger, error] = useLazyValidateAddressForCryptoWithdrawalQuery();

  //Get network fee
  const { data: transactionCharge, isLoading: gettingCharge } =
    useGetTransactionChargeQuery({
      transactionCategory: "Withdrawal",
      currencyId: selectedBalance.currencyId,
      channel: "SwitchWallet",
      amount: Number(amtToWithdraw),
    });

  const handleFocus = () => {
    trigger(walletAddress);
  };

  const handleContinue = () => {
    let totalAmt =
      Number(amtToWithdraw) + (transactionCharge?.data.transactionCharge || 0);
    if (totalAmt === 0) {
      setError(t("pleaseEnterAValidAmount"));
    } else if (totalAmt > selectedBalance?.balance) {
      setError(t("amountToWithdrawCannotBeGreaterThanAvailableBalance"));
    } else if (!walletAddress) {
      setError(t("pleaseEnterAValidWalletAddress"));
    } else if (error.data?.message) {
      setError(t("pleaseEnterAValidWalletAddress"));
    } else {
      setError("");
      let data = {
        selectedBalance: selectedBalance,
        walletAddress: walletAddress,
        amtToWithdraw: Number(amtToWithdraw),
      };
      handleNext(data);
    }
  };

  //check if we have selected currency. if we do, set that as the current balance, otherwise,
  //set the first balance as the current balance
  useEffect(() => {
    if (balances) {
      if (selectedCurrency) {
        let currBalance = balances?.data.balances.find(
          (each) => each.symbol === selectedCurrency.code
        );
        currBalance && setSelectedBalance(currBalance);
      } else {
        setSelectedBalance(balances?.data.balances[0]);
      }
    }
  // }, [balances?.data.balances, selectedCurrency]);
  }, [balances, selectedCurrency]);

  //Paste from the clipboard
  const pasteFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setAddress(text);
      })
      .catch((error) => {
        console.error("Failed to read text from clipboard:", error);
      });
  };

  return (
    <div className="p-4 py-5 bg-white rounded dark:bg-clrDarkBg md:px-8">
      <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-3">
        {t("amount")}
      </h2>
      <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
        {t("enterTheWithdrawalAmount")}
      </p>
      <div className="mt-4">
        <div className="relative">
          <button
            onClick={() => setUserBalance(!isUserBalance)}
            className="flex justify-between items-center border-[#C4C4C4] dark:border-clrGray border-solid border-[1px] rounded-[5px] p-3 gap-2 w-full"
          >
            <div>
              <h4 className="mb-1 font-semibold text-x10 text-clrTextBlue dark:text-white">
                {selectedBalance.symbol} {t("balance")}
              </h4>
              <p className="text-left text-x10 font-normal text-[#989898] dark:text-clrPlaceholder">
                {selectedBalance?.balance} {selectedBalance?.symbol}
              </p>
            </div>
            <img
              src={ArrowDown}
              alt={t("moreOptions")}
              width={20}
              height={20}
              className=""
            />
          </button>
          <div className="absolute z-20 w-full">
            {isUserBalance && (
              <div className="p-3 space-y-2 bg-white border-2 rounded-md dark:bg-clrDarkBg border-clrBg dark:border-clrGray">
                {balances?.data.balances.map((balance) => {
                  return (
                    <div
                      className="flex items-center justify-between"
                      key={balance.symbol}
                      onClick={() => {
                        setSelectedBalance(balance);
                        setUserBalance(!isUserBalance);
                      }}
                    >
                      <p className="font-medium text-x10 text-clrTextBlue dark:text-white">
                        {balance.symbol}
                      </p>
                      <p className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                        {balance.balance}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="my-6 md:px-8">
          <label
            htmlFor=""
            className="block font-semibold md:mb-2 text-x10 text-clrGray dark:text-clrPlaceholder"
          >
            {t("address")}
          </label>
          <div className="relative">
            <input
              type="text"
              className="text-x10 border-b-[1px] border-solid dark:bg-inherit border-clrTextBlue dark:border-clrGray dark:text-clrPlaceholder dark:placeholder:!text-clrPlaceholder pb-1 md:pb-2 focus:outline-none w-full "
              onChange={(e) => setAddress(e.target.value)}
              onBlur={() => handleFocus()}
              value={walletAddress}
            />
            <img
              src={PaperIcon}
              alt={t("downloadHere")}
              width={15}
              height={15}
              onClick={() => pasteFromClipboard()}
              className="absolute cursor-pointer top-1 right-2"
            />
            {/* <img
              src={ScanIcon}
              alt="Scan here"
              width={15}
              height={15}
              className="absolute top-1 right-2"
            /> */}
          </div>
          <p className="mt-1 font-medium text-x10 text-clrRed">
            {error.data?.message}
          </p>
        </div>
        <div className="relative">
          <button className="text-left border-[#C4C4C4] dark:border-clrGray dark:bg-inherit border-solid border-[1px] rounded-[5px] p-3 gap-2 mb-4 w-full">
            <h4 className="mb-1 font-semibold text-x10 text-clrTextBlue dark:text-white">
              {t("network")}
            </h4>
            <p className="text-left text-x10 font-normal text-[#989898] dark:text-clrPlaceholder">
              Arbitrium
            </p>
          </button>
        </div>
        <div className="relative flex flex-col items-center justify-center gap-3">
          <p className="text-center text-x10 font-semibold text-[#a6a6a6] dark:text-clrPlaceholder">
            {t("enterWithdrawalAmount")}
          </p>
          <div className="relative">
            <p className="flex text-center">
              <input
                type="number"
                className="w-1/2 text-3xl font-semibold text-right placeholder:text-3xl md:placeholder:text-5xl text-black dark:text-white dark:bg-inherit dark:placeholder:!text-white outline-none md:text-5xl"
                value={amtToWithdraw}
                placeholder="0"
                onChange={(e) => setAmtToWithdraw(e.target.value)}
              />
              <span className="self-end pb-4 text-x10 font-normal text-[#a6a6a6] dark:text-clrPlaceholder">
                {selectedBalance?.symbol}
              </span>
            </p>
            <div className="absolute top-2 right-28">
              <SwitchSwap width={40} height={40} />
            </div>
          </div>

          <p className="text-x8 font-normal text-[#a6a6a6] self-start dark:text-clrPlaceholder">
            Network fee{" "}
            {gettingCharge ? "..." : transactionCharge?.data.transactionCharge}
            {selectedBalance?.symbol}
          </p>
        </div>
      </div>
      <p className="font-medium text-center text-x10 text-clrRed">{isError}</p>
      <div className="md:w-[70%] mt-7 mx-auto ">
        <Button text={t("continue")} type="button" handleClick={handleContinue} />
      </div>
    </div>
  );
};
