import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { filesize } from "filesize";
import { CellRenderer } from "./CellRender";
import { numberWithCommas, numberWithCommasWithoutDecimal } from "../../Helper";
import { ReactComponent as InfoIcon } from "../../assets/icons/information-circle.svg";
import { useToggleAdSupportMutation } from "../../features/api/deviceSlice";
import { Device, Report, Session } from "./type";

// TODO : Move types to a typescript file

const columnHelper = createColumnHelper<Device>();

export const useMyDeviceColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor("id", {
      header: "#",
      cell: (row) => {
        return <p>{Number(row?.row.id) + 1}</p>;
      },
    }),
    columnHelper.accessor("name", {
      header: t("hubName"),
    }),
    columnHelper.accessor("deviceMode", {
      header: t("mode"),
    }),
    columnHelper.accessor("manager", {
      header: t("hubManagerAgent"),
      cell: (val) => {
        const manager = val.getValue();
        return <p>{manager ? manager : t("notAvailable")}</p>;
      },
    }),
    columnHelper.accessor("totalMiningReward", {
      header: () => <TotalMiningReward />,
      cell: (val) => {
        const reward = val.getValue();
        return <p> {numberWithCommas(reward || 0)} WNT</p>;
      },
    }),
    columnHelper.accessor("rate", {
      header: t("billingRate"),
      cell: (val) => {
        const rate = val.getValue();
        return <p> USD {numberWithCommas(rate)} /GB</p>;
      },
    }),
    columnHelper.accessor("isAdsEnabled", {
      header: t("hubAds"),
      cell: (val) => {
        let { id, isAdsEnabled } = val?.row?.original;
        return <AdToggle id={id} isAdsEnabled={isAdsEnabled} />;
      },
    }),
    columnHelper.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();
        let backgroundColor = "";
        let color = "";
        if (status.toLowerCase() === "online") {
          backgroundColor = "#E8FFF3";
          color = "#396";
        } else if (status.toLowerCase() === "offline") {
          backgroundColor = "#FFE4E4";
          color = "#DB5F5F";
        } else if (status.toLowerCase() === "blacklisted") {
          backgroundColor = "#585656";
          color = "#fff";
        }

        return (
          <div
            style={{
              background: backgroundColor,
              color,
              borderRadius: "19px",
              padding: "0.3rem 1rem",
              textAlign: "center",
            }}
            className="w-20 md:w-30 lg:w-full xl:w-[100%]"
          >
            {t(status.toLowerCase())}
          </div>
        );
      },
    }),
    columnHelper.accessor("id", {
      header: "",
      cell: (info) => {
        return <CellRenderer name={info?.row?.original?.name} id={info?.row?.original?.id} />;
      },
    }),
  ];
};

//Live Sessions
const columnSession = createColumnHelper<Session>();

export const useLiveSessionColumns = () => {
  const { t } = useTranslation();

  return [
    columnSession.accessor("deviceName", {
      header: "#",
      cell: (row) => {
        return <p>{Number(row?.row.id) + 1}</p>;
      },
    }),
    columnSession.accessor("deviceName", {
      header: t("hubName"),
    }),
    columnSession.accessor("deviceManagerName", {
      header: t("hubManagerAgent"),
    }),
    columnSession.accessor("clientEmail", {
      header: t("connectedUser"),
    }),
    columnSession.accessor("sessionDurationInMinutes", {
      header: t("duration"),
      cell: (val) => {
        const duration = val.getValue();
        return <p> {numberWithCommas(duration || 0)} {t("mins")}</p>;
      },
    }),
    columnSession.accessor("dataUsedInBytes", {
      header: t("dataUsed"),
      cell: (val) => {
        const dataUsed = val.getValue();
        return <p> {filesize(dataUsed || 0)}</p>;
      },
    }),
    columnSession.accessor("amountReceivedByHost", {
      header: t("amountGenerated"),
    }),
  ];
};

// Device Report / Hubs Report
const columnReport = createColumnHelper<Report>();

export const useReportColumns = () => {
  const { t } = useTranslation();

  return [
    columnReport.accessor("deviceName", {
      header: t("hubName"),
      cell: (val) => {
        const deviceName = val.getValue();
        return <p> {deviceName ? deviceName : t("unnamed")}</p>;
      },
    }),

    columnReport.accessor("usersCount", {
      header: t("numberOfUsers"),
    }),

    columnReport.accessor("sessionsCount", {
      header: t("sessions"),
      cell: (val) => {
        const sessions = val.getValue();
        return <p> {numberWithCommasWithoutDecimal(Number(sessions) || 0)}</p>;
      },
    }),
    columnReport.accessor("upTimeInHours", {
      header: t("uptime"),
      cell: (val) => {
        const uptime = val.getValue();
        return <p> {numberWithCommasWithoutDecimal(uptime)} {t("hrs")}</p>;
      },
    }),

    columnReport.accessor("totalMiningReward", {
      header: () => {
        return(<MiningReward />)
      },
      cell: (val) => {
        const miningReward = val.getValue();
        return <p> {numberWithCommasWithoutDecimal(miningReward)} WNT</p>;
      },
    }),

    columnReport.accessor("totalUsage", {
      header: t("dataUsed"),
      cell: (val) => {
        const totalUsage = val.getValue();
        return <p> {filesize(totalUsage || 0)}</p>;
      },
    }),
    columnReport.accessor("totalRevenue", {
      header: () => {
        return(<ResellRevenue />)
      },
      cell: (val) => {
        const totalRevenue = val.getValue();
        return t("usdAmount", { amount: numberWithCommas(totalRevenue || 0) });
      },
    }),
  ];
};

// Device Tracker
// type Tracker = {
//   Subscription: string;
//   DeviceName: string;
//   Value: string;
//   Used: string;
//   Remaining: string;
//   Validity: string;
//   ExpiryDate: string;
// };

// export const defaultDataTracker: Tracker[] = [
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
//   {
//     Subscription: "12-07-2021",
//     DeviceName: "Polomall_01",
//     Value: "200GB",
//     Used: "20GB",
//     Remaining: "180GB",
//     Validity: "180GB",
//     ExpiryDate: "12-09-2021",
//   },
// ];

// const columnTracker = createColumnHelper<Tracker>();

// export const columnsTracker = [
//   columnTracker.accessor("Subscription", {
//     header: "Subscription Date",
//   }),
//   columnTracker.accessor("DeviceName", {
//     header: "Hub Name",
//   }),
//   columnTracker.accessor("Value", {
//     header: "Data Value",
//   }),

//   columnTracker.accessor("Used", {
//     header: "Data Used",
//   }),
//   columnTracker.accessor("Remaining", {
//     header: "Data Remaining",
//   }),
//   columnTracker.accessor("Validity", {
//     header: "Validity",
//   }),
//   columnTracker.accessor("ExpiryDate", {
//     header: "Expiry Date",
//   }),
// ];

// Session History
const sessionHistoryColumns = createColumnHelper<Session>();

export const useSessionHistoryColumns = () => {
  const { t } = useTranslation();

  return [
    sessionHistoryColumns.accessor("sessionStartDateTimestamp", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    sessionHistoryColumns.accessor("deviceName", {
      header: t("hubsHubs"),
    }),
    sessionHistoryColumns.accessor("clientEmail", {
      header: t("userEmail"),
    }),
    sessionHistoryColumns.accessor("sessionDurationInMinutes", {
      header: t("duration"),
      cell: (val) => {
        const duration = val.getValue();
        return <p> {duration} {t("mins")}</p>;
      },
    }),
    sessionHistoryColumns.accessor("dataUsedInBytes", {
      header: t("dataUsed"),
      cell: (val) => {
        const totalUsage = val.getValue();
        return <p> {filesize(totalUsage || 0)}</p>;
      },
    }),
  ];
};


const AdToggle = ({ id, isAdsEnabled }: { id: string, isAdsEnabled: boolean }) => {
  const {t} = useTranslation();
  const [adStatus, setAdStatus] = useState<boolean>(isAdsEnabled || false);
  const [toggleAdSupport] = useToggleAdSupportMutation()


  const handleToggleAdSupport = () => {
    let data = {
      hubId: id,
      isAdSupportEnabled: !adStatus
    };
    setAdStatus(!adStatus)
    toggleAdSupport(data)
      .unwrap()
      .then((res) => {
        if (!res.success) {
          setAdStatus(false)
        }
      })
      .catch((err) => {
        setAdStatus(false)
      })
  };


  return (
    <div className="flex items-center gap-4">
      <div className="mb-0" > {adStatus ? t("enabled") : t("disabled")}</div>
      <label className="switch">
        <input type="checkbox" checked={adStatus} onChange={handleToggleAdSupport} />
        <span className="slider round"></span>
      </label>
    </div>
  )
}

const MiningReward = () => {
  const {t} = useTranslation();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      {t("miningReward")}               
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            {t("rewardAvailableAfter7Days")}
            {t("rewardMightBe0IfDateSelectedIsLessThan7Days")}
          </div>
        )}
      </span>
    </p>
  );
};
 
const TotalMiningReward = () => {
  const {t} = useTranslation();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      {t("totalMiningReward")}               
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            {t("totalMiningRewardTooltip")}
          </div>
        )}
      </span>
    </p>
  );
};

const ResellRevenue = () => {
  const {t} = useTranslation();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <p className="flex gap-1 items-center cursor-pointer">
      {t("dataResellRevenue")}             
      <span 
        className="relative"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <InfoIcon
          width={"15px"}
          height={"15px"}
          className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
        /> 
        {isTooltipVisible && (
          <div className="md:block absolute top-[-20px] left-0 w-32 bg-black text-white p-2 rounded z-10">
            {/* Add tooltip content */}
            {t("dataResellRevenueTooltip")}
          </div>
        )}
      </span>
    </p>
  );
};

export const useAdOverviewColumn = () => {
  const {t} = useTranslation();

  const adOverviewColumn = [
    columnHelper.accessor('device', {
      header: t('hubs'),
      cell: (val) => {
        const deviceDetails = val.getValue();
        return deviceDetails.name;
      },
    }),
    columnHelper.accessor('device', {
      header: t('BSSID'),
      cell: (val) => {
        const deviceDetails = val.getValue();
        return deviceDetails.bss_id;
      },
    }),
    columnHelper.accessor('viewcount', {
      header: t('viewCount'),
    }),
  ];

  return adOverviewColumn;
}

// export const useAdViewsColumns = () => {
//   const { t } = useTranslation();

//   return [
//     columnHelper.accessor('id', {
//       header: t("date"),
//       cell: (row) => {
//         return <p>{Number(row?.row.id) + 1}</p>;
//       },
//     }),
//     columnHelper.accessor('name', {
//       header: t("hotspotHub"),
//     }),
//     columnHelper.accessor('deviceMode', {
//       header: t("advertName"),
//     }),
//     columnHelper.accessor('deviceMode', {
//       header: t("amountEarned"),
//     }),
//   ];
// };
