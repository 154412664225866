import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

interface GraphCardProps {
  labels: string[];
  graphData: number[];
  height?: string | number;
  width?: string | number;
  showXAxis?: boolean;
  showYAxis?: boolean;
  borderColor?: string;
  bgColor1?: string;
  bgColor2?: string;
  lineTension?: number;
}

const GraphCard: React.FC<GraphCardProps> = ({
  labels,
  graphData,
  height = 150,
  width = 300,
  showXAxis = true,
  showYAxis = true,
  borderColor = "#CFA809",
  bgColor1 = "rgba(207, 168, 9, 0.43)",
  bgColor2 = "rgba(207, 168, 9, 0)",
  lineTension = 0.4,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
      },
    },
    lineTension: lineTension,
    scales: {
      y: {
        display: showYAxis,
        grid: {
          display: showYAxis,
          drawBorder: false,
        },
        ticks: {
          display: showYAxis,
        },
      },
      x: {
        display: showXAxis,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: showXAxis,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: graphData,
        backgroundColor: (context: any) => {
          const bgColor = [bgColor1, bgColor2];

          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            data,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(1, bgColor[1]);

          return gradientBg;
        },
        borderColor: borderColor,
        lineTension: 0.4,
        borderWidth: 1.8,
        fill: true,
        //   borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        //   borderJoinStyle: "miter",
        pointBorderColor: "#25346A",
        pointBackgroundColor: borderColor,
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#25346A",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  return <Line options={options} data={data} height={height} width={width} />;
};

export default GraphCard;
