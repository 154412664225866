import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "../reusables";
import { useTranslation } from "react-i18next";

type History = {
  id: string;
  lockUpStartDateTimestamp: number;
  lockedReward: number;
  type: string;
  interestEarned: number;
  currency: string;
  lockPeriodInMonths: number;
  status: string;
};

interface LockDuration {
  [key: number]: string;
}

type RewardHistory = {
  createdAtTimestamp: string;
  interestEarned: number;
  currency: string;
};

const boosterHistory = createColumnHelper<History>();
const rewardHistory = createColumnHelper<RewardHistory>();


export const useBooster = () => {
  const {t} = useTranslation();

  const boosterHistoryColumn = [
    boosterHistory.accessor("lockUpStartDateTimestamp", {
      header: t("time"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
  
    boosterHistory.accessor("lockedReward", {
      header: t("lockedReward"),
      cell: ({ row }) => {
        return (
          <p> {row?.original.lockedReward + " " + row?.original.currency}</p>
        );
      },
    }),
    boosterHistory.accessor("interestEarned", {
      header: t("boostReward"),
      cell: ({ row }) => {
        return (
          <p> {row?.original.interestEarned + " " + row?.original.currency}</p>
        );
      },
    }),
    boosterHistory.accessor("lockPeriodInMonths", {
      header: t("lockDuration"),
      cell: (val) => {
        const duration = val.getValue();
        return <p> {duration + `${t("months")}`}</p>;
      },
    }),
    boosterHistory.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();
        let color = "";
        if (status.toLowerCase() === "active") {
          color = "#00C087";
        } else if (status.toLowerCase() === "closed") {
          color = "#DB5F5F";
        }
  
        return (
          <p
            style={{
              color,
            }}
            className=""
          >
            {status}
          </p>
        );
      },
    }),
    boosterHistory.accessor("id", {
      header: "",
      cell: (val) => {
        const cycleId = val.getValue();
        return (
          <Link to={`/home/booster/history/${cycleId}`}>
            <Button
              text={"View Reward History"}
              type={"button"}
              handleClick={() => console.log("handle click")}
              textSize={"text-[0.5rem]"}
              lineHeight={"leading-[0]"}
            />
          </Link>
        );
      },
    }),
  ];
  
  const boosterFaq = [
    {
      id: 1,
      isView: true,
      question: t("faq1"),
      answer: t("faqAnswer1"),
    },
    {
      id: 2,
      isView: true,
      question: t("faq2"),
      answer: t("faqAnswer2"),
    },
    {
      id: 3,
      isView: true,
      question: t("faq3"),
      answer: t("faqAnswer3"),
    },
    {
      id: 4,
      isView: true,
      question: t("faq4"),
      answer: t("faqAnswer4"),
    },
  ];
  
  const rewardDuration = [
    { id: 1, value: "ThreeMonths", text: t("3Months") },
    { id: 2, value: "SixMonths", text: t("6Months") },
    { id: 3, value: "TwelveMonths", text: t("12Months") },
  ];
   
  const lockDuration: LockDuration = {
    3: "ThreeMonths",
    6: "SixMonths",
    12: "TwelveMonths",
  };
  
  const rewardHistoryColumn = [
    rewardHistory.accessor("createdAtTimestamp", {
      header: t("time"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
  
    rewardHistory.accessor("createdAtTimestamp", {
      header: t("type"),
      cell: () => {
        return <p> Weekly Reward</p>;
      },
    }),
    rewardHistory.accessor("interestEarned", {
      header: t("boostReward"),
      cell: ({ row }) => {
        return (
          <p> {row?.original.interestEarned + " " + row?.original.currency}</p>
        );
      },
    }),
  ];

  return {boosterHistoryColumn, boosterFaq, rewardDuration, lockDuration, rewardHistoryColumn}
}
