import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SecondaryNav from '../reusables/SecondaryNav';

const AnalyticsDetails = () => {
  const { t } = useTranslation();
  const { analyticName, analyticId } = useParams();
  const encodedAnalyticName = encodeURIComponent(analyticName as string);

  return (
    <SecondaryNav
      paths={[
        {
          path: `/home/analytics/connections/${analyticId}/${encodedAnalyticName}`,
          title: t('connections'),
        },
        {
          path: `/home/analytics/uptime/${analyticId}/${encodedAnalyticName}`,
          title: t('uptime'),
        },
        {
          path: `/home/analytics/data-usage/${analyticId}/${encodedAnalyticName}`,
          title: t('dataUsage'),
        },
        {
          path: `/home/analytics/speed/${analyticId}/${encodedAnalyticName}`,
          title: t('speed'),
        },
        {
          path: `/home/analytics/traffic/${analyticId}/${encodedAnalyticName}`,
          title: t('trafficAnalytics'),
        },
      ]}
    />
  );
};

export default AnalyticsDetails;
