import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { useRef, useState } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import TableMain from "../reusables/ReactDataTable";
import { useClaimHistoryColumns } from "./Data";
import { useGetClaimHistoryQuery } from "../../features/api/explorerSlice";
import { EmptyCard, Filter, TableLoader } from "../reusables";
import { useTranslation } from "react-i18next";

const ClaimHistory = () => {
  const {t} = useTranslation();
  const [option, toggleOption] = useState<string>("");
  const [walletOption, setWalletOption] = useState<string>("All");
  const [duration, setDuration] = useState<string>("This Month");
  const [historyStatus, setStatus] = useState<string>("All");
  const [addressToSearch, setAddressToSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const columnsClaimHistory = useClaimHistoryColumns();

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  //Get Claim history
  const {
    data: claimHistory,
    isLoading,
    isFetching,
  } = useGetClaimHistoryQuery({
    walletAddress: addressToSearch,
    walletName: walletOption === "All" ? "" : walletOption,
    paymentStatus: historyStatus  === "All" ? "" : historyStatus,
    duration: duration === "This Month" ? "1" : duration === "Last Month" ?  "2" : duration === "Last 3 Months" ?  "3" : "12",
    pageNumber: page,
    pageSize: 30,
  });

  return (
    <section className="w-[96%] mx-auto pt-3 pb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-8">
        <div className="flex flex-wrap items-center gap-4 mb-8 ">
          <MobileFilterSearch>
            <Filter
              options={[ t("all"),t("internalWallet"), t("metamask"), t("walletconnect")]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"WALLET"}
              selectFilterOption={setWalletOption}
              selectedOption={walletOption}
              modalRef={modalRef}
              label={t("wallet")}
            />
            <Filter
              options={[
                t("thisMonth"),
                t("lastMonth"),
                t("last3Months"),
                t("thisYear"),
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"DURATION"}
              selectFilterOption={setDuration}
              selectedOption={duration}
              modalRef={modalRef}
              label={t("duration")}
            />
            <Filter
              options={[t("all"), t("failed"), t("success"), t("pending")]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"STATUS"}
              selectFilterOption={setStatus}
              selectedOption={historyStatus}
              modalRef={modalRef}
              label={t("status")}
            />
          </MobileFilterSearch>
          <div className="flex-1 md:flex-auto ">
            <label
              htmlFor=""
              className="hidden mb-2 font-semibold md:block text-x10 md:text-x11 text-clrGray dark:text-clrPlaceholder"
            >
              {t("address")}
            </label>
            <input
              type="text"
              name=""
              onChange={(e) => setAddressToSearch(e.target.value)}
              id=""
              placeholder={t("addressPlaceholder")}
              className="w-full md:w-52 text-x10 border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-7 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
            />
          </div>
        </div>

        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={claimHistory?.data.records || []}
              columns={columnsClaimHistory}
              totalPages={claimHistory?.data.totalPages || 0}
              pageSize={claimHistory?.data.pageSize || 0}
              currentPage = {claimHistory?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading && !isFetching && (claimHistory?.data.records.length || 0) === 0  && (
          <EmptyCard description={t("noHistory")} />
        )}
      </div>
    </section>
  );
};

export default ClaimHistory;
