import React, { useRef, useState } from "react";
import { Card, ButtonAuth, ErrorMsg, InputBox } from "../../reusables";
import ClosedEye from "../../../assets/auth/eye-closed.svg";
import OpenEye from "../../../assets/auth/eye-open.svg";
import { Link, useNavigate } from "react-router-dom";
import { useLogInMutation, useLogInTwoFaMutation } from "../../../features/api/authSlice";
import GoogleAuth from "../googleAuth/GoogleAuth";
import AuthFrame from "../../reusables/AuthFrame";
import { useAppDispatch } from "../../../app/hooks";
import { VerificationCodeModal } from "./VerificationCodeModal";
import { setUser } from "../../../features/store/authReducerSlice";
import { validateEmail } from "../../../Helper";
import useOutsideClick from "../../../Hooks/useOutsideClick";

const loginDetails = {
  email: "",
  pin: "",
};

interface LoginFormProps {
  email: string;
  pin: string;
}
const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authRequired = "TwoFactorAuthenticationRequired";
  // const authCompleted = "AuthenticationCompleted";

  const [isView, setView] = useState<boolean>(false);
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [modalVersion, setModal] = useState<number>(0);

  const [form, setForm] = useState<LoginFormProps>(loginDetails);
  const [isError, setError] = useState<string>("");
  const [isVerifyError, setVerifyError] = useState<string>("");
  const [authMedium, setAuthMedium] = useState<string>("");
  const [logIn, { isLoading }] = useLogInMutation();

  const modalRefOptions = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setModal(0);
    setVerifyError("");
  };

  useOutsideClick(modalRefOptions, handleCloseModal);


  const [login2fa, { isLoading: loading }] = useLogInTwoFaMutation();

  //handle form inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setError("");
    }
    const value = e.target.value;
    setForm({ ...form, [e.target.id]: value });
  };

  //function to validate user inputs and then send the request to the
  //backend for authentication.
  //if it's successful, we authenticate user and push them to the user dashboard home page
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateEmail(form.email)) {
      setError("Please enter a valid email");
    } else if (!form.pin) {
      setError("Please enter a valid pin");
    } else {
      setError("");
      logIn(form)
        .unwrap()
        .then((res) => {
          if (res?.data?.authenticationStep === authRequired) {
            setAuthMedium(res?.data?.authenticationMedium as string)
            setModal(1);
          } else {
            dispatch(setUser(res?.data.authResponse));
            navigate("/home");
          }

        })
        .catch((error) => {
          if (error?.data?.message) {
            setError(error.data.message);
          } else {
            setError("An error occurred.");
          }
        });
    }
  };

  const handleVerify = (faCode: string) => {
    setVerifyError("")
    if (faCode.length === 6) {
      let data = {
        ...form,
        code: faCode
      }
      login2fa(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            dispatch(setUser(res?.data.authResponse));
            navigate("/home");
          } else {
            setVerifyError(res.message || "Something went wrong");
          }
        })
        .catch((err) => {
          setVerifyError(err?.data.message || "Something went wrong")
        });
    } else {
      setVerifyError("Please enter a valid code");
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex flex-col min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="mb-6 md:text-left">
            <h1 className="font-semibold text-[#000] text-lg md:text-3xl md:leading-[54px] mb-5 ">
              Log In
            </h1>
            <p className="text-para">
              Log In to your dashboard  and start managing your data.
            </p>
          </div>
          <form className="">
            <div className="mb-4">
              <InputBox
                title="Email address"
                name="email"
                type="email"
                id="email"
                value={form.email}
                handleChange={handleChange}
                placeholder=""
              />
            </div>
            <div className="relative">
              <InputBox
                title="Enter PIN"
                type={isView ? "text" : "password"}
                name="pin"
                id="pin"
                value={form.pin}
                handleChange={handleChange}
                placeholder=""
              />
              <span
                className="absolute cursor-pointer top-6 right-4"
                onClick={() => setView(!isView)}
              >
                {isView ? (
                  <img src={OpenEye} alt="open-eye" />
                ) : (
                  <img src={ClosedEye} alt="close-eye" />
                )}
              </span>
            </div>
            <div className="mt-2 mb-4 md:mb-8 ">
              <p className="text-[12px] leading-4 font-normal md:font-medium md:text-sm md:leading-[22px] text-primary italic text-right">
                Forgot your pin?{" "}
                <Link to="/reset" className="text-gray-500">
                  Reset
                </Link>
              </p>
            </div>
            <ErrorMsg errMsg={isError ? isError : ""} />
            <ButtonAuth
              type="submit"
              loading={isLoading || authenticating}
              handleClick={handleSubmit}
              text="Log In"
              testId="login-button"
            />
            <div>
              <p className="my-6 text-center text-para !text-[#3E3E3E]">or</p>
            </div>
            <GoogleAuth
              title={"signin_with"}
              setError={setError}
              setAuthenticating={setAuthenticating}
            />

           

          </form>
          <div>
            <p className="text-[12px] leading-4 font-normal md:font-medium md:text-[0.9rem] md:leading-[22px] text-[#333333] mt-4 text-center">
              Don't have an account yet?{" "}
              <Link to="/signup" className="text-primary">
                Create Account
              </Link>
            </p>
          </div>
        </div>
      </Card>
      <AuthFrame />
      {modalVersion === 1 &&
        <VerificationCodeModal
          modalRef={modalRefOptions}
          handleSubmit={handleVerify}
          buttonTitle={"Submit"}
          formTitle={authMedium || ""}
          description={`Enter the 6 digit code from your ${authMedium?.toLowerCase() || ""}`}
          handleModal={handleCloseModal}
          errMsg={isVerifyError}
          loading={loading}
        />
      }
    </div>
  );
};
export default LogIn;
