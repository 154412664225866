import { useState } from "react";
import { MoreResponseModalProps } from "../hubForm/types";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { supportedLanguages } from "./data";
import { useTranslation } from "react-i18next";

export const LanguageModal = ({ modalRef, handleCloseModal }: MoreResponseModalProps) => {
    const {t} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");
    const [deviceToSearch, setDeviceSearch] = useState<string>("");

    const { i18n} = useTranslation();

    const handleCheckboxChange = (code: string) => {
        i18n.changeLanguage(code)
        setSelectedLanguage(code)
        handleCloseModal()
    };

    
    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
            <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white w-fit md:w-96 dark:bg-clrDarkBg">
                <div className="flex items-center gap-2 mb-6">
                    <button className="btn-reset" onClick={handleCloseModal}>
                        <ArrowBack className="w-5 h-5 dark-arrow" />
                    </button>
                    <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                        {t("chooseYourLanguage")}
                    </h3>
                </div>
                <div className="flex flex-col gap-10 mt-5">
                    <div className="mt-1.5">
                        <label htmlFor="" className="hidden">{t("search")}</label>
                        <input
                            type="text"
                            name="" id=""
                            placeholder={t("search")}
                            value={deviceToSearch}
                            onChange={(e) => setDeviceSearch(e.target.value)}
                            className="relative flex items-center justify-between bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
                    </div>
                    
                    <div className="space-y-6">
                        {supportedLanguages.map((language) => {
                            return (
                                <label
                                    key={language.code}
                                    className="material-checkbox text-x11 font-normal text-clrGray66 dark:text-clrPlaceholder"
                                >
                                    {language.name}
                                    <input
                                        checked={selectedLanguage === language.code}
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(language.code)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            )
                        })}
                    </div>

                </div>
            </div>

        </div>
    );
};