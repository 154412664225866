import { ReactComponent as LightIcon } from "../../assets/icons/hotspot-hubs/light.svg";

export const EmptyCard = ({ description }: { description: string }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-6 w-full">
      <LightIcon className="dark-light-icon" />
      <p className="text-xs font-small text-clrGray3 w-[23%] mx-auto text-center">{description}</p>
    </div>
  );
};
