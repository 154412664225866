import { useState } from "react";
import { MoreResponseModalProps } from "../hubForm/types";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { Button } from "../reusables";
import { useTranslation } from "react-i18next";
import { Country } from "../../features/api/authSliceTypes";
import { useGetSupportedCountriesQuery } from "../../features/api/authSlice";
import { ErrorMsg } from '../reusables';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-Yellow.svg';
import { SelectRegion } from "./EditNumber";

export const NewTVAccount = ({ modalRef, handleCloseModal }: MoreResponseModalProps) => {
    const {t} = useTranslation();
    const [search, setSearch] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [isDropdown, showDropdown] = useState<boolean>(false);
    const [selectRegion, showSelectRegion] = useState<boolean>(false);
    const [errMsg, setErr] = useState<string>('');
    const [agentEmail, setAgentEmail] = useState<string>("");
    
    const { data: countries } = useGetSupportedCountriesQuery({
        page: 1,
        pageSize: 195,
        search,
    });

    const handleCountryClick = (country: Country) => {
        setSelectedCountry(country);
        showSelectRegion(false);
    };

    const handleSaveTVAccount = () => {
        setErr("")
      };

    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white dark:bg-clrDarkBg w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            {t("addNewAccount")}
                        </h3>
                    </div>
                    <p className="text-xs font-normal text-clrGray mb-[1.375rem] dark:text-clrPlaceholder">{t("addNewAccountDetails")}</p>

                    <div className="grid gap-[1.875rem]">
                        <div className="grid gap-7">
                            <label
                                htmlFor="email"
                                className="block font-medium text-black dark:text-white text-xs"
                            >
                                {t("emailAddress")}
                            </label>
                            <input
                                type="text"
                                id="email"
                                onChange={(e) => setAgentEmail(e.target.value)}
                                value={agentEmail}
                                placeholder="example@useremail.com"
                                className="w-full dark:bg-transparent font-semibold text-xs text-clrGray43 dark:text-clrPlaceholder placeholder:text-xs dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-[0.7px] border-solid border-clrBorder5 outline-none"
                            />
                        </div>
                        <div className="w-full">
                            <label
                            htmlFor="country"
                            className="block text-[0.625rem] leading-3 text-black dark:text-white md:text-xs font-medium mb-2"
                            >
                            {t("Country")}
                            </label>

                            <div className="relative" onClick={() => showSelectRegion(!selectRegion)}>
                            <img
                                src={selectedCountry?.logoUrl}
                                alt={selectedCountry?.name}
                                className="absolute inset-y-0 left-0 top-[20%] h-5 w-auto"
                            />
                            <input
                                className="w-full pb-2 pl-10 pr-4 text-xs font-medium bg-transparent border-b border-clrBorder5 text-clrGray focus:outline-none"
                                onChange={(e) => setSearch(e.target.value)}
                                value={selectedCountry?.name || search || ''}
                            />
                            <ArrowDown
                                className="absolute cursor-pointer right-0 top-[20%]"
                                width={20}
                                height={20}
                            />
                            </div>

                            {isDropdown && (
                            <div>
                                <div className="absolute z-30 w-40 p-3 pb-4 pl-0 overflow-auto bg-white rounded dark:bg-clrDarkBg max-h-40">
                                <ul className="space-y-6 text-xs font-normal text-clrGray dark:text-clrPlaceholder">
                                    {countries?.data?.records.map((country) => {
                                    return (
                                        <li
                                        key={country.countryId}
                                        onClick={() => {
                                            setSelectedCountry(country);
                                            showDropdown(!isDropdown);
                                        }}
                                        className="flex items-center gap-4 cursor-pointer "
                                        >
                                        <span>
                                            <img src={country.logoUrl} alt={country.name} />
                                        </span>
                                        {country.name}
                                        </li>
                                    );
                                    })}
                                </ul>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className="w-full grid gap-7">
                            <label
                            htmlFor="country"
                            className="block text-[0.625rem] leading-3 text-black dark:text-white md:text-x11 font-medium"
                            >
                            {t("phoneNumber")}
                            </label>
                            <div className='flex items-center gap-1.5 border-b border-clrBorder5 pb-1.5'>
                                <p className='text-[0.625rem] leading-3 text-clrGray dark:text-clrPlaceholder  md:text-xs font-normal'>{selectedCountry?.countryCode || "+234"}</p>
                                <div className='h-[0.8rem] w-[0.075rem] bg-clrPlaceholder' />
                                <input
                                className="w-full text-[0.625rem] leading-3 md:text-xs bg-transparent dark:text-clrPlaceholder font-normal text-clrGray focus:outline-none"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                value={phoneNumber || ''}
                                />
                            </div>
                        </div>
                        <ErrorMsg errMsg={errMsg} />
                    </div>
                    <div className="flex mt-auto [&>*]:ml-0">
                        <Button
                            type="button"
                            text={t("save")}
                            disabled={phoneNumber === ""}
                            handleClick={() => handleSaveTVAccount}
                            fontWeight="font-normal"
                            wBtn="md:w-[8rem]"
                        />
                    </div>

                </div>
                {selectRegion && (
                    <SelectRegion
                        handleCloseModal={() => showSelectRegion(false)}
                        handleGoBack={() => showSelectRegion(false)}
                        handleSubmit={handleCountryClick}
                    />
                )}
        </div>
    );
};