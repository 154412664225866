import { useParams } from 'react-router-dom';

import { MoreDetailsCard } from "./MoreDetails";
import { BarChart } from "./BarChartSession";
import { useState } from "react";
import { SearchRow } from './common';
import { useGetUptimeGraphResultsQuery, useGetUptimeSummaryQuery } from '../../features/api/analyticsSlice';
import { exportTableData } from '../../Helper';
import { useTranslation } from 'react-i18next';

interface UptimeData {
  uptime: string;
  uptimeInSeconds: number;
  timePeriod: string;
}

export interface BarChartUptimeProps {
  labels: string[];
  data: UptimeData[];
}

const Uptime = () => {
  const { t } = useTranslation();
  const { analyticId } = useParams();
  // State for filters
  const [filterYear, setFilterYear] = useState<string>('2024');
  const [filterTimeline, setFilterTimeline] = useState<string>('Yearly');
  const [filterMonth, setFilterMonth] = useState<string>('August');

  const { data: uptimeGraph } = useGetUptimeGraphResultsQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear
  });


  const { data: uptimeSummary, isLoading: gettingUptimeSummary } = useGetUptimeSummaryQuery({
    deviceId: analyticId || '',
    timeLine: filterTimeline,
    month: filterMonth,
    year: filterYear
  });

  const uptimeCardsData = [
    {
      title: t("averageUptime"),
      value: uptimeSummary?.data?.averageUptime,
      info: t("averageUptimeInfo"),
    },
    {
      title: t("maximumUptime"),
      value: uptimeSummary?.data?.maximumUptime,
      info: t("maximumUptimeInfo"),
    },
    {
      title: t("minimumUptime"),
      value: uptimeSummary?.data?.minimumUptime,
      info: t("minimumUptimeInfo"),
    },
    // {
    //   title: "Max Uptime of a Connected Device",
    //   value: uptimeSummary?.data?.maximumUptimeOfAConnectedDevice,
    //   info: "This is the maximum uptime of a single connected device.",
    // },
    // {
    //   title: "Min Uptime of a Connected Device",
    //   value: uptimeSummary?.data?.minimumUptimeOfAConnectedDevice,
    //   info: "This is the minimum uptime of a single connected device.",
    // },
  ];


  const chartData: BarChartUptimeProps = {
    labels: uptimeGraph?.data.map(item => item.timePeriod) || [],
    data: uptimeGraph?.data.map(item => ({
      uptime: item.uptime,
      uptimeInSeconds: item.uptimeInSeconds,
      timePeriod: item.timePeriod,
    })) || []
  };


  return (
    <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6 my-4">
        <div>
          <h3 className="text-sm font-semibold text-clrGray dark:text-white mb-5">
            {t("overallUptime")}
          </h3>
          <SearchRow
            setFilterYear={setFilterYear}
            filterYear={filterYear}
            filterTimeline={filterTimeline}
            setFilterTimeline={setFilterTimeline}
            setFilterMonth={setFilterMonth}
            filterMonth={filterMonth}
            exportTable={() => exportTableData(uptimeGraph?.data || [], "Overall Uptime")}
          />
          <BarChart chartData={chartData} />
        </div>
        <div className="mt-4">
          <h3 className="text-sm font-medium text-clrGray dark:text-white">
            {t("moreDetails")}
          </h3>
          <div className="grid md:grid-cols-2 gap-2 py-4 rounded-lg md:pt-5 md:pb-4 xl:grid-cols-4 md:gap-4">
            {uptimeCardsData.map((card, index) => (
              <MoreDetailsCard
                key={index}
                title={card.title}
                value={card.value}
                info={card.info}
                isLoading={gettingUptimeSummary}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Uptime;
