import { useTranslation } from "react-i18next";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import { numberWithCommas } from "../../../Helper";


// Crypto
type Crypto = {
  transactionTimeStamp: string;
  transactionType: string;
  reference: string;
  amount: number;
  status: string;
  currencyCode: string;
};

const columnCrypto = createColumnHelper<Crypto>();

export const useCryptoColumns = () => {
  const { t } = useTranslation();

  return [
    columnCrypto.accessor("transactionTimeStamp", {
      header: t("time"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnCrypto.accessor("transactionType", {
      header: t("type"),
    }),
    columnCrypto.accessor("reference", {
      header: t("reference"),
    }),
    columnCrypto.accessor("amount", {
      header: t("amount"),
      cell: ({ row }) => {
        return (
          <p>
            {numberWithCommas(row?.original.amount, 2)}{" "}
            {row?.original.currencyCode}
          </p>
        );
      },
    }),
    columnCrypto.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();

        let color = "";
        let updatedStatus = "";

        if (status === "CONFIRMED") {
          color = "#00C087";
          updatedStatus = t("confirmed");
        } else if (status === "FAILED") {
          color = "#DB5F5F";
          updatedStatus = t("failed");
        } else {
          color = "#E5B910";
          updatedStatus = t("pending");
        }

        return (
          <p
            style={{
              color,
            }}
          >
            {updatedStatus}
          </p>
        );
      },
    }),
  ];
};


// P2P
type P2P = {
  transactionTimeStamp: string;
  p2POrderType: string;
  buyCurrency: string;
  sellCurrency: string;
  buyAmount: number;
  sellAmount: number;
  reference: string;
  status: string;
};

const columnP2P = createColumnHelper<P2P>();

export const useP2PColumns = () => {
  const { t } = useTranslation();

  return [
    columnP2P.accessor("transactionTimeStamp", {
      header: t("time"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnP2P.accessor("p2POrderType", {
      header: t("type"),
    }),
    columnP2P.accessor("buyCurrency", {
      header: t("buyCurrency"),
    }),
    columnP2P.accessor("sellCurrency", {
      header: t("sellCurrency"),
    }),
    columnP2P.accessor("buyAmount", {
      header: t("buyAmount"),
      cell: (info) => {
        let buyAmount = info.getValue();
        return (
          <p> {numberWithCommas(buyAmount || 0)}</p>
        );
      },
    }),
    columnP2P.accessor("sellAmount", {
      header: t("sellAmount"),
      cell: (info) => {
        let sellAmount = info.getValue();
        return (
          <p> {numberWithCommas(sellAmount || 0)}</p>
        );
      },
    }),
    columnP2P.accessor("reference", {
      header: t("reference"),
      cell: (val) => {
        const reference = val.getValue();
        return <p> {reference.slice(0, 6) + "xxx"}</p>;
      },
    }),
    columnP2P.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();

        let color = "";
        let updatedStatus = "";

        if (status === "CONFIRMED") {
          color = "#00C087";
          updatedStatus = t("confirmed");
        } else if (status === "FAILED") {
          color = "#DB5F5F";
          updatedStatus = t("failed");
        } else {
          color = "#E5B910";
          updatedStatus = t("pending");
        }

        return (
          <p
            style={{
              color,
            }}
          >
            {updatedStatus}
          </p>
        );
      },
    }),
  ];
};


// Swap
type Swap = {
  transactionTimeStamp: number;
  buyAmount: number;
  buyCurrency: string;
  sellAmount: number;
  sellCurrency: string;
  status: string;
  reference: string;
};

const columnSwap = createColumnHelper<Swap>();

export const useSwapColumns = () => {
  const { t } = useTranslation();

  return [
    columnSwap.accessor("transactionTimeStamp", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnSwap.accessor("sellAmount", {
      header: t("amountSent"),
      cell: ({ row }) => {
        return (
          <p> {numberWithCommas(row?.original.sellAmount || 0) + " " + row?.original.sellCurrency}</p>
        );
      },
    }),
    columnSwap.accessor("buyAmount", {
      header: t("amountReceived"),
      cell: ({ row }) => {
        return (
          <p> {numberWithCommas(row?.original.buyAmount || 0) + " " + row?.original.buyCurrency}</p>
        );
      },
    }),
    columnSwap.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();

        let color = "";
        let updatedStatus = "";

        if (status === "CONFIRMED") {
          color = "#00C087";
          updatedStatus = t("confirmed");
        } else if (status === "FAILED") {
          color = "#DB5F5F";
          updatedStatus = t("failed");
        } else {
          color = "#E5B910";
          updatedStatus = t("pending");
        }

        return (
          <p
            style={{
              color,
            }}
          >
            {updatedStatus}
          </p>
        );
      },
    }),
    columnSwap.accessor("reference", {
      header: t("reference"),
    }),
  ];
};


// Transfer
type Transfer = {
  transactionTimeStamp: string;
  transactionType: string;
  from: string;
  to: string;
  amount: number;
  reference: string;
  status: string;
  currency: string

};

const columnTransfer = createColumnHelper<Transfer>();

export const useTransferColumns = () => {
  const { t } = useTranslation();

  return [
    columnTransfer.accessor("transactionTimeStamp", {
      header: t("time"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnTransfer.accessor("transactionType", {
      header: t("type"),
    }),
    columnTransfer.accessor("to", {
      header: t("recipient"),
    }),
    columnTransfer.accessor("amount", {
      header: t("amount"),
      cell: ({ row }) => {
        return (
          <p> {row?.original.currency + " " + numberWithCommas(row?.original.amount)}</p>
        );
      },
    }),
    columnTransfer.accessor("reference", {
      header: t("reference"),
      cell: (val) => {
        const reference = val.getValue();
        return <p> {reference.slice(0, 6) + "xxx"}</p>;
      },
    }),
    columnTransfer.accessor("status", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();

        let color = "";
        let updatedStatus = "";

        if (status === "CONFIRMED") {
          color = "#00C087";
          updatedStatus = t("confirmed");
        } else if (status === "FAILED") {
          color = "#DB5F5F";
          updatedStatus = t("failed");
        } else {
          color = "#E5B910";
          updatedStatus = t("pending");
        }

        return (
          <p
            style={{
              color,
            }}
          >
            {updatedStatus}
          </p>
        );
      },
    }),
  ];
};

// stake
type Stakes = {
  transactionTimeStamp: string;
  action: string;
  type: string;
  amount: number;
};

const columnStakes = createColumnHelper<Stakes>();

export const useStakesColumns = () => {
  const { t } = useTranslation();

  return [
    columnStakes.accessor("transactionTimeStamp", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnStakes.accessor("action", {
      header: t("action"),
    }),
    columnStakes.accessor("type", {
      header: t("type"),
    }),
    columnStakes.accessor("amount", {
      header: t("amount"),
      cell: (val) => {
        const amount = val.getValue();
        return <p> {numberWithCommas(amount)}</p>;
      },
    }),
  ];
};

