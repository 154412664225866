import { Modal } from '../reusables/Modal';
import { Button, ErrorMsg } from '../reusables';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handlePaste } from '../../Helper';
import { useGenerateEmailOTPForExistingUserMutation, useVerify2faMutation } from '../../features/api/authSlice';
import { AuthAppProps } from '../../types';
import { useTranslation } from 'react-i18next';


export const AuthApp = ({
  handleModal,
  formTitle,
  description,
  timer,
  setOtp,
  otp,
  email,
}: AuthAppProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isError, setError] = useState('');

  const [generateEmailOTPForExistingUser] =
    useGenerateEmailOTPForExistingUserMutation();
  const [verify2fa, { isLoading }] = useVerify2faMutation();

  useEffect(() => {
    handleResendOTP();
  }, []);

  const handleResendOTP = () => {
    generateEmailOTPForExistingUser();
  };

  const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    setError("")
    e.preventDefault();
    if (otp?.length === 6) {
      let data = {
        twoFactorCode: otp,
        authenticationMedium: 'Email',
      };
      verify2fa(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/home/profile/security/2fa');
          } else {
            setError(res.message);
          }
        })
        .catch((err) => setError(err?.data?.message));
    } else {
      setError(t('securityQuestion'));
    }
  };
  
  const handleClick = async () => {
    const clipboardText = await handlePaste();
    setOtp(clipboardText || "");
  };

  return (
    <Modal
      close={true}
      title={t('securityVerification')}
      description={t('verifyToProceed')}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="">
          <div className="px-7 py-8 mb-8 bg-white rounded-xl  dark:bg-clrDarkBg">
            <div>
              <label
                htmlFor="verification-code"
                className="block text-[0.625rem] leading-3 text-clrGray md:text-xs font-bold mb-2"
              >
                {formTitle}
              </label>
              <div className="relative">
                <input
                  className="bg-clrBg rounded-lg w-3/4 h-11 text-base focus:outline-none px-3"
                  type="number"
                  value={otp}
                  onChange={(e) => {
                    if (isError) {
                      setError('');
                    } else {
                      setOtp(e.target.value);
                    }
                  }}
                />
                {timer ? (
                  <span className="absolute cursor-pointer top-[35%] right-[30%] text-xs text-secondary">
                    {timer}
                  </span>
                ) : (
                  <span onClick={()=> handleClick()} className="absolute cursor-pointer top-[35%] right-[30%] text-xs text-secondary">
                    {t("paste")}
                  </span>
                )}
              </div>
              <p className="w-4/5 text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder">
                {description} <span className='text-secondary'>{email}</span>
              </p>
            </div>
          </div>
          <ErrorMsg errMsg={isError} />
          <div className="md:w-[80%] mt-6 mx-auto ">
            <Button
              type="button"
              text={t('verify')}
              handleClick={handleVerify}
              loading={isLoading}
              disabled={!otp || isLoading}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

export const AuthLoginApp = ({
  handleModal,
  formTitle,
  description,
  timer,
  setOtp,
  otp,
}: AuthAppProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isError, setError] = useState('');

  const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (otp?.length === 6) {
      
      navigate('/home/profile/security/reset-password');
     
    } else {
      setError('Please enter a valid code');
    }
  };
  
  const handleClick = async () => {
    const clipboardText = await handlePaste();
    setOtp(clipboardText || "");
  };

  return (
    <Modal
      close={true}
      title={t('securityVerification')}
      description={t('verifyToProceed')}
      handleCloseModal={handleModal}
      goBack={false}
    >
      <>
        <div className="">
          <div className="px-7 py-8 mb-8 bg-white rounded-xl  dark:bg-clrDarkBg">
            <div>
              <label
                htmlFor="verification-code"
                className="block text-[0.625rem] leading-3 text-clrGray md:text-xs font-bold mb-2"
              >
                {formTitle}
              </label>
              <div className="relative">
                <input
                  className="bg-clrBg rounded-lg w-3/4 h-11 text-base focus:outline-none px-3"
                  type="number"
                  value={otp}
                  onChange={(e) => {
                    if (isError) {
                      setError('');
                    } else {
                      setOtp(e.target.value);
                    }
                  }}
                />
                {timer ? (
                  <span className="absolute cursor-pointer top-[35%] right-[30%] text-xs text-primary">
                    {timer}
                  </span>
                ) : (
                  <span 
                    onClick={handleClick}
                    className="absolute cursor-pointer top-[35%] right-[30%] text-xs text-primary"
                  >
                    {t("paste")}
                  </span>
                )}
              </div>
              <p className="w-4/5 text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder">
                {description}
              </p>
            </div>
          </div>
          <ErrorMsg errMsg={isError} />
          <div className="md:w-[80%] mt-6 mx-auto ">
            <Button
              type="button"
              text={t('verify')}
              handleClick={handleVerify}
              loading={false}
              disabled={!otp || false}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};