import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import TableMain from "../../reusables/ReactDataTable";
import { useStakesColumns } from "./DataList";
import { useGetStakesTransactionHistoryQuery } from "../../../features/api/walletSlice";
import {MobileFilterSearch} from "../../hotspotHubs/OptionsStatus";
import useOutsideClick from "../../../Hooks/useOutsideClick";
import { EmptyCard, Filter, TableLoader } from "../../reusables";
import { useTranslation } from "react-i18next";

const Stakes: React.FC<TransationProps> = ({ show, showFilter }) => {
  const { t } = useTranslation();
  const { userId } = useAppSelector((state) => state.auth);
  const [option, toggleOption] = useState<string>("");
  const [status, setStatus] = useState<string>("All");
  const [action, setAction] = useState<string>("All");
  const [page, setPage] = useState<number>(1);
  const columnsStakes = useStakesColumns();

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  const { data: history, isLoading: gettingStakingHistory, isFetching } =
    useGetStakesTransactionHistoryQuery({
      userId,
      page,
      pageSize: 30,
      status: status === "All" ? "" : status,
      action: action === "All" ? "" : action,
    });

  useOutsideClick(modalRef, handleCloseModal);
  if (!show) return <></>;
  return (
    <div>
      {showFilter && (
        <div className="mb-3">
          <div className="flex w-full gap-3 md:px-4 md:w-auto">
            <MobileFilterSearch>
              <Filter
                options={[
                  t("all"),
                  t("stake"),
                  t("unstake"),
                  t("forceUnlock"),
                  t("claim"),
                ]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"ACTION"}
                selectFilterOption={setAction}
                selectedOption={action}
                modalRef={modalRef}
                label={t("action")}
              />
              <Filter
                options={[
                  t("all"),
                  t("pending"),
                  t("confirmed"),
                  t("failed"),
                ]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"STATUS"}
                selectFilterOption={setStatus}
                selectedOption={status}
                modalRef={modalRef}
                label={t("status")}
              />
            </MobileFilterSearch>
          </div>
        </div>
      )}
      <div>
        {gettingStakingHistory || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            showPagingation={showFilter}
            defaultData={history?.data.records || []}
            columns={columnsStakes}
            totalPages={history?.data.totalPages || 0}
            pageSize={history?.data.pageSize || 0}
            currentPage = {history?.data.currentPage || 0}
            setPage={setPage}
          />
        )}
        {!gettingStakingHistory  &&!isFetching &&
          (history?.data?.records.length ?? 0) === 0 && (
            <EmptyCard description={t("makeAStakeTransactionToViewYourHistory")} />
          )}
      </div>
    </div>
  );
};

export default Stakes;
