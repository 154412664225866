import { useState } from "react";
import { Button, ErrorMsg, Success } from "../reusables";
import Edit from "../../assets/edit.svg";
import { useRegisterBusinessMutation } from "../../features/api/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logOut } from "../../features/store/authReducerSlice";

const BusinessReg = () => {
  const dispatch = useAppDispatch();

  const { userId } = useAppSelector((state) => state.auth);

  const [businessName, setBusinessName] = useState<string>("");
  const [businessAddress, setBusinessAddress] = useState<string>("");
  const [businessLogo, setBusinessLogo] = useState<string>("");
  const [businessLogoFile, setBusinessLogoFile] = useState<File>();
  const [errorMsg, setError] = useState<string>("");

  const [registrationStatus, setRegistrationStatus] = useState<boolean>(false);

  const [registerBusiness, { isLoading }] = useRegisterBusinessMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if ((selectedFile as File).size > 256 * 1024) {
      setError("File size exceeds 256KB. Please select a smaller file.");
      return;
  }
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setBusinessLogoFile(selectedFile);
      setBusinessLogo(blobUrl);
    }
  };

  const handleBusinessRegistration = () => {
    if (!businessName) {
      setError("Please enter a business name");
    } else if (!businessAddress) {
      setError("Please enter your business address");
    } else if (!businessLogo) {
      setError("Please save a logo for your business");
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("userId", userId);
      bodyFormData.append("name", businessName);
      bodyFormData.append("logoUrl", businessLogo);
      bodyFormData.append("officeAddress", businessAddress);
      bodyFormData.append("logoFile", businessLogoFile as File);

      registerBusiness(bodyFormData)
        .unwrap()
        .then((res) => {
          if (res.success) {
            setBusinessName("");
            setBusinessAddress("");
            setBusinessLogo("");
            setRegistrationStatus(true);
          } else {
            setError(res.message);
          }
        });
    }
  };

  return (
    <section className="max-w-xl w-[90%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:pt-6 md:pb-8 md:px-12">
        <h1 className="self-start mb-6 text-sm font-semibold md:text-base text-clrTitle dark:text-white">
          Create Business Account
        </h1>
        <div className="flex flex-col items-center mb-6">
          <div className="relative">
            <img
              src={
                businessLogo
                  ? businessLogo
                  : "https://assets.hongkiat.com/uploads/psd-text-svg/logo-example.jpg"
              }
              alt="Profile picture"
              width={150}
              height={150}
              className="rounded"
            />
            <button className="absolute right-0 bottom-6">
              <div className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-clrTextBlue dark:bg-clrDarkYellow">
                <img
                  src={Edit}
                  alt="Edit your profile here"
                  width={15}
                  height={15}
                />
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleChange}
                  accept=".png, .jpeg, .jpg"
                  className="absolute overflow-hidden opacity-0 z-index--1"
                />
              </div>
            </button>
          </div>
          <div className="px-3 py-1.5 mt-3 rounded-lg bg-clrBg dark:bg-clrDarkBg2">
            <p className="font-semibold text-x8 text-clrTextBlue dark:text-clrDarkYellow">
              JPG, JPEG, and PNG only. Maximum file size: 256KB.
            </p>
          </div>
        </div>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="business-name"
              className="block mb-4 font-semibold text-x10 text-clrGray43 dark:text-clrPlaceholder opacity-80"
            >
              Business Name
            </label>
            <input
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              type="text"
              id="business-name"
              className="w-full font-semibold text-x10 text-clrGray43 border-b-[0.7px] border-solid border-[#25346a99] dark:border-white outline-none"
            />
          </div>
          <div>
            <label
              htmlFor="business-name"
              className="block mb-4 font-semibold text-x10 text-clrGray43 dark:text-clrPlaceholder opacity-80"
            >
              Merchant Address
            </label>
            <input
              type="text"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              id="business-name"
              className="w-full font-semibold text-x10 text-clrGray43 border-b-[0.7px] border-solid border-[#25346a99] dark:border-white outline-none"
            />
          </div>
          <ErrorMsg errMsg={errorMsg} />
        </div>
        <div className="w-1/2 mx-auto mt-12 [&>*]:tracking-wider md:w-2/5">
          <Button
            type="button"
            text="Register"
            loading={isLoading}
            handleClick={handleBusinessRegistration}
            textSize="text-x10"
            py="py-2"
            fontWeight="font-semibold"
          />
        </div>
        {registrationStatus && (
          <Success
            modalRef={undefined}
            handleButton={() => dispatch(logOut())}
            title="Registration Successful!"
            description="Please log out and log back in to access your business account!"
            buttonTitle={"Log Out"}
          />
        )}
      </div>
    </section>
  );
};

export default BusinessReg;
