// import React, { useState } from "react";
// import { Card, ButtonAuth, CardTitle, ErrorMsg } from "../../reusables";
// import { Link } from "react-router-dom";
// import AuthFrame from "../../reusables/AuthFrame";
// import AuthCode from "react-auth-code-input";

// interface CreatePinProps {
//   handleNext: (title?: string, value?: string) => void;
//   email: string;
// }

// const CreatePin = ({ handleNext, email }: CreatePinProps) => {
//   const [pin, setPin] = useState("");
//   const [confirnPin, setConfirmPin] = useState("");
//   const [isError, setError] = useState("");


//   const handleSubmit = () => {
//     if (pin.length !== 4) {
//       setError("Please enter a valid pin");
//     } else if (pin !== confirnPin) {
//       setError("Pins do not match!");
//     } else {
//       setError("")
//       handleNext("pin", pin);
//     }
//   };

//   const handleChange = (res: string) => {
//     if(isError){
//       setError("")
//     }
//     setPin(res);
//   };

//   const handleConfrimChange = (res: string) => {
//     if(isError){
//       setError("")
//     }
//     setConfirmPin(res);
//   };

//   return (
//     <div className="flex">
//       <Card>
//         <div className="flex items-center flex-col mt-2 min-[426px]:w-[350px] mx-auto mb-12 md:mb-8 h-full">
//           <div className="text-center md:text-left">
//             <CardTitle title="Create PIN" />
//             <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-2">
//               Welcome :{" "}
//               <span className="text-clrYellow">{email}</span>
//             </p>
//             <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-8">
//               Create a 4 digit PIN and secure your account
//             </p>
//           </div>
//           <div className="mb-4 text-center">
//             <label
//               htmlFor="#"
//               className="block text-[0.625rem] leading-3 text-clrTextBlue md:text-sm font-bold mb-4"
//             >
//               Enter new PIN
//             </label>
//             <div className="mb-4">
//               <AuthCode
//                 inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] rounded-md bg-[#f5f5f5]"
//                 containerClassName="flex justify-center gap-3"
//                 ariaLabel="verify-otp"
//                 isPassword={true}
//                 autoFocus={true}
//                 length={4}
//                 allowedCharacters="numeric"
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="text-center">
//             <label
//               htmlFor="#"
//               className="block text-[0.625rem] leading-3 text-clrTextBlue md:text-sm font-bold mb-4"
//             >
//               Confirm new PIN
//             </label>
//             <div className="mb-4">
//               <AuthCode
//                 inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] rounded-md bg-[#f5f5f5]"
//                 containerClassName="flex justify-center gap-3"
//                 ariaLabel="verify-otp"
//                 isPassword={true}
//                 autoFocus={true}
//                 length={4}
//                 allowedCharacters="numeric"
//                 onChange={handleConfrimChange}
//               />
//               <ErrorMsg errMsg={isError} />
//             </div>
//           </div>
//           <div className="mt-6 mb-8 w-[80%]">
//             <ButtonAuth type="button" handleClick={handleSubmit} text="Set Pin" />
//           </div>
//           <div>
//             <Link to="/"> ← Back to Login </Link>
//           </div>
//         </div>
//       </Card>
//       <AuthFrame />
//     </div>
//   );
// };

// export default CreatePin;

import { useState } from 'react';
import { Card, ButtonAuth, CardTitle, ErrorMsg } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import AuthCode from 'react-auth-code-input';

interface CreatePinProps {
  handleNext: (title?: string, value?: any) => void;
  email: string;
  handleBack: () => void;
}

const CreatePin = ({ handleNext, email, handleBack }: CreatePinProps) => {
  const [pin, setPin] = useState<string>('');
  const [confirnPin, setConfirmPin] = useState('');
  const [isError, setError] = useState('');

  const handleSubmit = () => {
    if (pin.length !== 4) {
      setError('Please enter a valid pin');
    } else if (pin !== confirnPin) {
      setError('Pins do not match!');
    } else {
      setError('');
      handleNext('pin', pin);
    }
  };

  const handleChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setPin(res);
  };

  const handleConfrimChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setConfirmPin(res);
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-center md:text-left">
            <CardTitle title="Create PIN" />
            <p className="text-clrText font-medium text-[0.625rem] md:text-sm mb-6">
              Welcome : <span className="text-primary">{email}</span>
            </p>
            <p className="text-clrText font-medium text-[0.625rem] md:text-sm mb-12">
              Create a 4 digit PIN and secure your account
            </p>
          </div>
          <div className="mb-4 text-center">
            <label
              htmlFor="#"
              className="block text-[0.625rem] leading-3 text-black md:text-sm font-semibold mb-4"
            >
              Enter new PIN
            </label>
            <div className="mb-4">
              <AuthCode
                // inputClassName="w-10 h-10 text-center text-lg font-semibold text-clrTextBlue border-clrTextBlue border-solid border-[1px] rounded-md bg-[#f5f5f5]"
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                containerClassName="flex justify-center gap-3"
                ariaLabel="verify-otp"
                isPassword={true}
                autoFocus={true}
                length={4}
                allowedCharacters="numeric"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="text-center">
            <label
              htmlFor="#"
              className="block text-[0.625rem] leading-3 text-black md:text-sm font-semibold mb-4"
            >
              Confirm new PIN
            </label>
            <div className="mb-4">
              <AuthCode
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                containerClassName="flex justify-center gap-3"
                ariaLabel="confirm-verify-otp"
                isPassword={true}
                autoFocus={false}
                length={4}
                allowedCharacters="numeric"
                onChange={handleConfrimChange}
              />
              <ErrorMsg errMsg={isError} />
            </div>
          </div>
          <div className="mt-6 mb-8 w-[80%]">
            <ButtonAuth
              //loading={isLoading}
              type="submit"
              handleClick={handleSubmit}
              text="Set Pin"
            />
          </div>
          <div className="mt-4 mb-10 cursor-pointer">
            <div onClick={handleBack}> ← Back </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default CreatePin;
