import { useAppSelector } from "../../app/hooks";
import TableMain from "../reusables/ReactDataTable";
import { EmptyCard, TableLoader } from "../reusables";
import { useGetReferralsQuery } from "../../features/api/authSlice";
import { useState } from "react";
import { useReferralColumn } from "./data";
import { useTranslation } from "react-i18next";

const ReferralList: React.FC = () => {
  const {t} = useTranslation();
  const referralColumn = useReferralColumn();
  const { referralCode } = useAppSelector((state) => state.auth);
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isFetching } = useGetReferralsQuery({
    code: referralCode,
    page: page,
    pageSize: 30,
  });

  return (
    <div>
      {isLoading ? (
        <TableLoader />
      ) : (
        <TableMain 
          defaultData={data?.data?.records || []} 
          columns={referralColumn} 
          totalPages={data?.data?.totalPages || 0}
          pageSize={ data?.data?.pageSize || 0}
          currentPage={data?.data?.currentPage || 0}
          setPage={setPage} 
        />
      )}
      {!isLoading && !isFetching && data?.data?.records?.length === 0 && (
        <EmptyCard description={t("emptyreferralTable")} />
      )}
    </div>
  );
};

export default ReferralList;
