import React, { ReactElement, useRef } from 'react';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/hotspot-hubs/close.svg';
import { Button } from './Button';
import { ErrorMsg } from './ErrorMsg';
import useOutsideClick from '../../Hooks/useOutsideClick';

interface ModalProps {
  handleCloseModal: () => void;
  children: ReactElement;
  title?: string;
  close?: boolean;
  description?: string;
  goBack?: boolean;
  handleGoBack?: () => void;
}

export interface ChooseModalProps {
  handleButton: () => void;
  title?: string;
  description?: string;
  buttonTitle?: string;
  icon: any;
  handleModal: () => void;
  isLoading: boolean;
  btnColor?: string;
  errMsg: string;
  close?: boolean;
}

export const Modal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
  goBack,
  handleGoBack,
}: ModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className="bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px] slide-in-left">
        {goBack && (
          <button className="mb-2 md:mb-3 btn-reset" onClick={handleGoBack}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
        )}

        <div className="flex items-start justify-between">
          {title ? (
            <div className="mb-3">
              <h1 className="mb-2 font-semibold text-black text-md dark:text-white">
                {title}
              </h1>
              <p className="text-sm font-normal md:text-xs text-clrGray dark:text-clrPlaceholder">
                {description}
              </p>
            </div>
          ) : (
            <div />
          )}

          {close && (
            <button onClick={handleCloseModal}>
              <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
            </button>
          )}
        </div>
        {children}
      </div>
    </section>
  );
};

export const Modal2: React.FC<ModalProps> = ({
  handleCloseModal,
  children,
  close,
  goBack,
  handleGoBack,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className=" bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px]">
        <div className="flex justify-between items-center">
          {goBack && (
            <button className="btn-reset" onClick={handleGoBack}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}
          {close && (
            <button onClick={handleCloseModal}>
              <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
            </button>
          )}
        </div>

        {children}
      </div>
    </section>
  );
};

export const SideModal = ({
  handleCloseModal,
  children,
  goBack,
  title,
}: ModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section
      className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center sm:justify-end md:items-start"
      ref={modalRef}
    >
      <div className="bg-white dark:bg-clrDarkBg2 flex flex-col py-6 z-30 w-11/12 px-6 md:w-[450px] h-screen slide-in-right">
        {goBack && (
          <div className="flex items-center gap-5 mb-2 md:mb-3">
            <button className="btn-reset" onClick={handleCloseModal}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
            <p className="font-bold dark:text-white"> {title}</p>
          </div>
        )}

        {children}
      </div>
    </section>
  );
};

export const ChooseModal = ({ 
  handleButton,
  handleModal,
  buttonTitle,
  title,
  description,
  icon,
  isLoading,
  btnColor,
  errMsg,
  close,
 }: ChooseModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleModal);
  return (
    <section
      className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
        <div className="flex flex-col bg-clrBg2 dark:bg-clrDarkBg2 pt-3 pb-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[370px] slide-in-left">
          {close && (
            <button onClick={handleModal} className='self-end mb-3.5' >
              <CloseIcon className="w-[10px] h-[10px] self-end dark-close" />
            </button>
          )}
          <div className="flex flex-col items-center justify-center gap-4 px-3 py-4 mb-2 bg-white rounded dark:bg-clrDarkBg">
              {icon}
              <div className="mb-4 text-center w-[90%] md:w-[80%] mx-auto">
                  <h2 className="mb-3 text-sm font-semibold text-black md:text-base dark:text-white">
                      {title}
                  </h2>
                  <p className="text-x9 md:text-x10 font-medium text-clrGray66 dark:text-clrPlaceholder">
                      {description}{" "}
                  </p>
              </div>
          </div>
          <div className='text-center'>
            <ErrorMsg errMsg={errMsg} />
          </div>
         

          <div className="md:w-[80%] mx-auto mt-2">
              <Button
                  type="button"
                  text={buttonTitle || ""}
                  handleClick={handleButton}
                  loading= {isLoading}
                  bgColor={btnColor}
              />
          </div>
        </div>
      </section>
  );
};