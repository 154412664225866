import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../Helper";
import { ClaimHistory } from "./types";

// Claim history

const columnClaimHistory = createColumnHelper<ClaimHistory>();
  
export const useClaimHistoryColumns = () => {
  const { t } = useTranslation();

  return [
    columnClaimHistory.accessor("createdAt", {
      header: t("date"),
      cell: (val) => {
        const time = val.getValue();
        return <p> {moment(time).format("MM/DD/YYYY | h:mm a")}</p>;
      },
    }),
    columnClaimHistory.accessor("walletName", {
      header: t("wallet"),
    }),
    columnClaimHistory.accessor("walletAddress", {
      header: t("address"),
    }),
    columnClaimHistory.accessor("amount", {
      header: t("amountClaimed"),
      cell: (val) => {
        const amount = val.getValue();
        return (
          <p className="font-medium md:font-normal text-clrGreen2 md:text-inherit">
            {numberWithCommas(amount)}WNT
          </p>
        );
      },
    }),
    columnClaimHistory.accessor("paymentStatus", {
      header: t("status"),
      cell: (info) => {
        const status = info?.getValue()?.toLowerCase();
        let color = "";
        if (status === "success") {
          color = "#00C087";
        } else if (status === "failed") {
          color = "#DB5F5F";
        }
        return (
          <p
            style={{
              color,
              fontWeight: "600",
            }}
          >
            {t(status)}
          </p>
        );
      },
    }),
  ];
};