import { createColumnHelper } from "@tanstack/react-table";
import { filesize } from "filesize";
import moment from "moment";
import { Button } from "../reusables";
import { useRef, useState } from "react";
import { ReasonToDeactive, TokenDetails } from "./TokenModals";
import { useTranslation } from "react-i18next";
import EnterPin from "../reusables/EnterPin";
import useOutsideClick from "../../Hooks/useOutsideClick";

export type Token = {
  dataTokenOwner: { id: string; phoneNumber: string, email: string };
  code: string;
  bytesAllocated: number;
  bytesUsed: number;
  secondsAllocated: string;
  dataTokenUsageType: string;
  voucherStatus: string;
  viewMore: string;
};




const ViewMoreButton = ({row}: {row: Token}) => {
  const {t} = useTranslation();
  const [selectedToken, setSelectedToken] = useState<Token | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEnterPin, setIsEnterPin] = useState(false);
  const [isReasonToDeactiveOpen, setIsReasonToDeactiveOpen] = useState(false);
  const [pin, setPin] = useState<string>("");

  const handleCloseDetails = () => {
    setIsModalOpen(false);
    setSelectedToken(null);
  }
  
  const modalRefEnterPin = useRef<HTMLElement>(null);
  const modalRef = useRef(null); 
  useOutsideClick(modalRef, handleCloseDetails);

  
  const handleDeactivateToken = () => {
    setIsEnterPin(true);
  };

  const handlePin = (res: string) => {
    setPin(res);
  };

  const handleCloseModal = () => {
    setIsEnterPin(false);
  };

  useOutsideClick(modalRefEnterPin, handleCloseModal);

  const handleSendOTP = () => {
    if (pin.length === 4) {
      setIsEnterPin(false);
      setIsReasonToDeactiveOpen(true);
    }
  };

  const handleFinalClose = () => {
    setIsReasonToDeactiveOpen(false);
    handleCloseDetails();
  };

  return (
    <>
      <Button
        text={t("viewMore")}
        type="button"
        handleClick={() => {
          setSelectedToken(row);
          setIsModalOpen(true);
        }}
        textSize="text-[0.5rem]"
        lineHeight="leading-[0]"
        wBtn="md:w-[70%]"
      />

      {isModalOpen && <TokenDetails
        onClose={handleCloseDetails}
        handleDeactivateToken={handleDeactivateToken}
        tokenData={selectedToken}
      />}

      {/* EnterPin Modal with higher z-index */}
      {isEnterPin && (
        <EnterPin
          handleModal={handleCloseModal}
          handleContinue={handleSendOTP}
          handleChange={handlePin}
          errMsg={""}
          resendOtp={() => console.log("resend OTP")}
          loading={false}
        />
      )}

      {/* ReasonToDeactive Modal with higher z-index */}
      {isReasonToDeactiveOpen && (
        <ReasonToDeactive
          handleModal={() => setIsReasonToDeactiveOpen(false)}
          handleContinue={handleFinalClose}
          handleChange={handlePin}
          errMsg={""}
          resendOtp={() => console.log("resend OTP")}
          loading={false}
        />
      )}
    </>
  );
};

export const defaultDataTrackerToken: Token[] = [
  {
    dataTokenOwner: { id: "hello", phoneNumber: "676", email: "@tan" },
    code: "string",
    bytesAllocated: 1,
    bytesUsed: 2,
    secondsAllocated: "string",
    dataTokenUsageType: "string",
    voucherStatus: "active",
    viewMore: "",
  },
];

const columnToken = createColumnHelper<Token>();

export const useColumnsToken = () => {
  const {t} = useTranslation();

  const columnsToken = [
    columnToken.accessor("dataTokenOwner", {
      header: t("assignedTo"),
      cell: (val) => {
        const owner = val.getValue();
        return <p>{owner?.phoneNumber ? owner?.phoneNumber : owner?.email ? owner?.email : "Not Assigned"}</p>;
      },
    }),
  
    columnToken.accessor("code", {
      header: t("token"),
    }),
  
    columnToken.accessor("bytesAllocated", {
      header: t("dataAllocated"),
      cell: (val) => {
        const bytesAllocated = val.getValue();
        return filesize(bytesAllocated || 0);
      },
    }),
  
    columnToken.accessor("bytesUsed", {
      header: t("dataUsed"),
      cell: (val) => {
        const bytesUsed = val.getValue();
        return filesize(bytesUsed || 0);
      },
    }),
  
    columnToken.accessor("secondsAllocated", {
      header: t("validityPeriod"),
      cell: (val) => {
        const validityPeriod = val.getValue();
        const duration = moment.duration(Number(validityPeriod) || 0, 'seconds');
        return <p>
        {Number(validityPeriod) === 0 ? "--" : duration.days() >31 ? (
          <span>{t("unlimited")}</span>
        ) : (
          <span>
            {duration.days() > 0 && (
              <span>
                {duration.days()} {t("day")}{duration.days() > 1 ? 's ' : ' '}
              </span>
            )}
            {duration.hours() > 0 && (
              <span>
                {duration.hours()} {t("hour")}{duration.hours() > 1 ? 's ' : ' '}
              </span>
            )}
          </span>
          
        )}
      </p>
      },
    }),
  
    columnToken.accessor("dataTokenUsageType", {
      header: t("usageType"),
    }),
    columnToken.accessor("voucherStatus", {
      header: t("status"),
      cell: (info) => {
        const status = info.getValue();
        let color = "";
        if (status.toLowerCase() === "active") {
          color = "#00C087";
        } else if (status.toLowerCase() === "exhausted") {
          color = "#DB5F5F";
        } else if (status.toLowerCase() === "inactive") {
          color = "#CFA809";
        }
  
        return (
          <p
            style={{
              color,
              fontWeight: "600",
            }}
          >
            {status}
          </p>
        );
      },
    }),
    columnToken.accessor("viewMore", {
      header: "",
      cell: (info) => {
        const row = info.row.original;
        return (
          <ViewMoreButton row={row} />
        );
      },
    }),
  ];

  return { columnsToken }
}