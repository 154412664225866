import React, { Children, ReactNode, useEffect, useState } from "react";
import { AUTH_FRAME_DATA } from "./AuthFrameData";

interface AuthFrameMainProps {
  children: ReactNode;
}

const AuthFrame: React.FC = () => {
  const [carolIndex, setCarolIndex] = useState<number>(0);

  const handleClick = (n: number) => {
    setCarolIndex((prevState) => {
      let newIndex = prevState + n;
      if (newIndex < 0) {
        newIndex = AUTH_FRAME_DATA.length - 1;
      }
      if (newIndex > AUTH_FRAME_DATA.length - 1) {
        newIndex = 0;
      }

      return newIndex;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleClick(1);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="hidden relative md:flex justify-center items-center bg-clrTextBlue basis-[55.7%] overflow-hidden">
      <div className="flex md:flex-col lg:flex-row justify-center items-center gap-4 relative z-10">
        <div className="basis-1/2 lg:basis-2/5 order-2 lg:order-1 md:px-8 lg:pl-10 lg:pr-0 xl:pl-20">
          <div className="flex justify-center lg:justify-start gap-2 mb-4">
            <div
              className={`${
                carolIndex === 0 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carolIndex === 1 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carolIndex === 2 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carolIndex === 3 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carolIndex === 4 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
          </div>
          <div className="">
            <h2 className="text-2xl lg:text-4xl font-medium text-white my-5">
              {AUTH_FRAME_DATA[carolIndex].title}
            </h2>
            <p className="text-sm lg:text-base font-normal text-white">
              {AUTH_FRAME_DATA[carolIndex].details}
            </p>
          </div>
        </div>
        <div className=" basis-3/5 order-1 lg:order-2">
          <img
            src={AUTH_FRAME_DATA[carolIndex].image}
            alt="Frame"
            className="w-[400px] lg:w-[860px]"
          />
        </div>
      </div>
      <div className="absolute bottom-2 right-8 text-[#C8D5FF] text-xs">
        <ul className="flex md:justify-between gap-x-3 flex-wrap">
          <li>
            <a href="https://wicrypt.com/help/"> Help Center</a>
          </li>
          <li>
            <a href="https://wicrypt.com/terms-condition.html">
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="https://wicrypt.com/privacy-policy.html">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="absolute  w-[95%] h-[95%] rounded-[50%] bg-[#2f80ed1a] -left-60"></div>
    </div>
  );
};

export default AuthFrame;
