import { useState } from "react";
import { useNavigate } from "react-router";
import { useDeleteAccountMutation } from "../../features/api/authSlice";
import { Button } from "../reusables/Button";
import { ErrorMsg } from "../reusables/ErrorMsg";
import { Modal } from "../reusables/Modal";
import { useTranslation } from "react-i18next";
import { ReactComponent as Delete } from "../../assets/delete.svg";

export const DeleteAccount = ({ handleModal }: { handleModal: () => void }) => {
  const {t} = useTranslation();
    const navigate = useNavigate();
    const [deleteUserAccount, {isLoading}] = useDeleteAccountMutation();
    const [errMsg, setErr] = useState<string>("");

    const handleDelete = () =>{
        deleteUserAccount()
        .unwrap()
        .then((res) => {
          if (res?.success) {
            navigate("/")
          } else {
            setErr(res?.message);
          }
        })
        .catch((err) => {
          setErr(err?.data?.message);
        });
    }

  
    return (
      <Modal
        close={true}
        description={""}
        handleCloseModal={handleModal}
      >
        <div>
          <div className="px-4 py-8 bg-white rounded dark:bg-clrDarkBg">
            <div className="flex flex-col justify-center items-center max-w-60 mx-auto">
              <div className="bg-[#FFDCDCB2] dark:bg-[#DB5F5F33] w-16 h-16 rounded-full grid place-items-center">
                <Delete />
              </div>
              <h3 className="mt-5 text-lg font-semibold text-center text-clrTextBlue dark:font-medium dark:text-white">
                {t("deleteAccount")}
              </h3>
              <p className="font-normal text-center text-xs text-clrGray dark:text-clrPlaceholder mt-4">
                {t("deleteAccountDescription")}
              </p>
              
              <div className="px-3 py-2 bg-[#25346A1A] dark:bg-clrDarkBg2 rounded-lg mt-2">
                <p className="font-normal text-center text-x8 text-clrGray dark:text-clrPlaceholder">
                  {t("deleteAccountDetails")}
                </p>
              </div>
            </div>
            <ErrorMsg errMsg={errMsg} />
          </div>
          <div className="md:w-[80%] mt-6 mx-auto ">
            <Button
              type="button"
              text={t("deleteAccount")}
              handleClick={handleDelete}
              loading={isLoading}
              textSize="text-x10"
              py="py-2 !px-6"
              fontWeight="font-normal"
              bgColor="bg-[#DB5F5F]"
              hover="hover:bg-[#DB524F]"
              textColor="dark:text-white"
            />
          </div>
        </div>
      </Modal>
    );
  };