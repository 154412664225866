import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonOutline, DropDownOptions, ErrorMsg, TableLoader } from "../reusables";
import { Modal } from "../reusables/Modal"
import {
  AddEditHubNameProps, CheckboxState, ChooseHubOrClusterProps, CreateHubFormProps, DropDownHubProps, DropdownFilterHubProps,
  EditContentModalProps, EditFormHeaderModalProps, FiterDropdownProps, HubLoginModalProps, HubLoginProps, IColor, IPreferredHubMethod, LinkHubsProps, MoreResponseModalProps, ThemeColorModalProps
} from "./types"

import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { ReactComponent as Profile } from "../../assets/hub/profile.svg";
import { ReactComponent as DataSubIcon } from "../../assets/hub/data-sub.svg";

// Question type images start
import experienceImg from "../../assets/hub/experience.svg";
import longTextImg from "../../assets/hub/long-text.svg";
import singleChoiceImg from "../../assets/hub/single-choice.svg";
// import dataSubImg from "../../assets/hub/data-sub.svg";
import dropdownFilterImg from "../../assets/hub/download-filter.svg";
import starRatingImg from "../../assets/hub/star-rating.svg";
// import uploadDocumentImg from "../../assets/hub/upload-document.svg";
// Question type images end 
import { ReactComponent as EmptyIcon } from "../../assets/hub/empty.svg";
import { ReactComponent as AddImage } from "../../assets/hub/add-image.svg";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down-Yellow.svg";
import { ContentOptions, ThemeColors, hubLinksData } from "./data";
import { QuestionType } from "./FormContent";
import { QuestionProps } from "./types";
import checkIcon from "../../assets/hub/check.svg";
import { PreviewFormExample } from "./PreviewFormExample";
import classNames from "classnames";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { useGetResponseDetailQuery } from "../../features/api/dynamicFormSlice";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { useGetClustersQuery } from "../../features/api/clusterSlice";
import { ModalRefProps } from "../reusables/types";
import { useAppSelector } from "../../app/hooks";
import { useTranslation } from "react-i18next";

// Modal for creating the name of a form
export const CreateHubForm = ({ handleModal }: CreateHubFormProps) => {

  const [formName, setFormName] = useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal
      close={true}
      title={"Create hub form"}
      handleCloseModal={handleModal}
    >
      <>
        <div className="flex flex-col gap-2 mb-4 ">
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5">
            <label
              htmlFor="formName"
              className="block pb-4 font-semibold text-black dark:text-white text-x10 md:text-xs"
            >
              Name of form
            </label>

            <input
              type="text"
              name="formName"
              placeholder="Network feedback form"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className="w-full pb-1 font-normal border-b border-solid text-clrGray placeholder:text-x11 text-x11 dark:text-clrPlaceholder border-clrBorder5 focus:border-primary dark:border-clrGray focus:outline-none dark:bg-transparent"
            />
          </div>

        </div>

        <div className="w-4/5 mx-auto mt-9">
          <Button
            text="Continue"
            type="button"
            disabled={formName ? false : true}
            handleClick={() => navigate(`/home/hub-form/form-content/${formName}`)}
          />
        </div>
      </>
    </Modal>
  )
}

export const MoreResponse = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [isMoreResponseOpen, setIsMoreResponseOpen] = useState(false);

  const modalRefMoreResponse = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsMoreResponseOpen(false);
  };

  useOutsideClick(modalRefMoreResponse, handleCloseModal);

  return (
    <>
      <Button
        text={"View"}
        type={"button"}
        handleClick={() => setIsMoreResponseOpen(!isMoreResponseOpen)}
        textSize={"text-[0.5rem]"}
        lineHeight={"leading-[0]"}
      />
      {isMoreResponseOpen && (
        <MoreResponseModal modalRef={modalRefMoreResponse} handleCloseModal={handleCloseModal} id={id} />
      )}
    </>
  );
}

interface ResponsePayload {
  [key: string]: string;
}


export const MoreResponseModal = ({ modalRef, handleCloseModal, id }: MoreResponseModalProps) => {

  const { data: responseDetail, isLoading: gettingResponseDetail } = useGetResponseDetailQuery(id as string);

  const renderResponse = () => {
    const response: ResponsePayload = responseDetail?.data?.responsePayload || {}; // Added missing closing parenthesis

    let responses = []

    for (const key in response) {
      // Check if the property belongs to the object itself and not its prototype chain
      if (response.hasOwnProperty(key)) {
        responses.push(
          <div className="flex gap-2">
            <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary bg-opacity-10">
              <DataSubIcon />
            </div>
            <div>
              <p className="mb-3 text-xs font-normal text-clrGray">{key}</p>
              <p className="text-xs font-semibold text-black">{response[key]}</p>
            </div>
          </div>
        )
      }
    }
    return responses
  };


  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
      <div className="fixed top-0 right-0 z-30 h-screen px-10 py-12 bg-white min-w-fit">
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={handleCloseModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            More Response
          </h3>
        </div>
        <div className="flex items-center gap-4 mb-10">
          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-clrPlaceholder">
            <Profile className="w-5 h-5" />
          </div>
          <p className="text-[0.625rem] font-semibold md:text-xs text-clrGray dark:text-white">
            {responseDetail?.data?.user?.email}
          </p>
        </div>
        <div className="grid gap-11">
          {gettingResponseDetail ? <TableLoader /> : renderResponse()}
        </div>

      </div>
    </div>
  );
};

export const EditDeleteOptions = ({ modalRef }: ModalRefProps) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const handleCloseModal = () => {
    setIsEdit(false);
  };
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <div>
      <div className="absolute z-30 flex flex-col items-start gap-6 px-6 py-3 bg-white rounded top-10 w-fit right-5">
        <button onClick={() => { setIsEdit(!isEdit) }} className="block text-xs font-normal text-[#6B6B6B]">Edit Hub</button>
        <button className="block text-xs font-normal text-clrRed">Delete Hub</button>
      </div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
      {isEdit && (
        <AddEditHubName handleCloseModal={handleCloseModal} />
      )}
    </div>
  );
};

//Component to handle setting add or edit hub name 
export const AddEditHubName = ({ handleCloseModal }: AddEditHubNameProps) => {
  const { t } = useTranslation();
  // const [errMsg, setErr] = useState<string>("");

  return (
    <Modal
      close={true}
      title={"Edit Hub"}
      description={"Edit your hub Details"}
      handleCloseModal={handleCloseModal}
      goBack={false}
      handleGoBack={() => { }}
    >
      <>
        <div className="relative p-4 mb-5 bg-white dark:bg-clrDarkBg">
          <label className="block mb-2 text-[10px] font-semibold text-black dark:text-white">
            Hub name
          </label>
          <div className="relative">
            <input type="text" placeholder="Polomall1" className="relative flex items-center justify-between w-full text-[0.625rem] border-b border-solid placeholder:text-xs border-clrBorder focus:outline-none focus:border-primary" />
            <p className="absolute block text-[0.625rem] text-primary right-0 top-[-0.07rem]">Edit</p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-5 px-2 py-6 bg-white dark:bg-clrDarkBg">
          <p className="text-[10px] font-semibold text-[#212121] dark:text-white">
            Set billing rate
          </p>
          <p className="text-[10px] font-normal text-[#b6b6b6] dark:text-clrPlaceholder">
            Tap to enter rate
          </p>
          <div className="flex">
            <input
              className="w-1/2 text-5xl font-extrabold text-right text-black outline-none dark:placeholder:!text-white dark:text-white dark:bg-clrDarkBg"
              type="number"
              placeholder="0"
              onChange={() => { }}
            />
            <span className="self-end pb-4 text-[10px] font-semibold text-[#7d7d7d] dark:text-clrPlaceholder">
              {" "}
              NGN/MB
            </span>
          </div>
        </div>
        <ErrorMsg errMsg={''} />
        <div className="w-[80%] mt-8 mx-auto ">
          <Button
            text="Continue"
            type="button"
            handleClick={() => { }}
          />
        </div>
      </>
    </Modal>
  );
};

// Filter for dropdown with border bottom
export const FilterDropdown = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}: FiterDropdownProps) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="hidden">
        {label}:
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="relative flex items-center justify-between h-8 border-b border-solid min-w-48 border-primary dark:border-clrGray"
      >
        <p className="text-[10px] font-normal text-clrPlaceholder">
          {selectedOption}
        </p>
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownOptions
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};

export const DropDownOptionsHubs = ({
  list,
  modalRef,
  handleSelect,
  closeDropdown,
  widthFull,
}: DropDownHubProps) => {
  return (
    <div>
      <div className={`absolute z-30 ${widthFull ? "w-full" : "w-48"} p-3 pb-4 overflow-hidden bg-white rounded dark:bg-clrDarkBg whitespace-nowrap text-ellipsis`}>
        <ul className="space-y-6 ">
          {list.map((item) => {
            return (
              <li
                key={item.label}
                onClick={() => {
                  handleSelect(item);
                  closeDropdown("");
                }}
                className="flex items-center justify-start text-xs font-normal transition duration-150 cursor-pointer text-clrGray dark:text-clrPlaceholder hover:text-clrYellow dark:hover:text-clrYellow"
              >
                <div className="flex items-center justify-center w-6 h-6 mr-3 rounded-full bg-primary bg-opacity-10">
                  <img src={item.image} alt={item.label} className="w-5 h-5" />
                </div>
                {item.label}
              </li>
            );
          })}
        </ul>
      </div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
    </div>
  );
};

export const FilterHub = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}: DropdownFilterHubProps) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-black dark:text-clrPlaceholder">
        {label}
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="relative flex items-center justify-between w-full h-8 border-b border-solid border-primary dark:border-clrGray"
      >
        <div className="flex items-center gap-3">
          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-primary bg-opacity-10">
            <img src={selectedOption?.image || experienceImg} alt={selectedOption?.label} className="w-5 h-5" />
          </div>
          <p className="text-xs font-normal text-black dark:text-white">
            {selectedOption?.label}
          </p>
        </div>
        <ArrowDownIcon className="w-5 h-5" />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownOptionsHubs
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          widthFull={true}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};


type IOpton = {
  label: string;
  image: string
}
export const EditContentModal = ({ modalRef, handleCloseModal, id, handleOptionChange, form, opacity }: EditContentModalProps) => {
  const [selectedOption, setSelectedOption] = useState<IOpton>({
    label: "Short Text",
    image: experienceImg
  });
  const [option, toggleOption] = useState<string>("");
  const [maxStatus, toggleMaxCharacters] = useState<boolean>(false);
  const [isRequired, toggleIsRequired] = useState<boolean>(true);
  const [maxCharacters, setMaxCharacters] = useState<number>(0)
  const modalRefFilter = useRef<HTMLElement>(null);

  const { t } = useTranslation();

  const handleModal = () => {
    toggleOption("");
  };

  const handleOptionSelect = (label: string, image: string) => {
    setSelectedOption({ label: label as QuestionType, image });
  };

  const handleProceed = () => {
    handleOptionChange(selectedOption.label as QuestionType, isRequired, maxCharacters);
    handleCloseModal()
  }

  useOutsideClick(modalRefFilter, handleModal);

  useEffect(() => {
    if (form) {
      toggleIsRequired(form?.required || false);
      setMaxCharacters(form?.maxLength?.length || 0);
      toggleMaxCharacters(form?.maxLength?.isEnabled || false);
      setSelectedOption(ContentOptions[form.type] as IOpton)
      setMaxCharacters(form?.maxLength?.length || 0)

    }
  }, [form])

  const handleSetMaxCharacter = (e: { target: { value: any; }; }) => {
    const inputValue = Number(e.target.value);
    if ((selectedOption.label === "Short Text" && inputValue > 256) ||
      (selectedOption.label === "Long Text" && inputValue > 500)) {
      // If the input exceeds the maximum allowed characters, do not update the state
      return;
    }
    setMaxCharacters(inputValue);
  }

  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className={`fixed ${opacity} top-0 right-0 z-30 flex flex-col w-full h-screen px-4 py-12 bg-white dark:bg-clrDarkBg md:px-10 md:w-80 slide-in-right`} id={id}>
        <div className="flex items-center gap-2 mb-6" >
          <button className="btn-reset" onClick={handleCloseModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            Edit Content
          </h3>
        </div>

        <div className="grid gap-11 mt-14">
          <FilterHub
            modalRef={modalRefFilter}
            options={[
              { label: "Short Text", image: experienceImg },
              { label: "Long Text", image: longTextImg },
              { label: "Single Choice", image: singleChoiceImg },
              // { label: "Multiple Choice", image: dataSubImg },
              { label: "Dropdown", image: dropdownFilterImg },
              { label: "Ratings", image: starRatingImg },
              // { label: "Upload Document", image: uploadDocumentImg },
              // { label: "Prized Items", image: prizedItemsImg }
            ]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={"TYPE"}
            selectFilterOption={(val) => handleOptionSelect(val.label, val.image)}
            selectedOption={selectedOption}
            label={"Type"}
          />
          <div className="">
            {selectedOption?.label !== "Upload Document" && <h3 className="text-xs font-semibold text-clrGray dark:text-clrPlaceholder">Settings</h3>}
            {selectedOption?.label !== "Upload Document" && selectedOption?.label !== "Prized Items" && <div className="flex justify-between mt-10">
              <label htmlFor="" className="text-xs font-normal text-black dark:text-white">Required</label>
              <div className="flex items-center gap-2">
                <label className="hub_switch">
                  <p className="hidden">Enabled</p>
                  <input
                    type="checkbox"
                    id="toggle-mode"
                    checked={isRequired}
                    onChange={() => toggleIsRequired(!isRequired)}
                  />
                  <span className="hub_switch__toggle"></span>
                </label>
                <p className="text-xs font-normal text-clrGray">{isRequired ? "Enabled" : "Disabled"}</p>
              </div>
            </div>}

            {(selectedOption?.label === "Short Text" || selectedOption?.label === "Long Text") && <div className="flex justify-between mt-10">
              <label htmlFor="" className="text-xs font-normal text-black dark:text-white">Max Characters</label>
              <div className="flex items-center gap-2">
                <label className="hub_switch">
                  <p className="hidden">Enabled</p>
                  <input
                    type="checkbox"
                    id="toggle-mode"
                    checked={maxStatus}
                    onChange={() => toggleMaxCharacters(!maxStatus)}
                  />
                  <span className="hub_switch__toggle"></span>
                </label>
                <p className="text-xs font-normal text-clrGray">{maxStatus ? "Enabled" : "Disabled"}</p>
              </div>
            </div>}

            {/* {(selectedOption.label === "Dropdown" || selectedOption.label === "Prized Items") && <div className="flex justify-between mt-10">
              <label htmlFor="" className="text-xs font-normal text-black">Alphabetical Order</label>
              <div className="flex items-center gap-2">
                <label className="hub_switch">
                  <p className="hidden">Enabled</p>
                  <input
                    type="checkbox"
                    id="toggle-mode"
                  />
                  <span className="hub_switch__toggle"></span>
                </label>
                <p className="text-xs font-normal text-clrGray">Enabled</p>
              </div>
            </div>} */}
          </div>

          {selectedOption?.label === "Checkbox" && <div className="flex items-center justify-between">
            <h3 className="text-xs font-normal text-black">Image</h3>
            <div className="flex items-center gap-2">
              {/* <label htmlFor="">Required</label> */}
              <AddImage className="w-4 h-4" />
              <p className="text-xs font-normal text-clrGray">Add Image</p>
            </div>
          </div>}

          {(selectedOption?.label === "Short Text" || selectedOption?.label === "Long Text") &&
            maxStatus &&
            <div>
              <input
                type="number"
                name=""
                id=""
                value={maxCharacters === 0 ? '' : maxCharacters}
                onChange={(e) => handleSetMaxCharacter(e)}
                className="w-full pb-1 text-xs dark:text-white font-normal border-b border-solid border-primary dark:border-secondary bg-transparent focus:outline-none"
                placeholder={`0-${selectedOption?.label === "Short Text" ? "256" : "500"} words`}
              />
            </div>
          }

        </div>

        <div className="flex flex-col justify-start gap-3 mt-auto md:w-fit">
          <Button
            type="button"
            text={"Proceed"}
            handleClick={handleProceed}
            fontWeight="font-normal"
            bgColor="dark:bg-secondary"
            textColor="dark:text-white"
          />
        </div>

      </div>
    </div>
  );
};

export const TypeOfText = ({ modalRef }: ModalRefProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
      <div className="absolute z-30 flex flex-col items-start gap-6 px-6 py-3 bg-white rounded top-10 w-fit right-5">
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('shortText')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('longText')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('singleChoice')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('multipleChoice')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('dropdown')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('ratings')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('uploadDocument')}</button>
        </div>
        <div className="flex gap-2">
          <DataSubIcon />
          <button className="block text-xs font-normal text-[#6B6B6B]">{t('pricedItems')}</button>
        </div>
      </div>
    </div>
  )
}


// Link hub
export const LinkHub = ({ handleCloseModal }: AddEditHubNameProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      close={true}
      title={t('linkHubsTitle')}
      description={t('selectPreferredMethod')}
      handleCloseModal={handleCloseModal}
      goBack={false}
      handleGoBack={() => { }}
    >
      <div>
        <button className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded ">
          <div>
            <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
              {t('linkViaHubs')}
            </h3>
            <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
              {t('selectIndividualHubs')}
            </p>
          </div>
          <img
            src={ArrowDown}
            alt="more options"
            width={20}
            height={20}
            className="-rotate-90"
          />
        </button>
        <button className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded ">
          <div>
            <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
              {t('linkViaClusters')}
            </h3>
            <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
              {t('linkHubsUnderCluster')}
            </p>
          </div>
          <img
            src={ArrowDown}
            alt="more options"
            width={20}
            height={20}
            className="-rotate-90"
          />
        </button>
      </div>
    </Modal>
  );
};


export const HubLoginDropdown = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
  details,
}: HubLoginProps) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 text-xs font-semibold text-clrGray dark:text-clrPlaceholder">
        {label}
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="relative flex items-center justify-between w-full h-8 pb-1 border-b border-solid border-primary dark:border-clrGray"
      >
        <p className="text-xs font-normal text-black dark:text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDownIcon className="w-5 h-5" />
      </button>
      <p className="text-[10px] font-normal text-clrGray dark:text-clrPlaceholder mt-1">{details}</p>
      {selectedFilterOption === currentFilterOption && (
        <DropDownOptions
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
          widthFull={true}
        />
      )}
    </div>
  );
};

// Hub login access
export const HubLoginAccess = ({ modalRef, handleLoginModal, handleProceed, loginDetail, medium }: HubLoginModalProps) => {
  const { t } = useTranslation();
  const [option, toggleOption] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [loginType, setLoginType] = useState<string>("HubLoginCode");

  const [tokenValidity, setTokenValidity] = useState<string>("days");
  const [dataUnitType, setDataUnitType] = useState<string>("GB");
  const [dataVolume, setDataVolume] = useState<string>("");
  const [validityPeriod, setValidityPeriod] = useState<string>("1");

  const modalFilterRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalFilterRef, handleCloseModal);

  const handleSubmit = () => {
    setErrMsg("")
    let tokenDetail;
    if (loginType === "None") {

      if ((tokenValidity === "days" || tokenValidity === "hours") && !validityPeriod) {
        setErrMsg("How long do you want the access to last?")
      } else if (tokenValidity === "data" && (!dataVolume || !dataUnitType)) {
        setErrMsg("How much data do you want to allocate?")
      } else {
        tokenDetail = {
          type: tokenValidity,
          period: validityPeriod,
          unit: dataUnitType,
          volume: dataVolume
        }
        handleLoginModal();
        handleProceed(loginType, tokenDetail as any)
      }
    } else {
      tokenDetail = {
        type: tokenValidity,
        period: null,
        unit: null,
        volume: null
      }
      handleLoginModal();
      handleProceed(loginType, tokenDetail as any)
    }

  }

  function generateOptions(maxValue: number): string[] {
    const options: string[] = [];
    for (let i = 1; i <= maxValue; i++) {
      options.push(i.toString());
    }
    return options;
  }

  useEffect(() => {
    if (loginDetail) {
      setLoginType(medium);
      setTokenValidity(loginDetail?.type);
      setDataUnitType(loginDetail?.unit);
      setDataVolume(loginDetail?.volume);
      setValidityPeriod(loginDetail?.period)
    }
  }, [loginDetail])


  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className="fixed top-0 right-0 z-30 flex flex-col w-full h-screen px-4 py-12 bg-white dark:bg-clrDarkBg md:px-10 md:w-96">
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={handleLoginModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            {t("hubLoginAccess")}
          </h3>
        </div>
        <div className="grid mt-6 gap-9">
          <HubLoginDropdown
            modalRef={modalFilterRef}
            options={["HubLoginCode", "Token", "Both", "None"]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={"LOGINTYPE"}
            selectFilterOption={setLoginType}
            selectedOption={loginType}
            label={"Login type"}
            details={"How do you want users to login"}
          />
          {((loginType === "None")) && <div>
            <div className="mb-10">
              <h3 className="text-xs font-semibold text-clrGray">{t("settings")}</h3>
              <div className="flex gap-8 mt-8">
                <p className="self-start text-xs font-semibold text-black">{t("validity")}</p>
                <div className="grid grid-cols-2 gap-x-12 gap-y-4">
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    onClick={() => {
                      setTokenValidity("days");
                      setDataVolume("");
                      setValidityPeriod("")
                      setErrMsg("")
                    }}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "days"}
                    />
                    {tokenValidity === "days" && (
                      <div className="circle !left-[0.38em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      {t("days")}
                    </label>
                  </div>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    onClick={() => {
                      setTokenValidity("hours");
                    }}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "hours"}
                    />
                    {tokenValidity === "hours" && (
                      <div className="circle !left-[0.2em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      {t("hours")}
                    </label>
                  </div>
                  <div
                    className="relative flex items-center gap-2 radio-input"
                    onClick={() => {
                      setTokenValidity("data");
                      // setValidityPeriod("");
                    }}
                  >
                    <input
                      type="radio"
                      name="validity"
                      id=""
                      className=""
                      checked={tokenValidity === "data"}
                    />
                    {tokenValidity === "data" && (
                      <div className="circle !left-[0.38em] !top-[0.2em] !absolute"></div>
                    )}
                    <label
                      htmlFor=""
                      className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                    >
                      {t("data")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {tokenValidity === "days" &&
              <HubLoginDropdown
                modalRef={modalFilterRef}
                options={generateOptions(30)}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"VALIDITY"}
                selectFilterOption={setValidityPeriod}
                selectedOption={validityPeriod}
                label={"Validity Period"}
                details={"How long do you want the access to last"}
              />}
            {tokenValidity === "hours" &&
              <HubLoginDropdown
                modalRef={modalFilterRef}
                options={generateOptions(24)}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"VALIDITY"}
                selectFilterOption={setValidityPeriod}
                selectedOption={validityPeriod}
                label={"Validity Period"}
                details={"How long do you want the access to last"}
              />}
            {tokenValidity === "data" && <div className="flex flex-col gap-8">
              <HubLoginDropdown
                modalRef={modalFilterRef}
                options={["TB", "GB", "MB"]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={"DATAUNIT"}
                selectFilterOption={setDataUnitType}
                selectedOption={dataUnitType}
                label={"Data Unit"}
                details={""}
              />
              <div className="relative mb-4 md:mb-0">
                <h4 className="mb-2 text-xs font-semibold text-clrGray">{t("dataVolume")}</h4>
                <input
                  type="number"
                  className="relative flex items-center justify-between w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none border-primary dark:border-clrGray focus:outline-none"
                  placeholder=""
                  value={dataVolume}
                  onChange={(e) => setDataVolume(e.target.value)}
                />
                <p className="text-[10px] font-normal text-clrGray mt-1">{t("dataVolumnDetails")}</p>
              </div>
            </div>}
          </div>}
        </div>
        <ErrorMsg errMsg={errMsg} />

        <div className="flex flex-col justify-start gap-3 mt-auto md:w-fit">
          <Button
            type="button"
            text={t("proceed")}
            handleClick={handleSubmit}
            fontWeight="font-normal"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * LinkHubsModal component.
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle modal close.
 * @param handleHubs- Function to handle link via hubs.
 * @param handleClusters - Fundtion to handle link via cluster.
 * @returns React functional component.
 */
export const LinkHubsModal = ({
  handleCloseModal,
  handleHubs,
  handleClusters,
}: LinkHubsProps) => {
  return (
    <Modal
      close={true}
      title={"Link Hubs"}
      description={"Select preferred method"}
      handleCloseModal={handleCloseModal}
    >
      <div className="space-y-2">
        {hubLinksData.map(link => {
          return (
            <button
              onClick={link.channel === "hubs" ? handleHubs : handleClusters}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
            >
              <div>
                <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                  {link.name}
                </h3>
                <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                  {link.description}
                </p>
              </div>

              <img
                src={ArrowDown}
                alt="more options"
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          )
        })}
      </div>
    </Modal>
  );
};

// Connected Hubs
export const ConnectedHubsModal = ({ modalRef, handleCloseModal, handleHubList }: MoreResponseModalProps) => {
  const [deviceStatusList, setIsCheckboxChecked] = useState<CheckboxState>({});
  // const [isSelectAll, setSelectApp] = useState<boolean>(true);
  const [deviceToSearch, setDeviceSearch] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetDevicesQuery({
    deviceName: deviceToSearch,
    deviceStatus: "All",
    page: 1,
    pageSize: 30,
  });

  const handleCheckboxChange = (id: string) => {
    setIsCheckboxChecked(prevState => ({
      ...prevState,
      [id]: !prevState[id] // Toggle the checkbox value
    }));
  };

  // const handleSelectAll = () =>{
  //   data?.data?.records.map((each)=>{
  //     handleCheckboxChange(each.id)
  //   })
  //   setSelectApp(!isSelectAll)
  // }

  const handleReset = () => {
    data?.data?.records.map((each) => {
      setIsCheckboxChecked(prevState => ({
        ...prevState,
        [each.id]: false
      }));
    })
    // setSelectApp(true)
  }

  const handleSave = () => {
    const devicesWithTrueStatus = Object.entries(deviceStatusList)
      .filter(([_id, status]) => status === true)
      .map(([id]) => id);
    if (devicesWithTrueStatus.length > 0) {
      handleCloseModal();
      if (handleHubList) {
        handleHubList(devicesWithTrueStatus)
      }
    } else {
      setErrorMsg("Select the devices you want this form applied to")
    }
  }

  return (
    <div>
      <section
        className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
      <div className="fixed top-0 right-0 z-50 flex flex-col w-full h-screen px-4 py-12 bg-white md:px-10 md:w-96">
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={handleCloseModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            Connected Hubs
          </h3>
        </div>
        <p className="mt-2 text-xs font-normal text-clrGray">Select hubs this form will be applied on</p>

        <div className="flex flex-col gap-10 mt-5">
          {(data?.data?.records.length || 0) > 0 &&
            <>
              <div className="flex items-center justify-between">
                <p className="text-base font-semibold text-clrGray">Select Hubs</p>
                {/* <button 
              type="button" 
              className="flex gap-2" 
              onClick={()=> handleSelectAll()}
            >
              {isSelectAll ? 
                <p className="text-[0.625rem] font-semibold text-primary">Select all</p> :
                <p className="text-[0.625rem] font-semibold text-primary">De-select all</p>
              }
            </button> */}
              </div>
              <div className="mt-1.5">
                <label htmlFor="" className="hidden">Search</label>
                <input
                  type="text"
                  name="" id=""
                  placeholder="Search"
                  value={deviceToSearch}
                  onChange={(e) => setDeviceSearch(e.target.value)}
                  className="relative flex items-center justify-between w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
              </div>
            </>}
          <div className="space-y-6">
            {isLoading && <TableLoader />}

            {data?.data?.records.map((hubs) => {
              return (
                <label
                  key={hubs?.id}
                  className="material-checkbox text-[10px] font-normal text-black"
                >
                  {hubs?.name}
                  <input
                    checked={deviceStatusList[hubs?.id]}
                    type="checkbox"
                    onChange={() => handleCheckboxChange(hubs?.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              )
            })}
          </div>
          <ErrorMsg errMsg={errorMsg} />
          {!isLoading && !isFetching && (data?.data?.records.length || 0) === 0 &&
            <EmptyHubCluster
              type="hubs"
              message="You have no hubs"
              actionBtn="Buy a hub"
              link="http://shop.mtn.ng/"
            />}
        </div>
        {(data?.data?.records.length || 0) > 0 &&
          <div className="flex justify-between mt-auto">
            <Button
              type="button"
              text={"Save"}
              handleClick={handleSave}
              fontWeight="font-normal"
              wBtn="md:w-[8rem]"
            />
            <ButtonOutline
              type="button"
              text={"Reset"}
              handleClick={handleReset}
              fontWeight="font-normal"
              wBtn="md:w-[8rem]"
            />
          </div>
        }
      </div>
    </div>
  );
};

// Connected Cluster
export const ConnectedClusterModal = ({ modalRef, handleCloseModal, handleHubList }: MoreResponseModalProps) => {
  const { t } = useTranslation();

  const { businessId } = useAppSelector((state) => state.auth);
  // const [isSelectAll, setSelectApp] = useState<boolean>(true);
  // const [page, setPage] = useState<number>(1);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<CheckboxState>({});

  const { data: clusters, isLoading, isFetching } = useGetClustersQuery({
    page: 1,
    pageSize: 30,
    businessId: businessId
  });

  const handleReset = () => {
    clusters?.data?.records.map((each) => {
      setIsCheckboxChecked(prevState => ({
        ...prevState,
        [each.deviceListId]: false
      }));
    })
    // setSelectApp(true)
  }

  const handleCheckboxChange = (id: string) => {
    setIsCheckboxChecked(prevState => {
      const newState = { ...prevState };
      // Set all checkboxes to false
      Object.keys(newState).forEach(key => {
        newState[key] = false;
      });
      // Set the checkbox with the specified id to true
      newState[id] = true;
      return newState;
    });
  };

  // const handleSelectAll = () =>{
  //   clusters?.data?.records.map((each)=>{
  //     handleCheckboxChange(each.deviceListId)
  //   })
  //   setSelectApp(!isSelectAll)
  // }

  const handleSave = () => {
    const devicesWithTrueStatus = Object.entries(isCheckboxChecked)
      .filter(([_id, status]) => status === true)
      .map(([id]) => id);

    if (devicesWithTrueStatus.length > 0) {
      handleCloseModal();
      if (handleHubList) {
        handleHubList(devicesWithTrueStatus)
      }
    } else {
      setErrorMsg("Select clusters with devices you want this form applied to")
    }
  }


  return (
    <div>
      <section
        className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className="fixed top-0 right-0 z-50 flex flex-col w-full h-screen px-4 py-12 bg-white dark:bg-clrDarkBg md:px-10 md:w-96">
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={handleCloseModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            {t("connectedClusters")}
          </h3>
        </div>
        <p className="mt-2 text-xs font-normal text-clrGray dark:text-clrPlaceholder">{t("connectedClustersDetails")}</p>

        <div className="flex flex-col gap-10 mt-5">
          <div className="flex items-center justify-between">
            <p className="text-base font-semibold text-clrGray dark:text-white">{t("selectCluster")}</p>
            {/* <button 
              type="button" 
              className="flex gap-2" 
              onClick={()=> handleSelectAll()}
            >
              {isSelectAll ? 
                <p className="text-[0.625rem] font-semibold text-primary">Select all</p> :
                <p className="text-[0.625rem] font-semibold text-primary">De-select all</p>
              }
            </button> */}
          </div>
          <div className="mt-1.5">
            <label htmlFor="" className="hidden">Search</label>
            <input type="text" name="" id="" placeholder="Search" className="relative flex items-center justify-between dark:bg-transparent w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
          </div>
          {isLoading && <TableLoader />}
          {clusters?.data?.records && <div className="space-y-6 overflow-y-scroll h-80">
            {clusters?.data?.records.map((cluster) => (
              <label
                key={cluster.deviceListId}
                className="material-checkbox text-[10px] font-normal text-black"
              >
                {cluster.listName}
                <input
                  checked={isCheckboxChecked[cluster.deviceListId]}
                  type="checkbox"
                  onChange={() => handleCheckboxChange(cluster.deviceListId)}
                />
                <span className="checkmark"></span>
              </label>
            ))
            }
          </div>}
          <ErrorMsg errMsg={errorMsg} />
          {!isLoading && !isFetching && (clusters?.data?.records.length || 0) === 0 &&
            <EmptyHubCluster
              type="hubs"
              message="You have no Ckusters"
              actionBtn="Add devices to cluser"
              link="/home/cluster"
            />}
        </div>

        <div className="flex justify-end gap-3 mt-auto md:w-fit">
          <Button
            type="button"
            text={t("Save")}
            handleClick={handleSave}
            fontWeight="font-normal"
            wBtn="md:w-[8rem]"
          />
          <ButtonOutline
            type="button"
            text={t("reset")}
            handleClick={handleReset}
            fontWeight="font-normal"
            wBtn="w-full md:w-[8rem]"
          />
        </div>

      </div>
    </div>
  );
};

export const EmptyHubCluster = ({ type, message, actionBtn, link }: { type: string, message: string, actionBtn: string, link: string }) => {
  
  let navigate = useNavigate()
  return (
    <div className="flex flex-col justify-center items-center w-[12rem] mx-auto mt-12">
      <EmptyIcon />
      <p className="mt-6 text-xs font-normal text-clrGray mb-9">{message}</p>
      {type === "hubs" ?
        <a href={link} target="_blank" rel="noreferrer">
          <Button
            type="button"
            text={actionBtn}
            handleClick={() => { }}
            fontWeight="font-normal"
          />
        </a> :
        <Button
          type="button"
          text={actionBtn}
          handleClick={() => navigate(link)}
          fontWeight="font-normal"
        />
      }
    </div>
  )
}



export const ContentOption = ({
  modalRef,
  handleDelete
}: QuestionProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="absolute right-0 z-30 w-32 p-3 pb-4 bg-white rounded dark:bg-clrDarkBg">
        <ul className="space-y-2 text-xs font-normal text-[#6b6b6b] dark:text-clrPlaceholder ">
          <li
            onClick={handleDelete}
            className="text-[#DB5F5F] text-left cursor-pointer py-1 rounded hover:bg-clrBorder dark:hover:bg-clrDarkBg2 transition duration-150 ease-in"
          >
            Delete
          </li>
        </ul>
      </div>

      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
        ref={modalRef}
      ></section>
    </div>
  );
};


/**
 * EditFormTitle renders a modal for editing a form title.
 * 
 * Props:
 * - handleModal: Function to close the modal.
 * 
 * State:
 * - formName: Current name of the form.
 * 
 * Features:
 * - Input field for the form name.
 * - Save button, enabled when formName is not empty.
 * - Navigates to a new route with formName in the URL on save.
 */
export const EditFormTitle = ({ handleModal }: CreateHubFormProps) => {
  const { t } = useTranslation();

  const [formName, setFormName] = useState<string>("");
  const navigate = useNavigate();

  const handleFormName = () => {
    navigate(`/home/hub-form/form-content/${formName}`);
    handleModal();
  }

  return (
    <Modal
      close={true}
      title={"Edit Form Title"}
      handleCloseModal={handleModal}
    >
      <>
        <div className="flex flex-col gap-2 mb-4 ">
          <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5">
            <label
              htmlFor="formName"
              className="block pb-4 font-semibold text-black dark:text-white text-x10 md:text-xs"
            >
              Name of form
            </label>

            <input
              type="text"
              name="formName"
              placeholder="Edit form name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
              className="w-full pb-1 font-normal border-b border-solid text-clrGray placeholder:text-x11 text-x11 dark:text-clrPlaceholder border-clrBorder5 focus:border-primary dark:border-clrGray focus:outline-none dark:bg-transparent"
            />
          </div>

        </div>

        <div className="w-4/5 mx-auto mt-9">
          <Button
            text="Save"
            type="button"
            disabled={formName ? false : true}
            handleClick={handleFormName}
          />
        </div>
      </>
    </Modal>
  )
}

/**
 * EditFormHeaderModal renders a modal for editing the header and description of a form.
 * 
 * Props:
 * - modalRef: Reference to the modal element.
 * - handleCloseModal: Function to close the modal.
 * - formQuestions: Array of form questions for preview.
 * - imageUrl: URL of the image for the form preview.
 * 
 * Features:
 * - Overlay section to create a modal background.
 * - Header section with a back button and title.
 * - Preview of the form with given questions and image.
 * - Input fields for editing the header text and description.
 * - Save button to trigger save functionality.
 */
export const EditFormHeaderModal = ({ modalRef, handleCloseModal, formQuestions, imageUrl }: EditFormHeaderModalProps) => {

  const [isOpen, setIsOpen] = useState(true);

  const handleSave = () => { };

  const closeModal = () => {
    setIsOpen(false);
    setTimeout(handleCloseModal, 300); // Delay the actual close to allow the animation to complete
  };
  const { t } = useTranslation();

  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className={`fixed top-0 right-0 z-30 flex flex-col w-full h-screen px-4 py-12 bg-white dark:bg-clrDarkBg md:px-10 md:w-96 overflow-y-auto ${isOpen ? 'slide-in-right' : 'slide-out-right'}`}>
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={closeModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            {t("headerDescription")}
          </h3>
        </div>

        <PreviewFormExample formQuestions={formQuestions} imageUrl={imageUrl} />

        <div className="flex flex-col gap-6 mt-12">
          <div className="">
            <label htmlFor="" className="text-sm font-medium text-clrGray dark:text-clrPlaceholder">{t("headerText")}</label>
            <input type="text" name="" id="" placeholder="Welcome to Alex's Space" className="relative dark:bg-transparent flex items-center justify-between w-full h-8 pb-1 text-sm font-normal text-black dark:text-white border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
            <span className="block mt-2 text-xs font-normal text-clrGray dark:text-clrPlaceholder">0/10 words</span>
          </div>

          <div className="">
            <label htmlFor="" className="block mb-3 text-sm font-medium text-clrGray dark:text-clrPlaceholder">{t("descriptionWord")}</label>
            <textarea name="" id="" className="w-full rounded-lg h-36 bg-clrBg dark:bg-clrDarkBg2 placeholder:!text-[#B1B1B1] dark:text-white placeholder:!text-xs pt-4 pl-6" placeholder="Enter description here"></textarea>
            <span className="block mt-1 text-xs font-normal text-clrGray dark:text-clrPlaceholder">0/30 words</span>
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-auto md:w-fit">
          <Button
            type="button"
            text={t("save")}
            handleClick={handleSave}
            fontWeight="font-normal"
            wBtn="md:w-[8rem]"
          />
        </div>

      </div>
    </div>
  );
};


/**
 * ThemeColorModal renders a modal for selecting a theme color for a form.
 * 
 * Props:
 * - modalRef: Reference to the modal element.
 * - handleCloseModal: Function to close the modal.
 * - formQuestions: Array of form questions for preview.
 * - imageUrl: URL of the image for the form preview.
 * 
 * Features:
 * - Overlay section to create a modal background.
 * - Header section with a back button and title.
 * - Preview of the form with given questions and image.
 * - Color selection area with available theme colors.
 * - Save button to trigger save functionality.
 */
export const ThemeColorModal = ({ modalRef, handleCloseModal, formQuestions, imageUrl, themeColorCode, setThemeColorCode }: ThemeColorModalProps) => {

  const [formBg, setFormBg] = useState<string>(themeColorCode);

  const handleColorSelection = (color: IColor) => {
    setFormBg(color.bgClass);
  };

  const closeModal = () => {
    setThemeColorCode(formBg)
    handleCloseModal()
  }
  const { t } = useTranslation();

  return (
    <div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] w-backdrop"
        ref={modalRef}
      ></section>
      <div className="fixed top-0 right-0 z-30 flex flex-col w-full h-screen px-4 py-12 bg-white dark:bg-clrDarkBg md:px-10 md:w-96 overflow-y-auto slide-in-right">
        <div className="flex items-center gap-2 mb-6">
          <button className="btn-reset" onClick={handleCloseModal}>
            <ArrowBack className="w-5 h-5 dark-arrow" />
          </button>
          <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
            {t("themeColor")}
          </h3>
        </div>

        <PreviewFormExample formQuestions={formQuestions} imageUrl={imageUrl} formBg={formBg} />

        <div className="flex flex-col gap-10 mt-10">
          <div className="">
            <h4 className="text-sm font-medium text-clrGray dark:text-clrPlaceholder">{t("color")}</h4>
          </div>

          <div className="flex gap-6">
            {ThemeColors.map((color, index) => (
              <div key={index} onClick={() => handleColorSelection(color)}>
                <div
                  className={classNames(
                    'flex justify-center items-center mb-3 w-12 h-12 md:w-14 md:h-14 rounded-full',
                    {
                      'border-solid border-2': formBg === color.bgClass,
                    }
                  )}
                  style={{
                    backgroundColor: color.bgClass,
                    borderColor: formBg === color.bgClass ? color.borderColor : 'transparent',
                  }}
                >
                  {formBg === color.bgClass && <img src={checkIcon} alt="checked" />}
                </div>
              </div>


            ))}
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-auto md:w-fit">
          <Button
            type="button"
            text={t("Save")}
            handleClick={() => closeModal()}
            fontWeight="font-normal"
            wBtn="md:w-[8rem]"
          />
        </div>

      </div>
    </div>
  );
};


/**
 * ChooseHubOrCluster Component
 * 
 * Props:
 * - handleSubmit: Function called with the selected method ID when a button is clicked.
 * - handleCloseModal: Function to close the modal.
 * 
 * Description:
 * - Renders a modal for selecting a hub linking method.
 * - Maps preferredHubData to create buttons with onClick handlers that call handleSubmit.
 * 
 * Returns:
 * - A Modal with a title, description, and a list of buttons for each linking method.
 */
export const ChooseHubOrCluster = ({
  handleSubmit,
  handleCloseModal,
}: ChooseHubOrClusterProps) => {
  const { t } = useTranslation();

  const preferredHubData: IPreferredHubMethod[] = [
    {
      id: "1",
      name: "Link via Hubs",
      description: "Select individual hubs you wish to link",
    },
    {
      id: "2",
      name: "Link via Clusters",
      description: "This is used to link hubs under a cluster group",
    },
  ];

  // Dynamically assign handleSubmit to each button's onClick handler
  const preferredHubMethod: (IPreferredHubMethod & { onClick: () => void })[] = preferredHubData.map((data) => ({
    ...data,
    onClick: () => handleSubmit(data.id),
  }));

  return (
    <Modal
      close={true}
      title={"Link Hubs"}
      description={"Select preferred method"}
      handleCloseModal={handleCloseModal}
    >
      <div className="space-y-2">
        {preferredHubMethod.map((method) => {
          return (
            <button
              key={method.id}
              onClick={method.onClick}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white rounded hover:bg-gray-50 dark:hover:bg-clrDarkBg2"
            >
              <div>
                <h3 className="mb-2 font-semibold text-left text-black dark:text-white text-x10 md:text-xs dark:font-medium ">
                  {method.name}
                </h3>
                <p className="text-x10 md:text-xs font-normal text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                  {method.description}
                </p>
              </div>
              <img
                src={ArrowDown}
                alt="more options"
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          );
        })}
      </div>
    </Modal>
  );
};
